import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock, FaThList} from 'react-icons/fa';
//import { MdBolt, MdClose, MdEdit } from 'react-icons/md';
import {MdClose, MdEdit} from 'react-icons/md';
import { CgBolt } from "react-icons/cg";
import {IoIosArrowForward, IoIosLock, IoIosClose} from 'react-icons/io';
import UserForm from './UserForm.js';
import LeftMenuPanel from '../LeftMenuPanel.js'
import AlertBox from '../Common/AlertBox.js'
import OrgUtils from '../../Common/OrgUtils.js';
import Utils from '../../Common/Utils.js';
import CSLMultiSelect from '../Common/CSLMultiSelect';
import EventBus from '../../Common/EventBus.js';
import CSLLoader from '../Common/CSLLoader.js';
import AssignCertificationMgr from './AssignCertificationMgr';

const CertMgrContainer=styled.div`
	width : 100%;
	height : 40px;
	margin : 5px 0px 5px 0px;
	background-color : white;
	border-radius : 4px;
	display : flex;
	flex-direction : row;
	justify-content : space-between;
	border : 1px solid #bdbdbd;
	align-items : center; 
`
const CertMgrName=styled.div`
	width : 25%;
	height : 34px;
	color : #1E7BE6;
	font-weight : 600;
	font-size : 16px;
	padding-left : 10px;
	line-height : 34px;
`
const RolesContainer=styled.div`
	width : 48%;
	height : 34px;
	color : #1E7BE6;
	display : flex;
	flex-direction : row;
	align-items : center;
`
const RoleTiles=styled.div`
	height : 28px;
	color : #fff;
	font-weight : 500;
	font-size : 13px;
	text-align : center;
	line-height : 28px;
	background-color : blue;
	padding : 0px 10px 0px 10px;
	border-radius : 6px;
	margin-right : 5px;
`
const ActionContainer=styled.div`
	width : 24%;
	height : 34px;
	color : #555;
	display : flex;
	flex-direction : row;
	justify-content : flex-end;
	align-items : center;
`

const ActionTile=styled.div`
	width : 25px;
	margin-right : 10px;
	padding : 3px;
	font-weight : 600;
	font-size : 22px;
	height : 32px;
	display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
	color : #777;
	&:hover {
		color : #333;
	}
`

const role_colors = {
    "SMF": '#1B3451',
    "SMPR": '#1B3451',
    "SMOR": '#1B3451',
    "OTH": '#1B3451',
    "COCO": '#24B3AD',
    "CRR": '#F0B72E',
    "CNF": '#11BBF2',
    "ANC": '#CE6666',
    "PAST": '#8A97A6'
}

class ReportingForm extends React.Component {
	state = {
		form_data : {user_id: null, manager_id: '0', drs: null, alt_managers: null},
		form_data_store: null,
		org: null,
		team_members: null,
		contacts: null,
		my_contact: null,
		my_role: 'team',
		user_role: 'team',
		is_dirty: false,
		can_set_manager: false,
		assigned_roles : [],
		assigned_roles_for_change : [],
		cert_mgrs : {},
		cert_mgrs_rolewise : {},
		show_change_cert_mgr : false,
		change_cert_mgr_for_role : null,
		alert_param: null,
		isLoadedIcon: false,
		activate_add_cert_mgr : false,
		show_assign_cert_mgr : false
	}

	setComponentState = () => {
		let my_role = Store.getStoreData('my_role')
		let my_contact = Store.getStoreData('contactInfo')
		// this call is blocked for SMCR to release
		// this.getRolesResponsibility(my_contact.company_id, this.props.userId);
		let all_alt_managers = Store.getStoreData('alt_managers')

		let alt_managers = this.props.userId.toString() in all_alt_managers ? all_alt_managers[this.props.userId.toString()] : {}
		let teams = Store.getStoreData('teams')
		let roles = Store.getStoreData('roles')
		let contacts = Store.getStoreData('members')
		// let team_members = Store.getStoreData('team_members')[this.props.teamId]
		// console.log('ReportingForm team_members', team_members)
		// let alt_managers = alt_managers_all
		let user_role = roles[this.props.userId] == null || roles[this.props.userId] === 'undefined' ? 'team' : roles[this.props.userId]
		let org = Store.getStoreData('org_state').org[this.props.teamId]
		let team_members_list = Store.getStoreData('org_members')[this.props.teamId]
		console.log('getTableData team_members_list', team_members_list)
		let team_members = {}
		for(let t of team_members_list){
			team_members[t.user_id] = t
		}
		let manager_id = team_members[this.props.userId].manager_id

		let can_set_manager = (my_role === 'admin_manager' || this.props.userId !== my_contact.id.toString()) && this.props.userId !== manager_id ? true : false

		let drs = {}
		for(let m of team_members[this.props.userId].members) {
			drs[m.id.toString()] = 1
		}
		// console.log('ReportingForm drs',drs)


		let form_data = {user_id: this.props.userId, manager_id, alt_managers, drs}
		let form_data_store = JSON.stringify(form_data)

		this.setState({form_data, form_data_store, org, team_members, contacts, my_role, user_role, can_set_manager})

		console.log("My Role : ", my_role)
		console.log("My Contact : ", my_contact)

		console.log("All Alt Managers : ".toUpperCase(), all_alt_managers)
		console.log("Alt Managers : ".toUpperCase(), alt_managers,)
		console.log("Teams : ".toUpperCase(), teams)
		console.log("Roles : ".toUpperCase(), roles)
		console.log("Contacts : ".toUpperCase(), contacts)
		console.log("User Role : ".toUpperCase(), user_role)
		console.log("Org : ".toUpperCase(), org)
		console.log("Team Members List : ".toUpperCase(), team_members_list)
		console.log("Team Members : ".toUpperCase(), team_members)
		console.log("Manager Id : ".toUpperCase(), manager_id,)
		console.log("Can Set Manager : ".toUpperCase(), can_set_manager)
		console.log("Drs : ".toUpperCase(), drs)
		console.log("Form Date : ".toUpperCase(), form_data)
		console.log("Form Data Store : ".toUpperCase(), form_data_store)
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	// componentDidUpdate = (prev_props) => {
	// 	if(prev_props === this.props) return
	// 	this.setComponentState()
	// }

	mgrCompare = (a,b) => {
		return a.name.localeCompare(b.name)
	}

	removeDR = (id) => {
		let form_data = this.state.form_data
		delete form_data.drs[id]
		let is_dirty = !(this.state.form_data_store === JSON.stringify(this.state.form_data))
		this.setState({form_data, is_dirty})
	}

	addDR = (id) => {
		let form_data = this.state.form_data
		form_data.drs[id] = 1
		let is_dirty = !(this.state.form_data_store === JSON.stringify(this.state.form_data))
		this.setState({form_data, is_dirty})
		// EventBus.raiseEvent('form_dirty', {form_id: 'reporting', is_dirty: is_dirty})
	}

	addAltManager = (id) => {
		let form_data = this.state.form_data
		form_data.alt_managers[id] = 1
		let is_dirty = !(this.state.form_data_store === JSON.stringify(this.state.form_data))
		// EventBus.raiseEvent('form_dirty', {form_id: 'reporting', is_dirty: is_dirty})
		this.setState({form_data, is_dirty})
	}

	removeAltManager = (id) => {
		let form_data = this.state.form_data
		delete form_data.alt_managers[id]
		let is_dirty = !(this.state.form_data_store === JSON.stringify(this.state.form_data))
		// EventBus.raiseEvent('form_dirty', {form_id: 'reporting', is_dirty: is_dirty})
		this.setState({form_data, is_dirty})
	}

	handleSave = () => {
		let form_data = this.state.form_data
		let form_data_store = JSON.parse(this.state.form_data_store)
		let team_members = this.state.team_members
		console.log('team_members', team_members)
		let form = {manager_id: 'no-change', alt_managers: {}, drs: {}, cert_mgrs : {}, user_id: this.props.userId, team_id: this.props.teamId}
		if(form_data.manager_id.toString() !== form_data_store.manager_id.toString()) {
			form.manager_id = form_data.manager_id
		}
		for(let id in form_data.alt_managers) {
			if(!(id in form_data_store.alt_managers)) {
				form.alt_managers[id] = {op: 'add'}
			}
		}
		for(let id in form_data_store.alt_managers) {
			if(!(id in form_data.alt_managers)) {
				form.alt_managers[id] = {op: 'remove'}
			}
		}
		for(let id in form_data.drs) {
			if(!(id in form_data_store.drs)) {
				form.drs[id] = {op: 'change_manager', manager_id: this.props.userId}
			}
		}
		for(let id in form_data_store.drs) {
			if(!(id in form_data.drs)) {
				form.drs[id] = {op: 'change_manager', manager_id: team_members[team_members[id].manager_id].manager_id}
			}
		}
		for(let id in form_data.cert_mgrs) {
			if(id in form_data_store.cert_mgrs && form_data.cert_mgrs[id] !== form_data_store.cert_mgrs[id]){
				form.cert_mgrs[id] = {op : "update", id : form_data.cert_mgrs[id]}
			}
			if(!(id in form_data_store.cert_mgrs)){
				form.cert_mgrs[id] = {op : "insert", id : form_data.cert_mgrs[id]}
			}
		}
		for(let id in form_data_store.cert_mgrs){
			if(!(id in form_data.cert_mgrs)) form.cert_mgrs[id] = {op : 'delete', id : form_data_store.cert_mgrs[id]}
		}
		this.setState({isLoadedIcon: true})
		let api = new APICall()
		let postData = {command: 'update_reporting_lines', form: form}
		console.log('ReportingFormDR postData', postData)
		api.command(postData, this.processSave)
	}

    formSaveComplete = (e) => {
        e === true ? this.setState({alert_param: null}) : this.props.CloseDialog()

    }

    processSave = (result) => {
    	console.log(result)
    	this.props.formSaved('reporting')
        let form_data = this.state.form_data
        let form_data_store = JSON.stringify(form_data)
        console.log('ReportingFormDR form_data', form_data)
    	// return
        let alert_param = {title: 'ERROR', message: 'Operation failed. Please try after some time.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.closeAlert}
        if(result.error_code === 0) {
            alert_param = {title: 'Confirm', message: 'These edits will be saved, do you want to make further changes?', ok_text: 'Continue', cancel_text: 'Exit', confirm: 'confirm',
                           alertHandler: this.formSaveComplete}
        }
        this.setState({alert_param, isLoadedIcon: false, form_data, form_data_store})
    }

	changeManager = (event) => {
		event.preventDefault()
		let manager_id = event.target.value
		let form_data = this.state.form_data
		if(manager_id in form_data.drs) {
			alert("ERROR: You cannot set a Direct Report as the manager.")
			return
		}

		form_data.manager_id = manager_id
		let is_dirty = !(this.state.form_data_store === JSON.stringify(this.state.form_data))
		// EventBus.raiseEvent('form_dirty', {form_id: 'reporting', is_dirty: is_dirty})
		this.setState({form_data, is_dirty})
	}

	getRolesResponsibility = (company_id, user_id) => {
		let api = new APICall()
		let postData = {command: 'get_roles_responsibilities', company_id : company_id, user_id : user_id}
		console.log('get_roles_responsibilities postData', postData)
		api.command(postData, this.processRolesResponsibility)
	}
	processRolesResponsibility = (result) =>{
		console.log("Roles Responsibility :", result);
		let user_id = this.props.userId;
		//console.log("ID : ", user_id);
		let assigned_roles = [];
		for(let r of result.roles_resp) {
			let rrjson = JSON.parse(r.roles_responsibility_json);
			if("assigned_info" in rrjson){
				let ainfo = rrjson.assigned_info;
				//console.log("Assigned Info : ", ainfo);
				for(let ai of ainfo){
					if(ai.cur_assigned_to === user_id.toString()){
						let present_role_codes = [];
						for(let item of assigned_roles) present_role_codes.push(item.role_id);
						if(present_role_codes.includes(r.role_id) === false) assigned_roles.push({base : JSON.parse(r.roles_responsibility_json).base, role_id : r.role_id, role_code : r.role_code});
					}
				}
			}
		}
		let activate_add_cert_mgr = assigned_roles.length > 0 ? true : false;
		let c_mgr = {};
		let form_c_mgr = {};
		console.log("Result.Cert_mgrs : ", result.cert_mgrs);
		if(result.cert_mgrs !== null && result.cert_mgrs.length !== 0){
			for(let r of result.cert_mgrs){
				c_mgr[r.role_id]= r.role_id in c_mgr === true ? c_mgr[r.role_id] : {};
				c_mgr[r.role_id][r.cert_mgr_id] = {id : r.cert_mgr_id, name : Store.getStoreData('members')[r.cert_mgr_id].ContactName};
				form_c_mgr[r.role_id] = parseInt(r.cert_mgr_id);
			}
		}
		console.log("Assigned Roles : ", assigned_roles);
		console.log("Assigned Mangers : ", c_mgr);
		console.log("Form Assigned Mangers : ", form_c_mgr);
		let form_data = JSON.parse(JSON.stringify(this.state.form_data));
		form_data["cert_mgrs"] = form_c_mgr;
		let form_data_store = JSON.stringify(form_data);

		this.setState({assigned_roles, activate_add_cert_mgr, form_data, form_data_store});
		this.assignCertificationMgr(c_mgr);
		//return form_c_mgr;
	}

	showAssignCertMgrDialog = (e) => {
		e.preventDefault();
		this.setState({show_assign_cert_mgr : true});
	}

	assignCertificationMgr = (selected_cert_mgrs) =>{
		console.log("Assigned Cert : ", selected_cert_mgrs);
		let cert_mgrs = {};
		for(let keys in selected_cert_mgrs){
			for(let mgr in selected_cert_mgrs[keys]){
				if(Object.keys(cert_mgrs).includes(mgr) === false) cert_mgrs[mgr] = {};
			}
		}

		for(let key in cert_mgrs){
			for(let role in selected_cert_mgrs){
				if(Object.keys(selected_cert_mgrs[role]).includes(key) ===  true) cert_mgrs[key][role] = this.state.assigned_roles.find(item => item.role_id === role);
				//console.log("RRR : ", this.state.assigned_roles.find(item => item.role_id === role)); FaPencilAlt FaRegTrashAlt MdBolt MdClose MdEdit
			}
		}
		console.log("Cert Mgrs : ", cert_mgrs);
		let form_cert_mgr = {}
		for(let key in selected_cert_mgrs){
			for(let keys in selected_cert_mgrs[key]){
				form_cert_mgr[key] = parseInt(selected_cert_mgrs[key][keys].id);
			}
		}
		let form_data = JSON.parse(JSON.stringify(this.state.form_data));
		form_data["cert_mgrs"] = form_cert_mgr;
		//let form_data_store = JSON.stringify(form_data);
		console.log("Form Data (assign): ", form_data);
		let is_dirty = !(this.state.form_data_store === JSON.stringify(form_data))
		this.setState({show_assign_cert_mgr : false, show_change_cert_mgr : false, cert_mgrs, cert_mgrs_rolewise : selected_cert_mgrs, form_data, is_dirty});
	}

	changeCertManager = (key) => (e) => {
		e.preventDefault();
		let assigned_roles = JSON.parse(JSON.stringify(this.state.assigned_roles));
		let assigned_roles_for_change = [];
		//console.log("AAAAAAAAAAa : ", this.state.cert_mgrs[key]);
		for(let keys in this.state.cert_mgrs[key]){
			let i = assigned_roles.find(item => { return item.role_id === keys });
			assigned_roles_for_change.push(i);
		}
		console.log("Assigned Role for change : ", assigned_roles_for_change);
		this.setState({show_change_cert_mgr : true, change_cert_mgr_for_role : key, assigned_roles_for_change : assigned_roles_for_change});
	}

	removeCertManager = (key) => (e) =>{
		e.preventDefault();
		let cert_mgrs = JSON.parse(JSON.stringify(this.state.cert_mgrs));
		delete cert_mgrs[key];

		let cert_mgrs_rolewise = JSON.parse(JSON.stringify(this.state.cert_mgrs_rolewise));
		for(let role_keys in cert_mgrs_rolewise){
			if(key in cert_mgrs_rolewise[role_keys] === true)
				delete cert_mgrs_rolewise[role_keys][key];
		}
		console.log("Cert Manager Role Wise after removal : ", cert_mgrs_rolewise);

		let form_data = JSON.parse(JSON.stringify(this.state.form_data));
		for(let keys in form_data.cert_mgrs){
			console.log("keys : ", form_data.cert_mgrs[keys], "key : ", key);
			if(form_data.cert_mgrs[keys] === parseInt(key)) delete form_data.cert_mgrs[keys];
		}
		console.log("Form Data (remove): ", form_data);
		let is_dirty = !(this.state.form_data_store === JSON.stringify(form_data))
		this.setState({cert_mgrs, cert_mgrs_rolewise, form_data, is_dirty});
	}

	closeAssignCertMgrDialog = () => {
		//e.preventDefault();
		this.setState({show_assign_cert_mgr : false});
	}

	render() {
		if(this.state.org === null)return <div />
		let roles = Store.getStoreData('roles')
		let contacts = Store.getStoreData('members')

		let allowed_roles = {team_manager: 1, admin_manager: 1}
		let manager_label = this.props.teamId === 'org_level' ? 'Select Primary Manager' : 'Select Manager...'
		console.log('ReportingForm this.state',this.state)

		let mgr_candidates = {}
		let alt_mgr_candidates = {}
		let dr_candidates = {}
		let alt_mgrs = {}
		let drs = {}

		let form_data = this.state.form_data

		if(this.state.can_set_manager) {
			for(let m_id in this.state.team_members) {

				if(contacts[m_id] === undefined){
					continue
				}
				if(!OrgUtils.isAbove(this.state.org.members,this.props.userId, m_id) && contacts[m_id].isActive) {
					mgr_candidates[m_id] = {id: m_id, name: this.state.contacts[m_id].ContactName}
				}
			}
		}

		if(this.state.user_role !== 'team' && this.state.user_role !== 'no-access') {
			for(let m_id in this.state.team_members) {
				if(contacts[m_id] === undefined){
					continue
				}
				let exclude = OrgUtils.isAbove(this.state.org.members, m_id, this.props.userId.toString())
				exclude = OrgUtils.isAbove(this.state.org.members, m_id, this.state.form_data.manager_id.toString())
				exclude = this.props.userId.toString() === m_id ? true : exclude
				exclude =  contacts[m_id].isActive ? exclude : true
				// console.log('ReportingFormDR include, m_id, this.props.user_id, this.state.org.members', exclude, m_id, this.props.userId, this.state.org.members)
				if(!exclude && m_id !== form_data.manager_id) {
					dr_candidates[m_id] = {id: m_id, name: this.state.contacts[m_id].ContactName}
				}
			}
			for(let m_id in form_data.drs) {
				if(m_id === form_data.manager_id && !contacts[m_id].isActive)delete form_data.drs[m_id]
				if(m_id in form_data.drs)drs[m_id] = {id: m_id, name: this.state.contacts[m_id].ContactName}
			}
		}

		if(this.props.teamId === 'org_level') {
			for(let m_id in this.state.contacts) {
				if(m_id !== this.props.userId.toString() && contacts[m_id].isActive) alt_mgr_candidates[m_id] = {id: m_id, name: this.state.contacts[m_id].ContactName}
			}
			for(let m_id in form_data.alt_managers) {
				if(m_id in this.state.form_data.alt_managers)alt_mgrs[m_id] = {id: m_id, name: this.state.contacts[m_id].ContactName}
			}
		}

		let cancel_right = this.state.is_dirty ? 220 : 80
		// console.log('ReportingForm dr_candidates, drs, alt_mgr_candidates, alt_mgrs', dr_candidates, drs)

		console.log("Form Data from render : ".toUpperCase(), this.state.form_data)
		console.log("Form Data Store from render : ".toUpperCase(), this.state.form_data_store)

		return (<div>
				{
	                (() => {
	                    if(this.state.isLoadedIcon){
	                        return <div><CSLLoader /></div>
	                    }
	                })()
	            }
				<Style.UserFormContainer>
					<Style.SpacerXL />
	                <Style.SpacerL />
	                {
	                	(() => {
	                		if(this.state.can_set_manager) return (<div><Style.SpacerL />
														<Style.ContactLabel>{manager_label}</Style.ContactLabel>
														<Style.SpacerXL />
														<Style.UserSelect onChange={this.changeManager} name="manager_id" value={this.state.form_data.manager_id}>
															<option value={'0'}>{this.props.teamId === 'org_level' ? 'Select Primary Manager' : 'Select Manager...'}</option>
															{
																Object.keys(mgr_candidates).map((id, i) => {
																	let this_role = roles[id] == null || roles[id] === 'undefined' ? 'team' : roles[id]
																	if(this_role in allowed_roles && !(id in form_data.drs))return (<option key={i} value={id}>{mgr_candidates[id].name}</option>)
																})
															}
														</Style.UserSelect>
													</div>)
	                	})()
	                }
	                <Style.SpacerXL />
	                <Style.SpacerL/>
	                {
	                	(() => {
							console.log("ALT MGRS : ", alt_mgrs);
							console.log("ALT MGR_CANDIDATES : ", alt_mgr_candidates);
	                		if(this.props.teamId === 'org_level') {
	                			return (<div>
	                				<Style.ContactLabel>Alternate Manager(s)</Style.ContactLabel>
				                    <Style.SpacerXL/>
				                    <Style.SpacerL/>
				                    <CSLMultiSelect
				                    	selectedItems={alt_mgrs}
				                    	items={alt_mgr_candidates}
				                    	selectCallback={this.addAltManager}
				                    	deselectCallback={this.removeAltManager}
				                    />
				                    <Style.SpacerXL/>
				                    <Style.SpacerL/>
	                			</div>)
	                		}
	                	})()
	                }
					{/* change Arnab */}
					{/*
					{
						<Style.ContactLabel style={{display : "flex", justifyContent : "space-between"}}>
						<span>Certification Manager</span>
						<FaPlusCircle
							style={{
								color : this.state.activate_add_cert_mgr === true ? "#000000" : "#aaaaaa",
								PointerEvent : this.state.activate_add_cert_mgr === true ? "auto" : "none",
								cursor : this.state.activate_add_cert_mgr === true ? "pointer" : "default"
							}}
							onClick = {this.showAssignCertMgrDialog}
						/>
						</Style.ContactLabel>
					}
					{
                    	(() => {
                    		//console.log("CERT mgr : ",this.state.cert_mgrs);
                    		if(Object.keys(this.state.cert_mgrs).length === 0) {
								return(<div>
									<Style.SpacerXL />
									<Style.SpacerL/>
									<div style={{width : "100%", height : "10px", backgroundColor : "#fff", border : "1px solid #DBDBDB", borderRadius : "3px"}} />
									<Style.SpacerXL/>
									<Style.SpacerL/>
								</div>);
							} else{
                    			return (<div>
									<Style.SpacerXL />
									{
										Object.keys(this.state.cert_mgrs).map(item =>{
											return(
												<CertMgrContainer>
													<CertMgrName>{this.state.contacts[item].ContactName}</CertMgrName>
													<RolesContainer>
														{
															Object.keys(this.state.cert_mgrs[item]).map(roles =>{
																return(<RoleTiles style={{backgroundColor : role_colors[this.state.cert_mgrs[item][roles].base]}}>{this.state.cert_mgrs[item][roles].role_code}</RoleTiles>)
															})
														}
													</RolesContainer>
													<ActionContainer>
														<ActionTile style={{cursor : "pointer"}} onClick={this.changeCertManager(item)}><MdEdit /></ActionTile>
														<ActionTile style={{cursor : "pointer"}} onClick={this.removeCertManager(item)}><MdClose /></ActionTile>
													</ActionContainer>
												</CertMgrContainer>
											)
										})
									}
				                    <Style.SpacerXL/>
				                    <Style.SpacerL/>
                    			</div>)
                    		}
                    	})()
                    }*/}
					{/* change Arnab upto here */}
	                {
                    	(() => {

                    		if(this.state.my_role in allowed_roles && this.state.user_role in allowed_roles) {
                    			return (<div>
									<Style.ContactLabel>{this.props.teamId === 'org_level' ? 'Direct Report(s)' : 'Team Members'}</Style.ContactLabel>
									<Style.SpacerXL />
					                <Style.SpacerL/>
				                    <CSLMultiSelect
				                    	selectedItems={drs}
				                    	items={dr_candidates}
				                    	selectCallback={this.addDR}
				                    	deselectCallback={this.removeDR}
				                    />
				                    <Style.SpacerXL/>
				                    <Style.SpacerL/>
                    			</div>)
                    		}
                    	})()
                    }

				</Style.UserFormContainer>
				<Style.UserFormFooter style={{marginRight: '-12px', marginLeft: '-12px'}}>
                    <Style.MRButton style={{top: '55px', right: cancel_right, position: 'absolute', backgroundColor: '#FFFFFF', color: '#757575'}} onClick = {this.props.CloseDialog}>Cancel</Style.MRButton>
                    {
                    	(() => {
                    		if(this.state.is_dirty) return <Style.MRButton style={{top: '55px', right: '80px', position: 'absolute',
                    										backgroundColor: '#046EE5', color: 'white'}} onClick = {this.handleSave}>Save</Style.MRButton>
                    	})()
                    }
                </Style.UserFormFooter>
                <AlertBox alertParam={this.state.alert_param} />
				{/*
				{
					this.state.show_assign_cert_mgr === true &&
					<AssignCertificationMgr user_id={this.props.userId} assigned_roles={this.state.assigned_roles} closeDialog={this.closeAssignCertMgrDialog} assignCertificationMgr={this.assignCertificationMgr} cert_mgrs_rolewise={this.state.cert_mgrs_rolewise}/>
				}
				{
					this.state.show_change_cert_mgr === true &&
					<AssignCertificationMgr user_id={this.props.userId} assigned_roles={this.state.assigned_roles_for_change} closeDialog={this.closeAssignCertMgrDialog} assignCertificationMgr={this.assignCertificationMgr} change_for_role={this.state.change_cert_mgr_for_role} cert_mgrs_rolewise={this.state.cert_mgrs_rolewise}/>
				}
				*/}
			</div>)
	}
}

export default ReportingForm;
