import React from 'react';
import OrgEditor from './OrgEditor.js'

class TEAMsIndex extends React.Component
{
    state = {view_count: 0, modal_view: null};

    constructor(props) {
        super(props);
        this.view = "teams_index";
    }

    refreshState = (modal_view) => {
        this.setState({view_count: this.state.view_count + 1, modal_view:modal_view});
    }

    render()
    {
        // console.log('here in TEAMSIndex')
        return <div style={{boxSizing: "border-box", padding: "0px 15px 0px 0px"}}><OrgEditor /></div>
    }
}
export default TEAMsIndex;