import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import AlertBox from '../Common/AlertBox.js'
import OrgUtils from '../../Common/OrgUtils.js';



class DeleteUserDialog extends React.Component
{
	state = {  
        view_count: 0, 
        ready: false,
        alert_param: null,
        user_id: 0,
        org: null
	}

	constructor(props) {
		super(props);
        this.removeUserProcessor = this.removeUserProcessor.bind(this)
	}
	componentDidMount = () => {
        let org = this.props.org;
        let user_id = this.props.userId;
        OrgUtils.initOrg(org)
        OrgUtils.measureOrg(org)
        let member = OrgUtils.orgFindById(user_id.toString(), org)
        console.log('org', org)
        console.log('this.props', this.props)
        console.log('user_id, member', user_id, member)
        let alert_param = {title: 'Warning', message: 'Are you sure you want to remove this user from the Org Chart?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.removeUserProcessor, stack: {user_id: user_id}}

        if(user_id.toString() === org[0].id) {
            alert_param = {title: 'ERROR', message: 'Cannot remove Team Owner from the team!', ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.props.closeUserDialog, stack: {user_id: user_id}}
        } else if('members' in member && member.members.length > 0) {
            alert_param = {title: 'ERROR', message: 'Cannot remove User with direct reports within the team.', ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.props.closeUserDialog, stack: {user_id: user_id}}
        }
        console.log('alert_param', alert_param)
        this.setState({user_id: user_id, org: org, member: member, alert_param: alert_param, ready: true})
    }

    orgSaved = () => {
        let url = window.location.href
        let url_split = url.split('/')
        console.log('url_split', url_split)
        if(url_split[url_split.length - 1] === 'users'){
            url+='/' + this.props.currentTeam
            console.log('url in', url)
            window.location.href = url
            return
        }
        console.log('url out', url)
        window.location.reload(true)
    }

    removeUserSave = () => {
        let alert_param = {title: 'Success', message: 'Removed user successfully from the team.', ok_text: 'Ok', cancel_text: 'No', confirm: false, 
                           alertHandler: this.orgSaved, stack: {}}

        this.setState({alert_param})
    }

    async removeUserProcessor(result, stack){
        console.log('result', result)
        if(!result) {
            this.props.closeUserDialog()
            return
        }
        let org = this.state.org
        let member = this.state.member
        let index = -1;
        for(let i=0; i < member.manager.members.length; i++) {
            if(member.manager.members[i].id === this.state.user_id.toString()){
                index = i
                break
            }
        }
        if(index >= 0 && index < member.manager.members.length) {
            member.manager.members.splice(index, 1)
        }
        // console.log()
        let team_members = {}
        for(let m_id in this.props.orgMembers) {
            team_members[m_id] = 1
        }
        OrgUtils.initOrg(org)
        let postData = { command: "save_org", org: JSON.stringify(org), team_id: this.props.currentTeam, team_members: team_members};
        console.log('postData', postData)
        let api = new APICall();
        await api.command(postData, this.removeUserSave);
    }

   
	refreshState = (cur_view) => {
        this.setState({view_count : this.state.view_count + 1});
    }

	render()
	{
		if(!this.state.ready) {
            return <div>Loading...</div>
        }
		return (

            <div>
                <AlertBox alertParam={this.state.alert_param} />
            </div>
		  	
		);
	}
}

export default DeleteUserDialog;