import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import EventBus from '../../Common/EventBus.js';
import Store from '../../Common/Store.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import {IoIosArrowForward, IoIosLock, IoIosClose} from 'react-icons/io';
import UserForm from './UserForm.js';
import ReportingForm from './ReportingForm.js';
import LeftMenuPanel from '../LeftMenuPanel.js'
import AlertBox from '../Common/AlertBox.js'
import OrgUtils from '../../Common/OrgUtils.js';
import Utils from '../../Common/Utils.js';
import CSLLoader from '../Common/CSLLoader.js'
import moment from 'moment';
import Draggable from 'react-draggable';

const NameDiv = styled.div`
    font-weight: 700; 
    font-size: 28px;
    float: left;
    margin-top: 34px;
    margin-left: 20px;
    margin-bottom: 20px;
`

const ClearFloat = styled.div`
    clear: both;
`

class EditUserTabs extends React.Component {
    state = {user_is_dirty: false, reporting_is_dirty: false}
    render() {
        let my_role = Store.getStoreData('my_role')
        let my_id = Store.getStoreData('active_member_id').toString()
        let contacts = Store.getStoreData('members')
        // let is_active = contacts[this.props.userId.toString()].isActive
        let is_active = this.props.userId === "0" ? false : contacts[this.props.userId.toString()].isActive;
        console.log('EditUserTabs my_id, my_role, is_active', my_id, my_role, is_active)
        // console.log('EditUserTabs this.props', this.props)
        if(!is_active) return (<Style.ModalTabActive style={{left: '50px'}}>General</Style.ModalTabActive>)
        if(my_role === 'team') return (<Style.ModalTabActive style={{left: '50px'}}>General</Style.ModalTabActive>)
        return (<div>
            {
                (() => {
                   switch(this.props.tab) {
                       case 'user': return (<div>
                                        <Style.ModalTabActive style={{left: '50px'}}>General</Style.ModalTabActive>

                                    </div>)
                   }
                })()
            }
        </div>)
    }
}

class EditUser extends React.Component
{
    state = {ready: true, tab: 'user', user_is_dirty: false, reporting_is_dirty: false, reporting_saved: false, user_saved: false, isLoadedIcon: false, activeDrags: 0}

    setComponentState = () => {
       this.setState({isLoadedIcon: false})
    }

    componentDidMount = () => {
        // EventBus.registerEvent('form_dirty', 'edit_user', this.formChanged)
    }

    formChanged = (stack) => {
        // console.log('stack', stack)
        switch(stack.form_id) {
            case 'user' : this.setState({user_is_dirty: stack.is_dirty}); break;
            case 'reporting' : this.setState({reporting_is_dirty: stack.is_dirty}); break;
        }
    }

    formSaved = (id) => {
        switch(id){
            case 'reporting' : this.setState({reporting_saved: true}); break;
            case 'user' : this.setState({user_saved: true}); break;
        }
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

    changeTab = (tab) => {
        this.setState({tab})
    }

    closeUserDialog = () => {
        // alert(this.state.user_saved)
        if(this.state.reporting_saved || this.state.user_saved) this.props.closeUserDialog(true)
        else this.props.closeUserDialog(false)
    }

    closeUserFromDialog = () => {
        this.props.closeUserDialog(true)
    }

    onStart = () => {
    this.setState({activeDrags: ++this.state.activeDrags});
  };
  onStop = () => {
    this.setState({activeDrags: --this.state.activeDrags});
  };

  	render() {
	    if(!this.state.ready )return (<div></div>)
	    let job_titles = Store.getStoreData('job_titles')
        let smcr_job_titles = Store.getStoreData('smcr_job_titles')
	    let contact_info = Store.getStoreData('contactInfo')
	    let contacts = Store.getStoreData('members')
	    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};


	    return (
	        <div>
    	        <Style.InactiveOverlay style={{zIndex: "1010"}} />
    	        <Draggable handle="strong" {...dragHandlers}>
    	            <div className={"modal-size"}>
            	        <Style.ModalContainer>
            	            <strong>
                	            <Style.ModalHeader>
                	                <Style.ProfileImage src="/default_profile.png" />
                	                <NameDiv>{this.props.userId === '0' ? 'Add new user' : contacts[this.props.userId].isActive === false ? contacts[this.props.userId].ContactName+' (Inactive)' : contacts[this.props.userId].ContactName}</NameDiv>
                                    {
                                        (() => {
                                            if(Store.getStoreData('my_role') !== "team") {
                                                return(<Style.ModalHeaderCloseBtn style={{fontWeight: 200}} onClick={this.closeUserDialog}><MdClose /></Style.ModalHeaderCloseBtn>)
                                            }
                                        })()
                                    }


                	            </Style.ModalHeader>
            	            </strong>
            	            <EditUserTabs userId={this.props.userId} teamId={this.props.teamId} contacts={contacts} org={this.props.org} tab={this.state.tab} changeTab={this.changeTab} />
            	            <ClearFloat />
            	            <Style.ModalBody>
            	            {
            	                (() => {
            	                    switch(this.state.tab) {
            	                        case 'user' : return (<UserForm
            	                                                ContactInfo = {contact_info}
            	                                                userId={this.props.userId}
            	                                                teamId={this.props.teamId}
                                                                RegulatoryStatus = {this.state.regulatory_status}
                                                                JobTitles={job_titles}smcrjobTitles={smcr_job_titles}
                                                                SaveContactInfo ={this.saveContactInfo}
                                                                CloseDialog ={this.closeUserFromDialog}
                                                                formSaved={this.formSaved}
                                                                setDirtyFlag={this.setDirtyFlag}
                                                                listRoles = {this.state.list_roles}
                                                                enableLogin = {this.state.enable_csc_Login}
                                                                lastResetMailDate = {this.state.last_reset_mail_date}
                                                            />)
            	                    }
            	                })()
            	            }
            	            </Style.ModalBody>
            	        </Style.ModalContainer>
            	    </div>
        	    </Draggable>
    	    </div>)
	}
}

export default EditUser;
