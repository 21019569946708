import React from 'react';
import styled from 'styled-components';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb';
import CSLLoader from '../Common/CSLLoader';
import { BiRadioCircleMarked,  BiRadioCircle} from "react-icons/bi";

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 5px;
`;
const RadioBlock = styled.div`
	margin-top: 5px;
`;
const ResponseDropdown = styled.select`
	box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #b5b5b5;
`;
const Radio = styled.div`
	float: left;
	font-size: 23px;
	color: #143151;
	cursor: pointer;
`;
const DropDown = styled.select`
	display: block;
    box-sizing: border-box;
    height: 38px;
    width: 60%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    font-family: Montserrat, sans-serif;
`;
class General extends React.Component
{
	state = {general: null, page:'org_chart'};

	constructor(props) {
	    super(props);

	}

	componentDidMount()
	{		
		let page = 'org_chart';
		let module_start_page = this.props.general.module_start_page
		for(let i=0; i< module_start_page.length; i++){
			console.log('page123==>', module_start_page[i])
			if(module_start_page[i].selected == true){
				page = module_start_page[i].page
			}
		}
		this.setState({general: module_start_page, page:page});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let page = 'org_chart';
			let module_start_page = this.props.general.module_start_page
			for(let i=0; i< module_start_page.length; i++){
				console.log('page123==>', module_start_page[i])
				if(module_start_page[i].selected == true){
					page = module_start_page[i].page
				}
			}
			this.setState({general: module_start_page, page:page});
		}
	}
	handleChange = (event) => {
		event.preventDefault();
		let old_gen = this.state.general;
		let res_gen = [];
		for(let i=0; i<old_gen.length; i++){
			let cont = old_gen[i];
			if(cont.page == event.target.value){
				cont.selected = true;		
			}else{
				cont.selected = false;
			}
			res_gen.push(cont);
			cont = {};
		}
		console.log("res_gen", res_gen)
		this.props.updateGeneral(res_gen);
		this.setState({[event.target.name]: event.target.value});
	}
	render()
	{
		console.log("this.state.general", this.state.general)
		if (this.state.general === null) {
			 return <CSLLoader />
		}

		
		return (
			<GeneralContainer>
				<GeneralInnerContainer>
					<div style={{fontSize: "18px", padding: "10px",fontWeight: "700",color: "#595958"}}>Please select your start up page</div>
					<div style={{padding: "10px", height:"200px"}}>
						{
							<DropDown onChange={this.handleChange} name="page" value={this.state.page}>
							{
								this.state.general.map((item) => {
									return (<option value={item.page} key={item.page}>{item.nickname}</option>)
								})
							}
							</DropDown>
						}
					</div>
				</GeneralInnerContainer>					
			</GeneralContainer>
		);
	}
}

export default General;