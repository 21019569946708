import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import SelectTeam from './SelectTeam';
import {FaTimes} from 'react-icons/fa';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';



class EditTeam extends React.Component
{
	state = {
        view_count: 0,
        ready: false,
        team : "Add Team",
        team_name: "",
        active: false
	}

	constructor(props) {
		super(props);
	}
	async componentDidMount(){
        let postData = { command: "index" };
        let api = new APICall();
        await api.command(postData, this.processIndexData);
    }
    processIndexData = (result) => {
        let current_team = this.props.match ? this.props.match.params.TeamId : 'org_level';
        let team = this.state.team;
        console.log("result===>", result)
        Store.updateStore('current_team', current_team)
        Store.updateStore('configs', result.result.configs)
        Store.updateStore('contactInfo', result.result.contactInfo)
        Store.updateStore('members', result.result.members)
        if(current_team !== "0") {
            team = "Edit Team";
        }
        let selected_members = {};
        this.targetElement = document.querySelector('#CSLTeamEditorDialog');
        disableBodyScroll(this.targetElement);
        this.setState({ready: true, contact_name:result.result.contactInfo.ContactName, team: team, available_members: result.result.members, selected_members: selected_members});
    }

	refreshState = (cur_view) => {
        this.setState({view_count : this.state.view_count + 1});
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }
    handleCheckboxChange = (event) => {
        this.setState({ active: event.target.checked })
    }
    closeModal = (event) => {
        event.preventDefault();
        const task_url = `/manage/teams`;
        window.location.assign(task_url);
    }

    includeMembers = (includemembers) => {
        console.log("include members", includemembers)
    }

	render()
	{
		if(!this.state.ready) {
            return <div>Loading...</div>
        }
        let current_team = Store.getStoreData('current_team')
		return (

            <div style={{backgroundColor: "#f2f2f3", position: "relative"}} id="CSLTeamEditorDialog">
                <DefaultHeader />
                <WelcomeBanner welcome={this.state.contact_name}/>
                <Style.ModalContainer>
                    <Style.ModalBody>
                            <Style.ModalHeaderText>{this.state.team}</Style.ModalHeaderText>
                            <Style.ModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></Style.ModalHeaderCloseBtn>
                            <div style={{clear: "both"}}></div>
                            <Style.ModalBodyInner>
                                <Style.ModalNameLabelDiv>Team Name</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalInput name="team_name" defaultValue={this.state.team_name} onChange={this.handleChange} />
                                </Style.ModalNameInputDiv>
                                <Style.ModalNameLabelDiv><input type="checkbox" name="active" onChange={this.handleCheckboxChange} checked={this.state.active} />Active</Style.ModalNameLabelDiv>
                                <Style.ModalNameInputDiv>
                                    <Style.ModalWiderDiv>
                                        <Style.ModalClearDiv />
                                        <Style.ModalWiderHeaderDiv style={{color: '#ffffff', paddingTop: '8px', paddingLeft: '15px'}}>
                                            Team Members
                                        </Style.ModalWiderHeaderDiv>
                                        <SelectTeam />
                                    </Style.ModalWiderDiv>
                                </Style.ModalNameInputDiv>
                            </Style.ModalBodyInner>
                    </Style.ModalBody>
                    <Style.MRButton style={{ margin:"15px"}}>Save</Style.MRButton>
                </Style.ModalContainer>
            </div>

		);
	}
}

export default EditTeam;
