import React from 'react';
import Store from '../../Common/Store.js';
import styled from 'styled-components';

const FadeDiv = styled.div`
    position: fixed;
    top: 18px;
    left: calc(50vw - 300px);
    width: 600px;
    font-size: 14px;
    padding-top: 7px;
    font-weight: 600;
    background-color: white;
    color: black;
    text-align: center;
    height: 28px;
    border-radius: 10px;
    z-index: 60000;
`

class Fade extends React.Component {
    state = {message: '', fade_scale: 0.6, fade_duration: 500, fade_scale: 1}

    reduceFade = () => {
        let fade_scale = this.state.fade_scale - 0.05
        // console.log('Fade fade_scale', fade_scale)
        this.setState({fade_scale})
        if(fade_scale > 0) setTimeout(this.reduceFade, 10)
    }
    
    setComponentState = () => {
        if(this.props.fadeParams === null) return
        let message = this.props.fadeParams.message
        let fade_scale = 'opacity' in this.props.fadeParams ? this.props.fadeParams.opacity : 0.8
        let fade_duration = 'duration' in this.props.fadeParams ? this.props.fadeParams.duration : 500
        setTimeout(this.reduceFade, this.state.fade_duration)
        this.setState({message, fade_duration, fade_scale})
    }
    componentDidMount = () => {
        this.setComponentState()
    }
    componentDidUpdate = (prevprops) => {
        if(this.props !== prevprops)this.setComponentState()
    }
    
    render() {
        if(this.props.fadeParams === null)return <div />
        
        console.log('Fade this.state', this.state)
        return <FadeDiv style={{opacity: this.state.fade_scale}}>{this.state.message}</FadeDiv>
    }
}

export default Fade;