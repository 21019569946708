import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import OrgUtils from '../../Common/OrgUtils.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import CSLTableAlt from '../Common/CSLTableAlt.js'
import CSLCheckBox from '../Common/CSLCheckBox.js'
import styled from 'styled-components';
import EditUserDialog from './EditUser';
import EditTeamDialog from '../Team/EditTeamDialog.js'
import {FaTimes, FaPlus, FaMinus, FaLock, FaSitemap, FaPencilAlt, FaEllipsisV, FaUser, FaSearch } from 'react-icons/fa';


class TeamEditUser extends React.Component {
	state = {
        view_count: 0,
        contact_name: '',
        ready: false
	}

	constructor(props) {
		super(props);
	}
	async componentDidMount(){
        let postData = { command: "index" };
        let api = new APICall();
        await api.command(postData, this.processIndexData);
    }
    processIndexData = (result) => {
        console.log("result===>", result)
        let my_role = result.result.roles[result.result.contactInfo.id];
        let url_start = '/edit/user/';
        // if(my_role === 'team')window.location.href = url_start + result.result.contactInfo.id;
        let current_team = 'org_level';
        Store.updateStore('configs', result.result.configs)
        Store.updateStore('contactInfo', result.result.contactInfo)
        Store.updateStore('members', result.result.members)
        let job_titles = {}
        for(let j of result.result.job_titles) {
            job_titles[j.JobID.toString()] = j.JobTitle
        }
        Store.updateStore('regulatory_status', result.result.regulatory_status)
        Store.updateStore('job_titles_list', result.result.job_titles)
        Store.updateStore('job_titles', job_titles)
        Store.updateStore('my_role', my_role)
        Store.updateStore('roles', result.result.roles)
        Store.updateStore('contacts_byid', result.result.contacts_byid)
        Store.updateStore('current_team', current_team)
        Store.updateStore('active_member_id', result.result.contactInfo.id.toString())

        this.setState({ready: true, contact_name:result.result.contactInfo.ContactName, edit_user_id: result.result.contactInfo.id});
    }

    closeUserDialog = () => {
    	window.location.reload()
    }
	render()
	{
		if(!this.state.ready) {
            return <div>Loading...</div>
        }

        let org = Store.getStoreData('configs')['org_chart']['org_level']
		return (

            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <DefaultHeader />
                <WelcomeBanner welcome={this.state.contact_name}/>
                <Style.InactiveOverlay  />
                <EditUserDialog userId={this.state.edit_user_id} org={org} closeUserDialog={this.closeUserDialog} isTeam={true} />
            </div>

		);
	}
}

export default TeamEditUser;
