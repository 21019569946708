import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import OrgUtils from '../../Common/OrgUtils.js';
import CSLTable from '../../Common/CSLTable';
import styled from 'styled-components';
import { FaAngleDoubleRight, FaAngleDoubleLeft, FaAngleRight, FaAngleLeft} from "react-icons/fa";
import AlertBox from '../Common/AlertBox.js'

const Box = styled.div`
	border: 1px solid #395875;
	padding: 6px;
	height: 13px;
	border-radius: 4px;
	margin-bottom: 20px;
	background-color: #335575;
`

class SelectTeam extends React.Component
{
		state = {  
    		members: null, alert_param: null
		}
		constructor(props) {
			super(props);
		}
		componentDidMount(){
	        // this.processMembers();
	        let roles = Store.getStoreData('roles')
	        let members = this.props.members;
	        for(let m_id of Object.keys(members)) {
	        	members[m_id].isManagerElligible = roles[m_id] === 'team' ? false : true;
	        }
	        console.log('this.props.members', this.props.members)
	        this.setState({members: members})
	    }
	    processMembers = () => {
	    	this.setState({available_members: Store.getStoreData('members'), selected_members: this.props.selected_members });
	    }

	    selectMembers = (event) => {
	    	event.preventDefault();
	    	let members = this.state.members
	    	for(let m_id of Object.keys(members)) {
	    		if(!members[m_id].included && members[m_id].selected) {
	    			members[m_id].included = true;
	    			members[m_id].selected = false;
	    		}
	    	}
	    	// this.setState({members: members})
	    	this.props.updateMembers(members, true)
	    }

	    deSelectMembers = (event) => {
	    	event.preventDefault();
	    	let members = this.state.members
	    	for(let m_id of Object.keys(members)) {
	    		if(members[m_id].included && members[m_id].selected) {
	    			members[m_id].included = false;
	    			members[m_id].selected = false;
	    		}
	    	}
	    	this.props.updateMembers(members, false)
	    }
	    deSelectAllMembers = (event) => {
	    	event.preventDefault();
	    	let members = this.state.members
	    	for(let m_id of Object.keys(members)) {
    			members[m_id].included = false;
    			members[m_id].selected = false;
	    	}
	    	this.props.updateMembers(members, false)
	    }
	    selectAllMembers = (event) => {
	    	event.preventDefault();
	    	let members = this.state.members
	    	for(let m_id of Object.keys(members)) {
    			members[m_id].included = true;
    			members[m_id].selected = false;
	    	}
	    	this.props.updateMembers(members, true)
	    }

	    selectMemberProcessor = (result, stack) => {
	    	this.setState({alert_param: null})
	    }

    	selectTag = (id) => (event) => {
    		event.preventDefault();	
    		console.log('this.props.org', this.props.org)
    		let member = OrgUtils.orgFindById(id, this.props.org)
    		let members = this.state.members;
    		if(members[id].included && member.members.length > 0) {
    			let alert_param = {title: 'ERROR', message: 'You cannot remove a member who has direct reports within the team.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.selectMemberProcessor, stack: {id: id}}

                this.setState({alert_param: alert_param})
                return
    		}
    		members[id].selected = members[id].selected ? false : true;
    		// member.selected = true;
    		this.setState({members: members})	  
    	}

	    availableMembers = () => {
	    	let ret = {data: [], columns: []}

			ret.columns =[{'Header' : 'Available Members',accessor: 'name', Cell: row => (
				                <div onClick={this.selectTag(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
				                	<label style={{cursor: 'pointer', fontWeight: row.original.can_manage ? '600' : '400'}}>{row.original.name}</label>
				            	</div>
					         ),headerStyle: {textAlign: 'left'}},
							];

			for(let m_id of Object.keys(this.state.members)) {
				if(!this.state.members[m_id].included && this.state.members[m_id].isManagerElligible)ret.data.push({name: this.state.members[m_id].ContactName, id: m_id, selected: this.state.members[m_id].selected, can_manage: this.state.members[m_id].isManagerElligible})
			}
			for(let m_id of Object.keys(this.state.members)) {
				if(!this.state.members[m_id].included && !this.state.members[m_id].isManagerElligible)ret.data.push({name: this.state.members[m_id].ContactName, id: m_id, selected: this.state.members[m_id].selected, can_manage: this.state.members[m_id].isManagerElligible})
			}

			return ret;
	    }

	    selectedMembers = () => {
	    	let ret = {data: [], columns: []}

			ret.columns =[{'Header' : 'Selected Members',accessor: 'name', Cell: row => (
				                <div onClick={this.selectTag(row.original.id)} style={{backgroundColor: row.original.selected ? '#ceeeed': '#ffffff', cursor:'pointer'}}>
				                	<label  style={{cursor: 'pointer', fontWeight: row.original.can_manage ? '600' : '400', fontSize: row.original.id === this.props.managerId ? '18px' : '12px'}}>{row.original.name}</label>
				            	</div>
					         ),headerStyle: {textAlign: 'left'}},
							];

			for(let m_id of Object.keys(this.state.members)) {
				if(this.state.members[m_id].included && this.state.members[m_id].isManagerElligible)ret.data.push({name: this.state.members[m_id].ContactName, id: m_id, selected: this.state.members[m_id].selected, can_manage: this.state.members[m_id].isManagerElligible})
			}
			for(let m_id of Object.keys(this.state.members)) {
				if(this.state.members[m_id].included && !this.state.members[m_id].isManagerElligible)ret.data.push({name: this.state.members[m_id].ContactName, id: m_id, selected: this.state.members[m_id].selected, can_manage: this.state.members[m_id].isManagerElligible})
			}

			return ret;
	    }

		render()
		{
			if(this.state.members === null)return <div>Loading...</div>
			console.log(this.state)
			return (
	            <div style={{backgroundColor: "#f2f2f3", position: "relative", width: 'calc(100% + 15px)'}}>
	            	<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "calc(50% - 25px)", borderRadius: "3px",padding: "5px", float: "left"}} >				
	            		<CSLTable add={false} processData={this.availableMembers} headerText={''} tableRows={10} />
	            	</div>
					<div style={{paddingTop: "140px", backgroundColor: "#f2f2f2", borderRadius: "3px",float: "left", width: '30px'}}>				
						<Box style={{cursor: "pointer"}} onClick={this.selectAllMembers}><FaAngleDoubleRight style={{color: "#ffffff"}} /></Box>
						<Box style={{cursor: "pointer"}} onClick={this.selectMembers}><FaAngleRight style={{color: "#ffffff"}} /><br/><br/></Box>
						<Box style={{cursor: "pointer"}} onClick={this.deSelectMembers}><FaAngleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
						<Box style={{cursor: "pointer"}} onClick={this.deSelectAllMembers}><FaAngleDoubleLeft  style={{color: "#ffffff"}}/><br/><br/></Box>
					</div>
					<div style={{marginTop: "2px", backgroundColor: "#ffffff", width: "calc(50% - 25px)", borderRadius: "3px",padding: "5px", float: "left" }}>	
						<CSLTable add={false} processData={this.selectedMembers} headerText={''} tableRows={10} />		
					</div>
					<AlertBox alertParam = {this.state.alert_param}/>
	            </div>
			  	
			);
		}
}
export default SelectTeam;