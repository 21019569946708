import Store from './Store.js';

class OrgUtils {
    memberFindById = (find_id, member) => {
        // if(find_id === '3973')console.log('OrgUtils m_id, member', find_id, member)
        if(member.id.toString() === find_id.toString())return member;
        if(!('members' in member))return null;
        for(let m of member.members) {
            if(m.id.toString() === find_id.toString())return m;
            let found_member = this.memberFindById(find_id.toString(), m);
            if(found_member !== null) return found_member;
        }
        return null;
    }

    orgFindById = (find_id, org) => {
        // find_id = find_id.toString()
        // if(find_id === '3973')console.log('OrgUtils find_id', find_id, org)
        for(let m of org) {
            let found_member = this.memberFindById(find_id.toString(), m);
            if(found_member !== null) return found_member;
        }
        return null;
    }

    isAbove = (org, u_id, m_id) => {
        console.log('ReportingForm org, u_id', org, u_id)
        let member = this.orgFindById(u_id, org)
        console.log('member', member)
        if(member){ // checking if member is null
            if(member.id === m_id.toString())return true;
            for(let mm of member.members) {
                if(mm.id.toString() === m_id.toString())return true;
                // let next_org = mm.members
                if(this.isAbove(org,mm.id, m_id))return true;
            }
        }

        return false;      
    }

    isUnder = (org, u_id, m_id) => {
        let member = this.orgFindById(u_id, org)
        if(member.manager_id.toString() === '0') return false;
        if(member.manager_id.toString() === m_id)return true;
        return this.isUnder(org, member.manager_id, m_id);
    }

    setManagers = (org, manager) => {
        for(let m of org) {
            m.manager = manager;
            this.setManagers(m.members, m)
        }
    }

    measureMember = (member, prev_m, id) => {
        member.manager = prev_m;
        member.x = prev_m !== null ? 100 : this.x_offset;
        member.y = this.y_offset;
        member.prev_x = prev_m !== null ? prev_m.x : this.x_offset;
        member.prev_y = prev_m !== null ? prev_m.y : this.y_offset;
        member.prev_box_height = prev_m !== null ? prev_m.box_height : this.box_height;

        member.teams = {};
        let configs = Store.getStoreData('configs');

        for(let t in configs.org_teams) {
            if(t !== 'org_level') {
                if(this.orgFindById(member.id, configs.org_chart[t]) !== null) {
                    let is_manager = member.id === configs.org_chart[t][0].id ? true : false;
                    member.teams[t] = {name: configs.org_teams[t].name, id: t, is_manager: is_manager}
                }
            }
        }
        member.mgr_lines = []
        if(member.manager !== null || Object.keys(member.alt_managers) > 0){
            member.mgr_lines.push([])
            if(member.manager !== null) member.mgr_lines[0].push({name: configs.org_members.org_level[member.manager.id].ContactName, is_primary: true})
        }

        if(member.mgr_lines.length === 0 && Object.keys(member.alt_managers).length > 0)member.mgr_lines.push([])

        let char_count = member.mgr_lines.length > 0 && member.mgr_lines[0].length > 0 ? member.mgr_lines[0][0].name.length : 0;
        let cur_line = 0;
        let cur_line_len = char_count + (char_count > 0 ? 2 : 0);
        // let line_len = 100;
        // console.log('member', member)
        for(let id in member.alt_managers) {
            if(cur_line_len + configs.org_members.org_level[id].ContactName.length > this.line_len) {
                member.mgr_lines.push([]);
                cur_line_len = 0;
                cur_line++
            }
            member.mgr_lines[cur_line].push({name: configs.org_members.org_level[id].ContactName, is_primary: false})
            cur_line_len += configs.org_members.org_level[id].ContactName.length + 2
        }

        member.box_height = Store.getStoreData('current_team') === 'org_level' ? this.box_height + 
                            member.mgr_lines.length * this.line_height + 
                            Object.keys(member.teams).length * this.line_height + 
                            (Object.keys(member.teams).length > 0 ? this.line_height : 0) +
                            (member.mgr_lines.length > 0 ? this.line_height : 0): this.box_height ;
        member.vert_gap = this.vert_gap
        this.y_offset += member.box_height + this.vert_gap + 25;//95;
        // console.log('member.manager', member.manager)
        for(let m of member.members) {
            this.measureMember(m, member, id);
        }
    }

    measureOrg = (org, id) => {
        this.initOrg(org)
        this.setManagers(org, null)
        console.log('Org wherefrom', org)
        for(let member of org) {
            this.measureMember(member, null, id);
        }
        console.log('org in measureOrg', org)
        this.setManagers(org, null)
    }

    initMember = (member) => {
        let configs = Store.getStoreData('configs')
        member.name = (member.id in configs.org_members.org_level) ? configs.org_members.org_level[member.id].ContactName : 'User Removed'
        member.x = 0;
        member.y = 0;
        member.prev_x = 0;
        member.prev_y = 0;
        member.manager = null;
        member.removed = false;
        member.teams = {};
        if(!('members' in member))member.members = []
        if(!('alt_managers' in member) || Array.isArray(member.alt_managers))member.alt_managers = {}
        if(!('alt_reporters' in member) || Array.isArray(member.alt_reporters))member.alt_reporters = {}
        if(!(member.id in configs.org_members.org_level)) {
            member.removed = true;
            member.alt_managers = {}
            member.alt_reporters = {}
        }
        for(let m of member.members) {
            this.initMember(m)
        }
    }

    initOrg = (org) => {
        let current_team = Store.getStoreData('current_team')
        let configs = Store.getStoreData('configs')
        let team_members = configs.org_members[current_team]
        if(current_team !== 'org_level') {
            for(let m_id of Object.keys(team_members)) {
                team_members[m_id] = configs.org_members.org_level[m_id]
                team_members[m_id].name = team_members[m_id].ContactName
            }
        }
        this.team_members = team_members
        this.x_offset = 50;
        this.y_offset = 10;
        for(let m of org) {
            this.initMember(m)
        }
    }
}

export default new OrgUtils();