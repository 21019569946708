import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import EventBus from '../../Common/EventBus.js';
import Store from '../../Common/Store.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import SelectTeam from './SelectTeam';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {FaTimes} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import OrgUtils from '../../Common/OrgUtils.js';
import AlertBox from '../Common/AlertBox.js'
import CSLCheckBox from '../Common/CSLCheckBox.js'
import CSLMultiSelect from '../Common/CSLMultiSelect.js'
import Draggable from 'react-draggable';
// import AlertBox from '../Common/AlertBox.js'

const allowed_roles = {team_manager: 1, admin_manager: 1}

class GeneralForm extends React.Component {

    changeName = (event) => {
        event.preventDefault()
        this.props.changeName(event.target.value)
    }

    changeActive = (value) => {
        this.props.changeActive(value)
    }
    render() {
        return (
                <div>

                    <Style.SpacerXL/>
                    <Style.ContactLabel>Team Name</Style.ContactLabel>
                    <Style.SpacerL/>
                    <Style.ModalNameInputDiv>
                        <Style.ContactInput name="team_name" defaultValue={this.props.formData.name} onChange={this.changeName} />
                    </Style.ModalNameInputDiv>
                    <Style.SpacerL/>
                    {
                        (() => {
                            if(this.props.teamId !== 'org_level') return (<div>
                                <Style.SpacerXL/>
                                <Style.ContactLabel>Active</Style.ContactLabel>
                                <CSLCheckBox checked={this.props.formData.active} checkCallback={this.changeActive} />
                            </div>)
                        })()
                    }

                </div>
            )
    }
}

class PersonnelForm extends React.Component {
    changeManager = (event) => {
        event.preventDefault()
        this.props.changeManager(event.target.value)
    }

    render() {
        let form_data = this.props.formData
        let team_managers = []
        let included_members = {}
        let member_suggestions = {}
        let contacts = Store.getStoreData('members')
        let roles = Store.getStoreData('roles')
        // console.log('EditTeamDialog contacts, roles', contacts, roles)
        for(let id in form_data.members) {
            let m = form_data.members[id]
            team_managers.push({id: id, name: contacts[id].ContactName, role: m.role})
        }
        for(let id in contacts) {
            let pillStyle = {}
            let optionStyle = {}
            let score = 0
            if(roles[id] in allowed_roles) {
                pillStyle.bgColor = '#046EE5'
                optionStyle.fontWeight = 700
                score = 100
            }
            if(id === form_data.manager_id) {
                pillStyle.fontWeight = 700
                // pillStyle.fontSize = '16px'
                pillStyle.bgColor = '#046DF4'
                score = 200
            }
            if(id in form_data.members) {
                included_members[id] = {name: contacts[id].ContactName, pillStyle: pillStyle, score: score}
            } else {
                if(contacts[id].isActive)member_suggestions[id] = {name: contacts[id].ContactName, optionStyle: optionStyle, score: score}
            }
        }
        return (<div>
                    <Style.SpacerXL/>
                    <Style.ContactLabel>Team Owner</Style.ContactLabel>
                    {
                        (() => {
                            if(team_managers.length === 0) {
                                return (<div>First add at least one member with the Team Manager role to the Team to be able to select a Team Owner.
                                        <Style.UserSelect onChange={this.changeManager} name="manager_id" value={form_data.manager_id}>
                                            <option key='0' value='0'>No one with a Team Manager role in the team.</option>
                                        </Style.UserSelect>
                                    </div>)
                            } else {
                                return (<div>
                                        <Style.UserSelect onChange={this.changeManager} name="manager_id" value={form_data.manager_id}>
                                        <option key='0' value='0'>Select a manager for the team.</option>
                                        {
                                            team_managers.map((m, i) => {
                                                // console.log('EditTeamDialog m', m)
                                                if(m.role in allowed_roles && contacts[m.id].isActive)return <option key={i} value={m.id}>{m.name}</option>
                                            })
                                        }
                                        </Style.UserSelect>
                                    </div>)
                            }
                        })()
                    }

                    <Style.SpacerXL/>
                    {
                        <Style.ModalNameInputDiv>
                            <Style.ModalWiderDiv>
                                <Style.ModalClearDiv />
                                <Style.SpacerXL/>
                                <Style.ContactLabel>
                                    Team Members
                                </Style.ContactLabel>
                                <CSLMultiSelect
                                    selectedItems={included_members}
                                    items={member_suggestions}
                                    selectCallback={this.props.addMember}
                                    deselectCallback={this.props.removeMember}
                                />
                            </Style.ModalWiderDiv>
                        </Style.ModalNameInputDiv>
                    }
                </div>
            )
    }
}

class EditTeamDialog extends React.Component
{
	state = {
        ready: false,
        alert_param: null,
        this_team: {},
        tab: 'general',
        form_data: null,
        form_data_store: ''
	}

    setComponentState = () => {
        let teams = Store.getStoreData('teams')
        let org_members = Store.getStoreData('org_members')
        let roles = Store.getStoreData('roles')
        // console.log('EditTeamDialog teams, org_members, this.props', teams, org_members, this.props)
        let this_team = this.props.teamId === '0' ? {name: '', active: true, manager_id: '0'} : teams[this.props.teamId]
        let form_data = {name: this_team.name, active: this_team.active, manager_id: this_team.manager_id, members: {} }
        let new_form_store = JSON.stringify(form_data)
        let new_members = 'newMembers' in this.props ? this.props.newMembers : []
        let this_team_members = this.props.teamId === '0' ? new_members : org_members[this.props.teamId]
        // console.log('EditTeamDialog this_team_members', this_team_members)
        for(let m of this_team_members) {
            form_data.members[m.id] = {id: m.id, is_manager: m.manager_id === '0' ? true : false, role: roles[m.id], manager_id: m.manager_id }
            if(m.manager_id === '0')form_data.manager_id = m.id
        }
        // console.log('EditTeamDialog form_data.members', form_data.members)
        let form_data_store = this.props.teamId === '0' ? new_form_store : JSON.stringify(form_data)
        this.setState({ready: true, this_team, form_data, form_data_store})
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    // componentDidUpdate = (prevprops) => {
    //     if(this.props === prevprops) return

    //     this.setComponentState()
    // }

    changeTab = (tab) => {
        this.setState({tab})
    }

    generateForm = () => {
        let form_data = this.state.form_data
        let form_data_store = JSON.parse(this.state.form_data_store)
        let form = {}

        form.members = []
        for(let id in form_data.members) {
            if(!(id in form_data_store.members)) {
                let manager_id = 'manager_id' in form_data.members[id] ? form_data.members[id].manager_id : (id === form_data.manager_id ? '0' : form_data.manager_id)
                // console.log('EditTeamDialog manager_id, form_data.members[id]', manager_id, form_data.members[id])
                form.members.push({id: id, op: 'add', manager_id : manager_id })
            }
        }
        for(let id in form_data_store.members) {
            if(!(id in form_data.members)) {
                form.members.push({id: id, op: 'remove'})
            }
        }
        form.name = (form_data.name !== form_data_store.name) ? form_data.name : 'no-change'
        form.active = (form_data.active !== form_data_store.active) ? form_data.active : 'no-change'
        form.manager_id = (form_data.manager_id !== form_data_store.manager_id) ? form_data.manager_id : 'no-change'
        form.prev_manager_id = form.manager_id !== 'no-change' ? form_data_store.manager_id : '0'
        if(form.name === 'no-change' && form.active === 'no-change' && form.manager_id === 'no-change' && form.members.length === 0) return null

        form.team_id = this.props.teamId
        return form
    }

    closeAlert = () => {
        this.setState({alert_param: null})
    }

    formSaveComplete = () => {
        let form_data_store = JSON.stringify(this.state.form_data)
        this.setState({form_data_store, alert_param: null})
    }

    processSaveTeam = (result) => {
        console.log('EditTeamDialog result', result)
        let teams = Store.getStoreData('teams')
        console.log('teams, this.props.teamId, this.state.form_data', teams,this.props.teamId, this.state.form_data)
        let new_team = false
        if(!(result.result.team_id in teams)){
            teams[result.result.team_id] = {}
            new_team = true
        }
        teams[result.result.team_id].name = this.state.form_data.name
        teams[result.result.team_id].active = this.state.form_data.active
        teams[result.result.team_id].manager_id = parseInt(this.state.form_data.manager_id)
        teams[result.result.team_id].id = result.result.team_id
        Store.updateStore('teams', teams)
        EventBus.raiseEvent('teams_changed', result.result.team_id)
        EventBus.raiseEvent('teams_changed_editor', result.result.team_id)
        // if(!new_team) {
        //     EventBus.raiseEvent('teams_changed', this.props.teamId)
        //     EventBus.raiseEvent('teams_changed_editor', this.props.teamId)
        // } else {
        //     Store.updateStore('new_team', true)
        //     Store.updateStore('new_team_id', result.result.team_id)
        // }
        // return
        let alert_param = {title: 'ERROR', message: 'Operation failed. Please try after some time.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.closeAlert}
        if(result.error_code === 0) {
            if('teams' in  result.result) Store.updateStore('teams', result.result.teams)
            alert_param = {title: 'Success', message: 'Team data saved successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.formSaveComplete}
        }
        this.setState({alert_param})
    }

    saveTeam = () => {
        let form_data = this.state.form_data
        if(form_data.manager_id === '0') {
            alert('ERROR: You must set a manager for the team.')
            return
        }
        if(!(form_data.manager_id in form_data.members)) {
            alert('ERROR: The team manager must be a team member.')
            return
        }
        let form = this.generateForm()
        if(form === null) return

        let api = new APICall()
        let postData = {command: 'save_team', form: form}
        // console.log('EditTeamDialog postData', postData)
        api.command(postData, this.processSaveTeam)
    }

    changeManager = (manager_id) => {
        let form_data = this.state.form_data
        form_data.manager_id = manager_id
        this.setState({form_data})
    }

    changeName = (name) => {
        let form_data = this.state.form_data
        form_data.name = name
        this.setState({form_data})
    }

    changeActive = (value) => {
        let form_data = this.state.form_data
        form_data.active = value
        this.setState({form_data})
    }

    addMember = (id) => {
        let form_data = this.state.form_data
        let roles = Store.getStoreData('roles')
        form_data.members[id] = {id: id, is_manager: false, role: roles[id] }
        this.setState({form_data})
    }
    removeMember = (id) => {
        let form_data = this.state.form_data
        if(form_data.manager_id === id) form_data.manager_id = '0'
        delete form_data.members[id]
        this.setState({form_data})
    }

    closeTeamDialog = () => {
        this.props.closeTeamDialog(false)
    }

    render() {
        if(!this.state.ready) return <div />
        let tab_top = 120
        let is_dirty = this.generateForm() === null ? false : true
        // console.log('EditTeamDialog this.state.form_data', this.state.form_data)
        return (<Draggable>
        <div className={"modal-size"} id="CSLTeamEditorDialog">
            <Style.ModalContainer>
                <Style.DialogBoxHeader>
                    <Style.ModalHeaderText>{this.props.teamId === '0' ? 'Add Team' : `Edit Team "${this.state.this_team.name}"`}</Style.ModalHeaderText>
                    <Style.ModalHeaderCloseBtn onClick={this.closeTeamDialog}><MdClose /></Style.ModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </Style.DialogBoxHeader>
                {
                    (() => {
                        if(this.state.tab === 'general') {
                            return (<div>
                                <Style.ModalTabActive style={{left: '50px', top: tab_top}}>General</Style.ModalTabActive>
                                {
                                    (() => {
                                        if(this.props.teamId !== 'org_level')return <Style.ModalTab style={{left: '220px', top: tab_top}} onClick={() => this.changeTab('personnel')}>Personnel</Style.ModalTab>
                                    })()
                                }

                            </div>)
                        } else {
                            return (<div>
                                <Style.ModalTab style={{left: '50px', top: tab_top}} onClick={() => this.changeTab('general')}>General</Style.ModalTab>
                                <Style.ModalTabActive style={{left: '220px', top: tab_top}}>Personnel</Style.ModalTabActive>
                            </div>)
                        }
                    })()
                }
                <Style.ModalBody>
                    <Style.ModalBodyInner>
                    {
                        (() => {
                            switch(this.state.tab) {
                                case 'general' : return <GeneralForm
                                                            changeName={this.changeName}
                                                            changeActive={this.changeActive}
                                                            formData={this.state.form_data}
                                                            teamId={this.props.teamId}
                                                        />
                                case 'personnel' : return <PersonnelForm
                                                            formData={this.state.form_data}
                                                            changeManager={this.changeManager}
                                                            addMember={this.addMember}
                                                            removeMember={this.removeMember}
                                                           />
                            }
                        })()

                    }
                    </Style.ModalBodyInner>
                </Style.ModalBody>
                {
                    (() => {
                        if(is_dirty) return <Style.MRButton style={{ margin:"15px", cursor: 'pointer'}} onClick={this.saveTeam}>Save</Style.MRButton>
                    })()
                }
            </Style.ModalContainer>
            <AlertBox alertParam={this.state.alert_param} />
        </div></Draggable>)
    }
}

export default EditTeamDialog;


// C:\Python36;C:\poppler\poppler\bin;C:\Users\Indranil\.dotnet\tools;C:\Users\Indranil\AppData\Roaming\npm
