import Store from './Store.js';

const show_trace = false

class Utils {
  // constructor() {
  // }
  genKey = (length) => {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  log() {

  }

  setEnv(module_name) {
    let api_url = process.env.REACT_APP_API_URL;

    Store.updateStore('module_name',module_name);
    Store.updateStore('api_url',api_url);
    Store.updateStore('is_admin',false);
    Store.updateStore('is_debug',false);
  }

}

export default new Utils();
