import Store from './Store.js';
import Utils from './Utils.js';
import http from 'mcc-front-aux/dist/httpCommon.js';

class APICall {
  constructor() {
    this.url = Store.getStoreData('api_url');
    this.is_admin = Store.getStoreData('is_admin');
    this.is_debug = Store.getStoreData('is_debug');
    this.module_name = Store.getStoreData('module_name');
  }

  command(postData, callback) {
      // if (this.is_debug) {
      //   postData.env = this.is_admin ? "csl" : "select";
      //   if(!this.is_admin) {
      //     postData['CSC_CL_companyID'] = 3159;
      //     postData['CSC_CL_contactID'] = 8515;
      //     postData.env='select'
      //   }
      // }

      // if (this.is_debug) {
      //     const currentUser = Store.getStoreData('user_data')
      //     postData['CSC_CL_companyID'] = currentUser.company_id
      //     postData['CSC_CL_contactID'] = currentUser.id;
      // }

      let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
      jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
      jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
      jsonDataString = jsonDataString.replace(/\n/g, '');
      jsonDataString = jsonDataString.replace(/\r/g, '');
      Utils.log('jsonDataString', jsonDataString)
      this.url = Store.getStoreData('api_url');
      // if(!this.is_debug)this.url += `msgw/${this.module_name}/api/command`;

      // Add in the json command if it exists
      let postURL = this.url
      if('command' in postData) {
          postURL += `/${postData.command}`
      }


      http.post(postURL, { jsondata: jsonDataString }).then(res => {
          res = res.data.body;
          res = res.replace(/(--plus--)/g, "+");
          res = res.replace(/(--quote--)/g, "'");
          res = res.replace(/(--and--)/g, "&");
          res = res.replace(/'+/g,"'");
          res = JSON.parse(res);
          // alert('here')
          // console.log('json returned new object', JSON.parse(JSON.stringify(result)))
          callback(res);
      }).catch(error => {
        console.log(error);
        return(null)
      });

      // trackPromise(fetch(this.url, httpVerb)
      //     .then(res => res.json())
      //     .then(result => {
      //       result = JSON.stringify(result);
      //       result = result.replace(/(--plus--)/g, "+");
      //       result = result.replace(/(--quote--)/g, "'");
      //       result = result.replace(/(--and--)/g, "&");
      //       result = result.replace(/'+/g,"'");
      //       result = JSON.parse(result);
      //       // alert('here')
      //       // console.log('json returned new object', JSON.parse(JSON.stringify(result)))
      //       callback(result);
      //     }).catch(error => {
      //       console.log(error);
      //       return(null)
      //     }));
  }
}

export default APICall;
