import React from 'react';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import Utils from './Common/Utils.js';
import Index from './Components/Index';
import './App.css';
import ManageTeams from './Components/Team/ManageTeams';
import ManageUsers from './Components/User/ManageUsers';
import ManageUsersOld from './Components/User/ManageUsersOld';
import TeamEditUser from './Components/User/TeamEditUser';
import EditUser from './Components/User/EditUser';
import EditTeam from './Components/Team/EditTeam';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC.js';
import DefaultHeader from './Components/Common/DefaultHeader';
import CSLLoader from './Components/Common/CSLLoader.js'
import AlertBox from './Components/Common/AlertBox.js'
import Reload from './Common/Reload.js';
import authService from 'mcc-front-aux/dist/authService.js';
/*global localStorage*/

class AppContent extends React.Component
{
	state = {ready: false, role: 'team', user_id: '0', alert_param: null}
	constructor(props) {
		super(props);
		Utils.setEnv('teams');
		Store.updateStore('module_name', 'teams');
    }

    setComponentState = () => {
    	let api = new APICall()
    	let postData = {command: 'get_role' }
    	api.command(postData, this.processCall)
    }

    processCall = (result) => {
      if (result.error_code === 0) {
        if (result.result["role"] === "no_access") {
          let alert_param = {title: 'No Access', message: '', ok_text: 'Ok', confirm: false,
                             alertHandler: this.alertHandler, stack: {id: 0}};
          alert_param["message"] = result.error_msg;
          this.setState({ ready: true, alert_param: alert_param});
          return false;

        }
        this.setState({ready: true})
      }else{
        let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                   alertHandler: this.alertHandler, stack: {id: 0}}
        alert_param["message"] = result.error_msg;
        this.setState({ ready: true, alert_param: alert_param});
        return;
      }
    }
    alertHandler = (result, stack) => {
      // this.setState({ alert_param: null });
      // window.location = "/select/ServiceBasedLanding";
      Reload.ReloadPage();
    };
    componentDidMount = () => {
    	this.setComponentState()
    }

	render()
	{
		console.log("userform App this.state",this.state)
		if(!this.state.ready) {
            return (
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <CSLLoader />
                </div>
            )
        }

    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }

      	return(
				<Router>
					<Switch>
						<Route path="/" exact><Index /></Route>
                        <Route path="/:teamId" component={Index} exact />
                        <Route path="/manage/teams" component={Index} exact />
                        <Route path="/manage/users" component={Index} exact />
                         <Route path="/manage/usersold" component={ManageUsersOld} exact />
                        <Route path="/manage/users/:teamId" component={ManageUsers} exact />
                        <Route path="/edit/user/:UserId" component={TeamEditUser} exact />
                        <Route path="/edit/team/:TeamId" component={EditTeam} exact />
                        <Route path="/manage/moduleconfig" component={ModuleConfigHOC} exact />
					</Switch>
				</Router>
			);
	}
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
	  console.log('this.props', this.props)
	  let is_debug = false
	  let api_url = process.env.REACT_APP_API_URL;
	  Store.updateStore('is_debug', is_debug)
	  Store.updateStore('api_url', api_url)

	  authService.refresh().then(() => {
		  this.setState({ready: true})
	  })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
	  if(!this.state.ready) {
	  	return <div>Loading...</div>
	  }
	  // let appContent = <AppContent />
	  // return <SiteLayout AppContent={appContent} />

	  return <AppContent />
  }
}

export default App;
