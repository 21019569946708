import React from 'react';
import styled from 'styled-components';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoMdTrash, IoMdCreate} from 'react-icons/io';
import Utils from '../../../Common/Utils';
import APICall from '../../../Common/APICall';
import { FaTimes } from 'react-icons/fa';
import AlertBox from '../AlertBox'

const OptionsContainer = styled.div`
	box-sizing: border-box;
	min-width: 300px;
    background-color: #ffffff;
    position: absolute;
    top: 45px;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    max-height: 250px;
`;
const Option = styled.div`
	padding: 5px 15px;
	cursor: pointer;
	color: black;
	width: calc(100% - 15px);
	&:focus {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
	&:active {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;

const OptionText = styled.div`
	width: calc(100% - 80px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	float: left;
`

const SearchBoxDiv = styled.div`
	float: left;
	height: 45px;
	width: 100%;
	
    box-sizing: border-box;
    position: relative;
    margin-top: 5px;
`;
const SearchBox = styled.input`
	height: 20px;
	width: 92%;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	padding: 5px;
	color: #979797;
	font-family: montserrat;
	font-size: 14px;
	font-weight: 600;
	&:focus {
		outline-width: 0;
	}
`;

const DelOptionBox = styled.div`
	float: right;
	width: 12px;
	height: 12px;
	font-size: 10px;

`

const AddFeedButton = styled.div`
	height: 12px;
	width: 12px;
	font-size: 14px;
	background-color: #ffffff;
	color: #37ADA7;
	border: 1px solid #ffffff;
	border-radius: 50%;
	float: right;
	padding: 3px;
	cursor: pointer;
`

const APAddFeedDialogBox = styled.div`
	position: fixed;
	background-color: #ffffff;
	top: 40vh;
	left: 40vw;
	width: 20vw;
	border: 1px solid #37ADA7;
	border-radius: 5px;
	z-index: 10000;
`
const APAddFeedDialogHeader = styled.div`
	width: calc(100% - 10px);
	height: 25px;
	padding: 5px;
	border-radius: 5px 5px 0px 0px;
	background-color: #37ADA7;
	color: #ffffff;
`
const APAddFeedDialogBody = styled.div`
	width: calc(100% - 10px);
	padding: 5px;
	border-radius: 0px 0px 0px 0px;
	background-color: #ffffff;
	color: #2d2d2d;
`
const APAddFeedDialogFooter = styled.div`
	width: calc(100% - 10px);
	padding: 5px;
	height: 25px;
	border-radius: 0px 0px 5px 5px;
	background-color: #ffffff;
	color: #2d2d2d;
`
const TextBox = styled.input`
	height: 15px;
    width: 95%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
`;

const BoldLabel = styled.div`
	font-weight: 600;
	color: #2d2d2d;
`;

const CancelBtn = styled.button`
	padding: 3px 20px;
	border: 1px solid #37ADA7;
	background-color: #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
	float: right;
`;
const SaveBtn = styled.button`
	padding: 3px 30px;
	border: 1px solid #37ADA7;
	background-color: #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 8px;
	float: right;
`;



class APEditOptionDialog extends React.Component {
	state = {alert_param: null, option_text: ''}

	componentDidMount = () => {
		this.componentSetState()
	}

	componentDidUpdate = (prev_props) => {
		// if(this.props === prev_props)return
		if(this.props !== prev_props) {
			this.componentSetState()
		}
	}

	componentSetState = () => {
		this.setState({option_text: this.props.optionText})
	}

	inputChange = (event) => {
		event.preventDefault()
		this.setState({[event.target.name]: event.target.value})
	}

	processBlankName = () => {
		this.setState({alert_param: null})
	}

	processSaveOption = (result) => {
		this.props.onEditOption(this.props.feedId, result.result.option_id, result.result.option_text)
	}

	saveOption = () => {
		if(this.state.option_text === '') {
			let alert_param = {title: 'Error', confirm: false, message: 'Option text cannot be blank', alertHandler: this.processBlankName}
			this.setState({alert_param})
			return
		}
		// this.feed_id = Utils.genKey(16)
		let post_data = {command: 'update_option_feeds',option_id: this.props.optionId, option_text: this.state.option_text}
		console.log('saveOption post_data', post_data)
		let api = new APICall()
		api.command(post_data, this.processSaveOption)
	}
	// onEditOptionNew = (result) => {
	// 	let option_id = result.result.option_id;
	// 	let option_text = result.result.option_text;
	// 	let suggestions = this.state.suggestions
	// 	let original_suggestions = this.state.original_suggestions
	// 	suggestions[option_id].option_text = option_text
	// 	original_suggestions[option_id].option_text = option_text
	// 	this.setState({suggestions, original_suggestions, show_optioneditor: false})
	// }
	render() {
		return (<div>
			<APAddFeedDialogBox>
				<APAddFeedDialogHeader>
					Edit Option<AddFeedButton onClick={this.props.closeDialog} style={{paddingLeft: 2, paddingRight: 5}}><FaTimes /></AddFeedButton>
				</APAddFeedDialogHeader>
				<APAddFeedDialogBody>
					<BoldLabel>Option Text</BoldLabel>
					<TextBox type="text" autoFocus name='option_text' value={this.state.option_text} onChange={this.inputChange} />
				</APAddFeedDialogBody>
				<APAddFeedDialogFooter>
					<SaveBtn onClick={this.saveOption}>Save</SaveBtn><CancelBtn onClick={this.props.closeDialog}>Cancel</CancelBtn>
				</APAddFeedDialogFooter>
				{
					(() => {
						if(this.state.alert_param !== null) return <AlertBox alertParam={this.state.alert_param} />
					})()
				}
				
			</APAddFeedDialogBox>
		</div>)
	}
}


class APDropdown extends React.Component {
	state = {items: {}, selected_items: {}, searchtext: '', show_suggestion: false, suggestions: {}, show_optioneditor: false, edit_option_id: null, edit_option_text: '' }

	constructor(props) {
		super(props);
	}


	_handleKeyDown = (e) => {
		if (e.key === 'Enter' && this.state.searchtext !== '') {
			let suggestions = this.state.suggestions
			let original_suggestions = this.state.original_suggestions
			let exists = false
			for(let o_id in original_suggestions) {
				if(this.state.searchtext.toLowerCase().replace(/\s/g, '') === original_suggestions[o_id].option_text.toLowerCase().replace(/\s/g, ''))return
			}

			let post_data = {command: 'add_option_feeds', option_text: e.target.value}
			let api = new APICall()
			api.command(post_data, this.processAddOption)
		}
	}

	processAddOption = (result) => {
		console.log('result', result)
		let stack = this.props.stack
		let suggestions = this.state.suggestions
		let original_suggestions = this.state.original_suggestions
		let option_id = result.result.job_titles.id;
		suggestions[option_id] = {option_id: option_id, option_text: result.result.job_titles.JobTitle}
		original_suggestions[option_id] = {option_id: option_id, option_text: result.result.job_titles.JobTitle}
		stack.value = result.result.job_titles.JobTitle
		stack.option_id = option_id
		this.props.selectCallback(stack)
		this.setState({suggestions, searchtext: result.result.job_titles.JobTitle, original_suggestions})
	}
	processListOptions = (result) => {
		
		let suggestions_list = result.result.job_titles.rows;
		let suggestions = {}
		for(let s of suggestions_list) {
			console.log("SRESULT::",s[0])
			suggestions[s[0]] = {option_id:s[0],option_text:s[1]}
		}
		console.log(suggestions)
		this.setState({items: JSON.parse(JSON.stringify(suggestions)), suggestions: JSON.parse(JSON.stringify(suggestions)), searchtext: this.props.selectedText, original_suggestions: JSON.parse(JSON.stringify(suggestions)), show_suggestion: false})
	}
	componentSetState = () => {
		console.log("componentSetState fired", this.props.job_titles);
		

		let suggestions_list = this.props.job_titles;
		let suggestions = {}
		for(let s in suggestions_list) {
			//console.log("SRESULT::",s)
			suggestions[s] = {option_id:s,option_text:suggestions_list[s].JobTitle}
		}
		console.log(suggestions)
		this.setState({items: JSON.parse(JSON.stringify(suggestions)), suggestions: JSON.parse(JSON.stringify(suggestions)), searchtext: this.props.selectedText, original_suggestions: JSON.parse(JSON.stringify(suggestions)), show_suggestion: false})
		
	}
	componentDidMount = () => {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.componentSetState()
	}

	componentDidUpdate = (prev_props) => {
		if(this.props === prev_props)return
		// this.componentSetState()
	}
	componentWillUnmount = () => {
	    document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = (node) => {
	    this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		if (this.state.show_suggestion && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({show_suggestion: false});
		}
	}

	searchFocused = (event) => {
		event.preventDefault();
		this.setState({show_suggestion: true})
	}

	searchData = (event) => {
		event.preventDefault();
		console.log('searchData called')
		const search_string = event.target.value;
		// const search_exp = `^${search_string}`
		// const re = new RegExp(search_exp, 'gi');
		let items = JSON.parse(JSON.stringify(this.state.original_suggestions));
		let selected_items = JSON.parse(JSON.stringify(this.state.selected_items));
		let suggestions = {};
		// let new_options_toshow = [];
		if (search_string === "") {
			suggestions = {};
			Object.keys(items).forEach((id) => {
				if (!(id in selected_items)) {
					suggestions[id] = this.state.original_suggestions[id];
				}
			})
		} else {
			// Object.keys(items).forEach((id) => {
			// 	if (items[id].option_text.match(re) !== null && !(id in selected_items)) {
			// 		suggestions[id] = this.state.original_suggestions[id];
			// 	}
			// })
			Object.keys(items).forEach((id) => {
				if (items[id].option_text.toLowerCase().replace(/\s/g, '').includes(search_string.toLowerCase().replace(/\s/g, ''))) {
					suggestions[id] = this.state.original_suggestions[id];
				}
			})
		}

		console.log("suggestions", suggestions, search_string)
		// console.log("search_string", search_string)

		this.setState({searchtext: search_string, suggestions: suggestions, show_suggestion: true});
	}

	selectThis = (item_id) => (event) => {
		event.preventDefault();

		let suggestions = this.state.suggestions
		let searchtext = suggestions[item_id].option_text
		let stack = this.props.stack
		stack.value = searchtext
		stack.option_id = item_id
		this.props.selectCallback(stack)
		this.setState({searchtext , suggestions});
	}

	removeOptionProcessor = (result) => {
		console.log(result)
		
		let suggestions = this.state.suggestions
		let original_suggestions = this.state.original_suggestions
		
		let new_suggestions = {}
		let new_original_suggestions = {}
		let option_id = result.result.option_id;
		
		for(let sid of Object.keys(suggestions)) {
			if(sid !== option_id.toString())new_suggestions[sid] = suggestions[sid]
		}
		for(let osid of Object.keys(original_suggestions)) {
			if(osid !== option_id.toString())new_original_suggestions[osid] = original_suggestions[osid]
		}

		this.setState({suggestions: new_suggestions, original_suggestions: new_original_suggestions})
	}

	removeThis = (option_id) => {
		console.log('remove ', option_id)
		let post_data = {command: 'remove_option_feeds', option_id: option_id}
		let api = new APICall()
		api.command(post_data, this.removeOptionProcessor)
	}

	nameCompare = (a,b) => {
		return a.option_text.localeCompare(b.option_text)
	}

	scoreCompare = (a,b) => {
		return b.score - a.score
	}

	editThis = (option_id) => {
		console.log('editThis')
		this.setState({edit_option_id: option_id, show_optioneditor: true, edit_option_text: this.state.original_suggestions[option_id].option_text})
	}

	closeDialog = () => {
		this.setState({show_optioneditor: false})
	}

	onEditOption = (feed_id, option_id, option_text) => {
		let suggestions = this.state.suggestions
		let original_suggestions = this.state.original_suggestions
		suggestions[option_id].option_text = option_text
		original_suggestions[option_id].option_text = option_text
		this.setState({suggestions, original_suggestions, show_optioneditor: false})
	}

	render() {
		console.log('APDropdown this.state', this.state)
		if(this.state.items === null)return (<div></div>)

		let selected_items = [];
		for(let id in this.state.selected_items) {
			selected_items.push(this.state.selected_items[id])
		}
		let suggestions = [];
		for(let id in this.state.suggestions) {
			suggestions.push(this.state.suggestions[id])
		}
		// selected_items.sort(this.nameCompare);
		suggestions.sort(this.nameCompare);

		return (<div ref={this.setWrapperRef} style={{cursor: "text", position: 'relative'}} onClick={() => {this.myInp.focus()}}>

				<SearchBoxDiv>
					<SearchBox ref={(ip) => this.myInp = ip} 
						onClick={this.searchFocused} 
						value={this.state.searchtext} onChange={this.searchData} onKeyDown={this._handleKeyDown} />
				</SearchBoxDiv>
				{
					(() => {
						if(this.state.show_optioneditor) {
							return <APEditOptionDialog 
								feedId={this.props.feedId}
								optionId={this.state.edit_option_id}
								optionText={this.state.edit_option_text}
								closeDialog={this.closeDialog}
								onEditOption={this.onEditOption}
							/>
						}
					})()
				}
				{
					(() => {
						let num_suggestions = 0
						for(let s of suggestions) {
							if(s !== undefined)num_suggestions++
						}
						if (this.state.show_suggestion && !this.state.show_optioneditor) {
							return (
								<OptionsContainer>
									{
										(() => {
											if(num_suggestions === 0 && this.state.searchtext !== '') {
												return <div style={{fontSize: 14, padding: '5px 15px', width: 500}}>No matches found. Press Enter to add this to available options.</div>
											}
										})()
									}
									{
										suggestions.map((item) => {
											// console.log('suggestion item', item)
											if(item !== undefined) {
												return (<Option style={{fontSize: '14px', fontWeight: 500}} key={item.option_id} id={item.option_id}>
															<OptionText title={item.option_text} onClick={this.selectThis(item.option_id)}>{item.option_text}</OptionText>
															
															{/*<DelOptionBox onClick={() => this.removeThis(item.option_id)}><IoMdTrash /></DelOptionBox>
															<DelOptionBox onClick={() => this.editThis(item.option_id)}><IoMdCreate /></DelOptionBox>*/}
															<div style={{clear: 'both'}} />
														</Option>)
											}
										})
									}
								</OptionsContainer>
							);
						}
					})()
				}
			<div style={{clear: 'both'}} />
			</div>)
	}
}

export default APDropdown
