import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import CSLTable from '../../Common/CSLTable';
import CSLLoader from '../Common/CSLLoader.js';
import AlertBox from '../Common/AlertBox.js';
import EditTeamDialog from './EditTeamDialog.js'

class ManageTeams extends React.Component
{
	state = {  
        view_count: 0, 
        ready: false,
        contacts_byid : {},
        show_dialog:null,
        new_manager_id: null,
        edit_team_id: '0',
        contact_name: ''
	}

	componentDidMount = () => {
	    let contact_info = Store.getStoreData('contactInfo')
        this.setState({ready: true, contact_name: contact_info.ContactName})
    }

    processTeamData = () => {
        let ret = {data: [], columns: []};
        let teams = Store.getStoreData('teams')
        let contacts = Store.getStoreData('members')
        let primary_manager = 'N/A'
        for(let id in contacts) {
            // console.log('c', contacts[id])
            let c = contacts[id]
            if(c.isPrimaryContact)primary_manager = c.ContactName
        }
        ret.columns =[  {Header: 'Team Name', accessor: 'team_name', minWidth: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Primary Manager', accessor: 'primary_manager', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 50, headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                           {
                               (() => {
                                   if(row.original.id !== '')return <Style.SubmitButton style={{width: '150px'}} onClick={() => this.props.editTeam(row.original.id)}>View</Style.SubmitButton>
                               })()
                           }
                           </div>
                        ), width: 210, headerStyle: {textAlign: 'left'}}];
                        
        let index = 0
        for(let id in teams) {
            let t = teams[id]
            // console.log('t', t)
            let e = {
                index: index,
                team_name: t.name,
                primary_manager: id === 'org_level' ? primary_manager : contacts[t.manager_id.toString()].ContactName,
                status: t.active ? 'Active' : 'Inactive',
                id: id
            }
            ret.data.push(e)
            // console.log('e', e)
            index++
        }               
        return ret
    }

	render()
	{
		if(!this.state.ready) {
            return (
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <DefaultHeader />
                    <CSLLoader />
                </div>
            )
        }
		return (

            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <div style={{boxSizing: "border-box", padding: "15px 10px 0px 10px"}}>
                    <Style.SubmitButton style={{backgroundColor:"#1e3e62"}} onClick={() => this.props.editTeam('0')}>Add Team</Style.SubmitButton>
                </div>
                <div style={{padding: "15px 9px 45px 9px"}}>
                    <CSLTable add={false} processData={this.processTeamData} tableRows={10} headerText={'Team List'} />
                </div>
                
            </div>
		  	
		);
	}
}

export default ManageTeams;