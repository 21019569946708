import React from 'react';
import APICall from '../../Common/APICall';
import Reload from '../../Common/Reload';
import EventBus from '../../Common/EventBus.js';
import Store from '../../Common/Store';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import  '../../App.css';
import {FaRegCheckSquare, FaRegSquare} from "react-icons/fa";
import OrgUtils from '../../Common/OrgUtils.js';
import AlertBox from '../Common/AlertBox.js'
import moment from 'moment'
import CSLLoader from '../Common/CSLLoader.js';
import APDropdown from "../Common/APDropdown/APDropdown.js";

const ContactInput = styled.input`
    height: 20px;
    width: 92%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
    font-weight:600;
`;
const ValidationMsg = styled.div`
    margin-top: 5px;
    color: #d60606;
    font-weight: 400;
`;
const Right = styled.label`
    margin-left: 10px;
    color: green;
`
const Wrong = styled.label`
    margin-left: 10px;
    color: red;
`;

const UserHr = styled.div`
    border-top: 1px solid #A0CDFF;
    margin-left: -37px;
    margin-right: -37px;
`;

const SpacerXL = styled.div`
    height: 20px;
`;

const SpacerL = styled.div`
    height: 10px;
`;

const ContactLabel = styled.label`
    color: #3C3C3C;
    font-weight: 600;
    margin-top: 10px;
    font-size:17px
`;

const UserSelect = styled.select`
    
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: 94%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
    font-weight:600;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
    `;
class UserForm extends React.Component
{
	state = {
                form_data: {ID: 0, ContactName :'', Direct:'', KnownAs:'',mcJobTitleID: '0', JobTitleID:'0',JobTitleText:'', IRN:'', RegulatoryStatusID:'0', RegulatoryStatusDate: new Date(),
                Switchboard:'', Fax:'', Address1:'', Address2:'', Town: '', County:'', Postcode:'',
                isActive: true, isPrimaryContact:false, is_smcr_emp:false, role: 'no_access', languages: [], user_langs: {}, selct_lang: 'en', is_lan: false  },
                form_data_store: null,
                regulatory_status : [],
                job_titles:[],
                smcr_job_titles:[],
                is_dirty: false,
                original_date: '',
                alert_param: null,
                isLoadedIcon: false

    };

    componentSetState = () => {
        console.log('componentSetState', moment().unix())
        let regulatory_status = Store.getStoreData('regulatory_status')
        regulatory_status = regulatory_status === null ? [] : regulatory_status
        // console.log('userform this.props, regulatory_status', this.props.smcrjobTitles, Object.keys(this.props.smcrjobTitles)[0])
        if(this.props.userId.toString() === '0') {
            let form_data = this.state.form_data
            form_data['RegulatoryStatusID'] = regulatory_status[0].ID;
            let form_data_store = JSON.parse(JSON.stringify(form_data))
            form_data_store['languages'] = Store.getStoreData('languages');
            form_data_store['user_langs'] = Store.getStoreData('user_langs');
            form_data_store['is_lan'] = Store.getStoreData('is_lan');
            form_data_store['selct_lang'] = 'en';

            form_data['languages'] = Store.getStoreData('languages');
            form_data['user_langs'] = Store.getStoreData('user_langs');
            form_data['is_lan'] = Store.getStoreData('is_lan');
            form_data['JobTitleID'] = Object.keys(this.props.smcrjobTitles)[0]
            // console.log("form_data", form_data)
            this.setState({form_data, form_data_store, regulatory_status, job_titles: this.props.JobTitles, smcr_job_titles: this.props.smcrjobTitles})
            return
        }
        let contact_details = Store.getStoreData('contact_details')

        // console.log('contact_info componentSetState contact_details, regulatory_status', contact_details, regulatory_status)
        if(contact_details !== null && this.props.userId.toString() in contact_details) {
            let form_data = contact_details[this.props.userId]
            form_data.RegulatoryStatusID = form_data.RegulatoryStatusID.toString()
            form_data.RegulatoryStatusDate = moment(form_data.RegulatoryStatusDate)
            let form_data_store = JSON.parse(JSON.stringify(form_data))

            let languages = Store.getStoreData('languages');
            let user_langs = Store.getStoreData('user_langs');

            // alert(user_langs[this.props.userId.toString()])
            let is_lan = Store.getStoreData('is_lan');
            let selct_lang = this.state.form_data.selct_lang;
            if(is_lan){
               selct_lang =  user_langs[this.props.userId.toString()] === undefined ? 'en' : user_langs[this.props.userId.toString()].lang;
            }
            form_data_store['languages'] = Store.getStoreData('languages');
            form_data_store['user_langs'] = Store.getStoreData('user_langs');
            form_data_store['is_lan'] = Store.getStoreData('is_lan');
            form_data_store['selct_lang'] = selct_lang;

            form_data['languages'] = Store.getStoreData('languages');
            form_data['user_langs'] = Store.getStoreData('user_langs');
            form_data['is_lan'] = Store.getStoreData('is_lan');
            form_data['selct_lang'] = selct_lang;

            this.setState({form_data, form_data_store, regulatory_status, job_titles: this.props.JobTitles})
            return
        }
        let api = new APICall()
        let postData = {command: 'get_contact_detail', user_id: this.props.userId, get_regulatory_statuses: regulatory_status === null ? true : false}
        api.command(postData, this.processGetContactDetail)
    }

    processGetContactDetail = (result) => {
        console.log('contact_info result', result)
        let form_data = result.result.form_data

        form_data.RegulatoryStatusID = form_data.RegulatoryStatusID.toString()
        // form_data.RegulatoryStatusDate = moment(form_data.RegulatoryStatusDate).format('YYYY-DD-MM')
        let original_date = form_data.RegulatoryStatusDate.split('T')[0]
        form_data.RegulatoryStatusDate = new Date(form_data.RegulatoryStatusDate)

        let years = form_data.RegulatoryStatusDate.getTime() / (24*60*60*365)
        if(years < 30)form_data.RegulatoryStatusDate = new Date()
        let form_data_store = JSON.parse(JSON.stringify(form_data))
        let regulatory_status = Store.getStoreData('regulatory_status')
        if(regulatory_status === null) {
            regulatory_status = result.result.regulatory_statuses
            for(let r of regulatory_status) {
                r.ID = r.ID.toString()
            }
        }

        let languages = Store.getStoreData('languages');
        let user_langs = Store.getStoreData('user_langs');

        // alert(user_langs[this.props.userId.toString()])
        let is_lan = Store.getStoreData('is_lan');
        let selct_lang = this.state.form_data.selct_lang;
        if(is_lan){
           selct_lang =  user_langs[this.props.userId.toString()] === undefined ? 'en' : user_langs[this.props.userId.toString()].lang;
        }
        form_data_store['languages'] = Store.getStoreData('languages');
        form_data_store['user_langs'] = Store.getStoreData('user_langs');
        form_data_store['is_lan'] = Store.getStoreData('is_lan');
        form_data_store['selct_lang'] = selct_lang;

        form_data['languages'] = Store.getStoreData('languages');
        form_data['user_langs'] = Store.getStoreData('user_langs');
        form_data['is_lan'] = Store.getStoreData('is_lan');
        form_data['selct_lang'] = selct_lang;
        this.setState({form_data, form_data_store, regulatory_status, job_titles: this.props.JobTitles, smcr_job_titles: this.props.smcrjobTitles, original_date})
    }

	componentDidMount = () => {
        this.componentSetState()

	}

    componentDidUpdate = (prev_props) => {
        if(this.props !== prev_props) {
            this.componentSetState()
        }
    }
    onAPDropdownSelect = (stack) => {
        console.log("onAPDropdownSelect", stack);
        let field_name = stack.data_container
        let form_data = this.state.form_data;
        let form_data_store = this.state.form_data_store;
        form_data[field_name] = stack.option_id
        let job_titles = this.state.job_titles;
        let s = {};
        s['JobID'] = stack.option_id
        s['JobTitle'] = stack.value
        job_titles[stack.option_id.toString()] = s;
        Store.updateStore('job_titles', job_titles)
        // console.log("onAPDropdownSelect job_titles", job_titles, s)
        let is_dirty = (JSON.stringify(form_data) !== JSON.stringify(form_data_store)) ? true : false
        EventBus.raiseEvent('form_dirty', {form_id: 'user', is_dirty: is_dirty})
        this.setState({form_data , is_dirty, job_titles: job_titles});
    }
    handleChange = (evt) => {

        // console.log("emaqil==>",this.multiselectRef.current)
        let field_name = [evt.target.name]
        let form_data = this.state.form_data;
        let form_data_store = this.state.form_data_store;
        form_data[field_name] = evt.target.value
        // if(field_name === ['RegulatoryStatusID']) form_data[field_name] = parseInt(evt.target.value)
        // console.log('userform field_name, form_data, form_data_store', field_name, form_data, form_data_store)
        let is_dirty = (JSON.stringify(form_data) !== JSON.stringify(form_data_store)) ? true : false
        EventBus.raiseEvent('form_dirty', {form_id: 'user', is_dirty: is_dirty})

        // if(is_dirty)this.props.setDirtyFlag(form_data)

        this.setState({form_data , is_dirty});
    }

    ValidateEmail = (mail) =>{
        if(mail == ''){
            return 0;
        }else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(mail)){
        return 1;
        }else{
        return 2;
      }

    }
    onStatusDateChange = (date) => {
        console.log('userform date', date)
        let form_data = this.state.form_data
        let form_data_store = this.state.form_data_store
        form_data.RegulatoryStatusDate = date
        // let is_dirty = (form_data !== form_data_store) ? true : false
        let is_dirty = (JSON.stringify(form_data) !== JSON.stringify(form_data_store)) ? true : false
        EventBus.raiseEvent('form_dirty', {form_id: 'user', is_dirty: is_dirty})
        // this.props.setDirtyFlag(this.state)
        this.setState({form_data, is_dirty});
    }

    closeAlert = () => {
        this.setState({alert_param: null})
    }

    formSaveComplete = (e, stack) => {
        let my_role = Store.getStoreData('my_role')
        this.props.formSaved('user')
        let form_data = this.state.form_data
        form_data['ID'] = stack.ID
        if(my_role==="team") {
            this.setState({alert_param: null, form_data: form_data})
        }else{
            e === true ? this.setState({alert_param: null, form_data: form_data}) : this.props.CloseDialog()
        }

        // this.setState({alert_param: null, form_data: form_data})

    }

    processSave = (result) => {
        let alert_param = {title: 'ERROR', message: 'Operation failed. Please try after some time.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.closeAlert}
        if(result.error_code === 0) {
            let members = Store.getStoreData('members')
            members[result.user_id.toString()] = result.user
            let user_langs = result.user_langs;
            Store.updateStore('members', members)
            Store.updateStore('user_langs', user_langs)
            console.log('userform members, result', result.user)
            Reload.RestoreContact(result.user);
            alert_param = {title: 'Confirm', message: 'These edits will be saved, do you want to make further changes?', ok_text: 'Continue', cancel_text: 'Exit', confirm: 'confirm',
                           alertHandler: this.formSaveComplete, stack: {ID: result.user_id}}
        }
        this.setState({alert_param, isLoadedIcon: false})
    }

    handleSave = (event) => {
        event.preventDefault();
        let form_data = this.state.form_data
        console.log('userform postData',form_data)
        var isContactNameProvided = this.state.form_data.ContactName === '' ? false : true;
        var JobTitleID = this.state.form_data.JobTitleID === '0' ? false : true;

        if(!isContactNameProvided) {
            alert('ERROR: Contact name cannot be blank.')
            return
        }
        if(!JobTitleID) {
            alert('ERROR: Please select the Job Title.')
            return
        }
        this.setState({isLoadedIcon: true})
        let api = new APICall()
        let postData = {command: 'updateContacts', contact_info: form_data}
        console.log('userform postData',postData)
        api.command(postData, this.processSave)
    }
    changePrimaryStatus = (event) => {
        event.preventDefault();
        let form_data = this.state.form_data
        let form_data_store = this.state.form_data_store

        if(form_data.isPrimaryContact){
            form_data.isPrimaryContact = false
        }else{
            form_data.isPrimaryContact = true
        }
        let is_dirty = (JSON.stringify(form_data) !== JSON.stringify(form_data_store)) ? true : false
        EventBus.raiseEvent('form_dirty', {form_id: 'user', is_dirty: is_dirty})
        this.setState({form_data, is_dirty})
    }
    changeSMCR_EmpStatus = (event) => {
        event.preventDefault();
        let form_data = this.state.form_data
        let form_data_store = this.state.form_data_store

        if(form_data.is_smcr_emp){
            form_data.is_smcr_emp = false
        }else{
            form_data.is_smcr_emp = true
        }
        let is_dirty = (JSON.stringify(form_data) !== JSON.stringify(form_data_store)) ? true : false
        EventBus.raiseEvent('form_dirty', {form_id: 'user', is_dirty: is_dirty})
        this.setState({form_data, is_dirty})
    }

	render()
	{

        if(this.state.form_data_store === null) return <div />
        let current_team = this.props.teamId
        // console.log("current_team in userform",current_team)
        let list_roles = Store.getStoreData('list_roles')
        console.log('userform list_roles', list_roles)
        let smcr_job_titles = this.state.smcr_job_titles;
        console.log('job_titles', smcr_job_titles)
        let jobs = [];
        for (let k in smcr_job_titles) {
          if (smcr_job_titles.hasOwnProperty(k)) {
            jobs.push(smcr_job_titles[k]);
          }
        }
        jobs.sort(function(a,b) {return (a.JobTitle > b.JobTitle) ? 1 : ((b.JobTitle > a.JobTitle) ? -1 : 0);} );










        console.log("contact_info in userform",this.state.form_data.JobTitleID)

        return (
			<div>
			{
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader /></div>
                    }else{
                        console.log("State::",this.state)
                    }
                })()
            }
			<Style.UserFormContainer style={{}}>
                 <SpacerXL/>
                 <SpacerL/>
                 <SpacerXL/>
				 <ContactLabel>Name</ContactLabel>
                    <SpacerL/>
                    <ContactInput type="text" name="ContactName" onChange={this.handleChange} value={this.state.form_data.ContactName} />
                    {
                    (() => {
                        if(this.state.isContactNameProvided == 0){
                            return <ValidationMsg>Please provide contact name.</ValidationMsg>;
                        }
                     })()
                    }
                    <SpacerXL/>
                    <ContactLabel>Known As</ContactLabel>
                    <SpacerL/>
                    <ContactInput type="text" name="KnownAs" onChange={this.handleChange} value={this.state.form_data.KnownAs} />
                    <SpacerXL/>
                     <ContactLabel>Job Title</ContactLabel>
                    <SpacerL/>
                    <APDropdown id={"apd-jobtitle"} feedId={'jobtitle'} selectedText={ this.state.form_data.mcJobTitleID  in this.state.job_titles ? this.state.job_titles[this.state.form_data.mcJobTitleID.toString()].JobTitle : ''} selectCallback={this.onAPDropdownSelect} stack={{  data_container: 'mcJobTitleID' }} job_titles={this.state.job_titles} />
                    <SpacerXL/>
                    <ContactLabel>IRN</ContactLabel>
                    <SpacerL/>
                    <ContactInput type="text" name="IRN" onChange={this.handleChange} value={this.state.form_data.IRN} />
                    <SpacerXL/>
                    <ContactLabel>Regulatory Status</ContactLabel>
                    <SpacerL/>
                    <UserSelect onChange={this.handleChange} name="RegulatoryStatusID" value={this.state.form_data.RegulatoryStatusID} >
                        {
                            (() => {
                                if(this.state.regulatory_status.length !== 0) {
                                    let regulatory_status = [];
                                    for(let cur_item of this.state.regulatory_status){
                                        regulatory_status.push(<option key={cur_item.ID} value={cur_item.ID}>{cur_item.Text}</option>);
                                    }
                                    return regulatory_status;
                                }
                            })()
                        }
                    </UserSelect>
                    <SpacerXL/>
                    <ContactLabel>Status Date</ContactLabel>
                    <SpacerL/>
                    <CSLDateCover style={{display: "inline-block"}}>
                                        <DatePicker
                                                onChange={this.onStatusDateChange}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.form_data.RegulatoryStatusDate}
                                        />
                    </CSLDateCover>
                    <SpacerXL/>
                    {
                        (() => {
                            if(this.state.form_data.is_lan) {
                                return(<div>
                                    <SpacerXL/>
                                     <ContactLabel>Language Preferences</ContactLabel>
                                    <SpacerL/>
                                    <UserSelect onChange={this.handleChange} name="selct_lang" value={this.state.form_data.selct_lang} >
                                        {
                                            (() => {
                                                if(this.state.form_data.languages.length !== 0) {
                                                    let lags = [];
                                                    let i=0
                                                    for(let l of this.state.form_data.languages){
                                                        lags.push(<option key={l.language_id} value={l.language_id}>{l.language_name}</option>);
                                                        i++
                                                    }
                                                    return lags;
                                                }
                                            })()
                                        }
                                    </UserSelect>
                                </div>)
                            }
                        })()
                    }
                    <SpacerXL/>

                    {
                        (() => {
                            if(Store.getStoreData('my_role') !== "team") {
                                return(<div style={{display:"contents"}}>
                                    <ContactLabel style={{marginLeft:"40px"}}>Primary User</ContactLabel>
                                    {
                                        (()=>{
                                            if(this.state.form_data.isPrimaryContact){
                                                return <FaRegCheckSquare onClick={this.changePrimaryStatus} style={{cursor: "pointer", marginLeft: "10px"}}/>
                                            }else{
                                                return <FaRegSquare onClick={this.changePrimaryStatus} style={{cursor: "pointer", marginLeft: "10px"}}/>
                                            }
                                        })()
                                    }

                                </div>)
                            }
                        })()
                    }

                    <ContactLabel style={{marginLeft:"40px"}}>Access to Boards and Committees Module</ContactLabel>
                    {
                        (()=>{
                            if(this.state.form_data.is_smcr_emp){
                                return <FaRegCheckSquare onClick={this.changeSMCR_EmpStatus} style={{cursor: "pointer", marginLeft: "10px"}}/>
                            }else{
                                return <FaRegSquare onClick={this.changeSMCR_EmpStatus} style={{cursor: "pointer", marginLeft: "10px"}}/>
                            }
                        })()
                    }

                    <SpacerXL/>
                    {/*
                        (() => {
                            if(this.state.form_data.is_smcr_emp){
                                return(<div>
                                    <ContactLabel>SMCR Job Title</ContactLabel>
                                    <SpacerL/>
                                    <UserSelect onChange={this.handleChange} name="JobTitleID" value={this.state.form_data.JobTitleID} >
                                    {
                                        (() => {
                                            if(jobs.length !== 0) {
                                                let jk = [];
                                                for(let jo of jobs){
                                                    jk.push(<option key={jo.JobID} value={jo.JobID}>{jo.JobTitle}</option>);
                                                }
                                                return jk;
                                            }
                                            })()
                                    }
                                    </UserSelect>
                                    <SpacerXL/>
                                </div>)
                            }
                        })()
                    */}
                    <SpacerL/>
                    <SpacerXL/>
                    <SpacerXL/>
                    <SpacerL/>
                    <Style.UserFormFooter>

                        {
                            (() => {
                            if(current_team === 'org_level' && this.state.is_dirty){
                                return (<div>

                                    {
                                        (() => {
                                            if(Store.getStoreData('my_role') !== "team") {
                                                return(<Style.MRButton style={{top: '55px', right: '220px', position: 'absolute', backgroundColor: '#FFFFFF', color: '#757575'}} onClick = {this.props.CloseDialog}>Cancel</Style.MRButton>)
                                            }
                                        })()
                                    }

                                    <Style.MRButton style={{top: '55px', right: '80px', position: 'absolute', backgroundColor: '#046EE5', color: 'white'}} onClick = {this.handleSave}>Save</Style.MRButton>
                                </div>)
                            } else{
                                return (<div>

                                    {
                                        (() => {
                                            if(Store.getStoreData('my_role') !== "team") {
                                                return(<Style.MRButton style={{top: '55px', right: '65px', position: 'absolute', backgroundColor: '#FFFFFF', color: '#757575'}} onClick = {this.props.CloseDialog}>Cancel</Style.MRButton>)
                                            }
                                        })()
                                    }
                                </div>)
                            }

                         })()
                        }

                    </Style.UserFormFooter>
			</Style.UserFormContainer>
			<AlertBox alertParam={this.state.alert_param} />
			</div>
		);
	}
}

export default UserForm;
