import React from 'react';
import Store from '../Common/Store.js';
import OrgUtils from '../Common/OrgUtils.js';
import Utils from '../Common/Utils.js';
import APICall from '../Common/APICall.js';
import * as Style from './Common/StyledComponents';
import {FaPlus, FaPencilAlt, FaTimes, FaTrash, FaEllipsisV, FaUser} from 'react-icons/fa';
import ManagerSelector from './ManagerSelector.js'
import styled from 'styled-components'

const card_width = 423;

const InactiveLabel = styled.div`
    position: absolute;
    top: 100px;
    right: 4px;
    border-radius: 10px;
    height: 25px;
    width: 150px;
    background-color: red;
    color: white;
    padding-top: 7px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
`

const MemberBox = styled.div`
  position: absolute;
  display: block;
  border-width:1px;
  border-left-width: 10px;
  border-style: solid;
  border-color: #00CDFF;
  border-radius: 3px 10px 10px 3px;
  padding-left: 0px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  color: #1A3552;
  width: 400px;
`;
const MemberBoxHeader = styled.div`
  position: absolute;
  top: -5px;
  left:0px;
  display: block;
  padding-left: 15px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  color: #046DE4;
  width: 400px;
`;
const MemberButton = styled.div`
  position: absolute;
  display: block;
  font-size: 14px;
  padding-top: 2px;
  padding-left: 1px;
  padding-right: 1px;
  font-weight: 600;
  border-width:1px;
  text-align: center;
  border-style: solid;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  height: 25px;
  color: #000;
  width: 25px;
  z-index: 50000;
`;

const MemberConnector = styled.div`
  display: block;
  position: absolute;
  border-color: #046DE4;
  left: -69px;
  top: 2px;
  background-color: transparent;
  border-bottom-width:4px;
  border-left-width:4px;
  border-left-style: solid;
  border-bottom-style: solid;
  width: 50px;
`;

const MemberSelectionBox = styled.div`
  position: absolute;
  z-index: 2000;
  top: 30px;
  left: 15px;
  display: block;
  background-color: white;
  border-width:2px;
  border-color: #1A3552;
  border-style: solid;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  height: 250px;
  color: blue;
  width:300px;
`;

const MemberSelectionBoxHeader = styled.div`
  width: calc(100% - 19px);
  height: 30px;
  color: white;
  font-size: 16px;
  padding-left: 15px;
  padding-top: 5px;
  margin-left: -1px;
  margin-top:-1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-color: #1A3552;
  border-style: solid;
  background-color: #1A3552;
`;

const MemberSelectionBoxRow = styled.div`
  width: 100%;
  height: 25px;
  color: #2d2d2d;
  cursor: pointer;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: white;
    background-color: grey;
  }
`;
const MemberMenuButton = styled.div`
    position: absolute;
    background-color: #FEEBF4;
    font-size: 18px;
    padding-left: 7px;
    padding-top: 7px;
    top: 0px;
    right: -16px;
    color: #F5239A;
    border-radius: 50%;
    border-color: #F5239A;
    border-style: solid;
    border-width: 1px;
    width: 26px;
    height: 26px;
    cursor: pointer;
`;

const MemberMenu = styled.div`
    position: absolute;
    background-color: #FEEBF4;
    font-size: 16px;
    top: 10px;
    left: 415px;
    color: #F5239A;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 4px;
    width: 200px;
    z-index: 4000;
`;

const MemberMenuLine = styled.div`
    width: calc(100% - 20px);
    padding-left: 15px;
    padding-top: 1px;
    padding-bottom: 6px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;

`;

const MemberMenuLineSpacer = styled.div`
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F5DADA;
    margin-bottom: 5px;
    margin-top: 3px;
`;

const MemberBoxHr = styled.div`
    position: absolute;
    border-top: 1px solid #A0CDFF;
    width: 100%;
`;

const MemberPhotoBox = styled.div`
    position: absolute;
    top: 12px;
    left: 15px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
`;

const MemberProfileBox = styled.div`
    position: absolute;
    top: 17px;
    left: 80px;
    color: #046DE4;
    width: 320px;
    font-size: 22px;
    font-weight: 700;
`;

const x_offset = 120;
const y_offset = 10;
const box_height = 100;
const line_height = 18;
const vert_gap = 50;
const line_len = 50;

class MemberAltManagersLine extends React.Component {
    render() {
        if(this.props.line.length === 0)return <div />
        return (<div>
        {
            this.props.line.map((w, i) => {
                return (<span key={i}>{w.name}, </span>)
            })
        }
        </div>)
    }
}

class MemberAltManagersPanel extends React.Component {
    render() {
        // // console.log('MemberAltManagersPanel this.props.card.mgr_lines.length', this.props.card.mgr_lines.length)
        if(this.props.team_id !== 'org_level') return <div />
        if(this.props.card.mgr_lines.length === 0) return <div />
        if(this.props.card.mgr_lines[0].length === 0) return <div />
        // // console.log('this.props.card.mgr_lines', this.props.card.mgr_lines)
        let c = this.props.card
        let teams_num = Object.keys(c.teams).length - 1
        let team_height = (teams_num > 0 ? 1 : 0) * 25 + teams_num * line_height
        let mgr_top = box_height + team_height
        let mgr_lines = this.props.card.mgr_lines
        if(this.props.card.mgr_lines.length > 0) return (<div>
                <MemberBoxHr style={{position: 'absolute', top: box_height + team_height - 5, left: 0}}/>
                <div style={{position: 'absolute', top: box_height + team_height, left: 18, fontSize: '16px'}}>Manager(s):</div>
                <div style={{position: 'absolute', top: box_height + team_height + 25, left: 18}}>
                {
                    mgr_lines.map((l, i) => {
                        return <MemberAltManagersLine key={i} line={l} />
                    })
                }
                </div>
            </div>)
    }
}

class MemberTeamsPanel extends React.Component {
    render() {

        if(this.props.team_id !== 'org_level') return <div />
        if(Object.keys(this.props.card.teams).length < 2) return <div />
        let teams = JSON.parse(JSON.stringify(this.props.card.teams))
        delete teams.org_level
        return (<div>
            <div style={{position: 'absolute', top: '100px', left: '18px', fontSize: '16px'}}>Team(s):</div>
            {
                Object.keys(teams).map((id, i) => {
                    let t = teams[id]
                    // // console.log('t', t)
                    return (<div key={i} style={{position: 'absolute', top: `${box_height + 20 + i * line_height}px`, left:'18px',
                                fontSize:'14px', fontWeight: '700', width:'93%', cursor: 'default',
                                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} title={t.name}>
                                {
                                    (() => {
                                        let user_color = t.is_manager ? '#F50089' : '#A5A5A5'
                                        return (<FaUser style={{color: user_color}} />)
                                    })()
                                }
                                &nbsp;&nbsp;
                                {t.name}
                    </div>)
                })
            }
        </div>)
    }
}


class OrgMember extends React.Component
{
    state = {card: null, team_id: 'org_level', modal_view_addmanager: false, current_team: null, show_menu_button: false, show_menu: false, pic: null, show_menu_button: false, show_menu: false };

    setComponentState = () => {
        this.setState({team_id: this.props.teamId, show_menu: false, card: this.props.card, team_id: this.props.team_id})
        // // console.log('card this.state.pic', this.props.card, this.state.pic)
        let contacts = Store.getStoreData('members')
        // // console.log('card contact', contacts[this.props.card.id.toString()])
        // // console.log('included', this.props.card.id in this.props.contacts)

        if(!(this.props.card.id in this.props.contacts))return
        let contact = this.props.contacts[this.props.card.id]
        let pic = contact.pic

        if(contact['pic'] === 0) {
            this.setState({pic: null})
            return
        }

        // // console.log('card contact this.state.pic', contacts[this.props.card.id.toString()], this.state.pic)
        let pics = Store.getStoreData('pics')
        pics = pics === null ? {} : pics
        if(this.props.card.id in pics) {
            this.setState({pic: pics[this.props.card.id]})
            return
        }

        let api = new APICall()

        let postData = {command: 'get_pic', user_id: this.props.card.id}
        api.command(postData, this.processPic)
    }

    processPic = (result) => {
        if(result.error_code === 0 && result.pic !== null) {
            let pics = Store.getStoreData('pics')
            pics = pics === null ? {} : pics
            pics[this.props.card.id] = result.pic
            Store.updateStore('pics', pics)
            this.setState({pic: result.pic})
        }
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prev_props) => {
        if(prev_props === this.props) return

        this.setComponentState()
    }

    showMemberMenuButton = () => {
        this.setState({show_menu_button: true})
    }

    hideMemberMenuButton = () => {
        this.setState({show_menu_button: false})
    }

    showMemberMenu = () => {
        this.setState({show_menu: true, show_menu_button: true})
    }

    hideMemberMenu = () => {
        this.setState({show_menu: false, show_menu_button: false})
    }

    render()
    {
        if(this.state.card === null)return (<div></div>)

        let c = this.props.card
        console.log('member c', this.props.contacts[c.id].isActive, c)
        // // console.log('card this.state.pic', this.state.pic)
        let my_role = Store.getStoreData('my_role')
        let contact_name = c.id in this.props.contacts ? this.props.contacts[c.id].ContactName : 'N/A'
        let isActive = this.props.contacts[c.id].isActive
        let job_title = c.id in this.props.contacts && this.props.contacts[c.id].mcJobTitleID in this.props.job_titles ? this.props.job_titles[this.props.contacts[c.id].mcJobTitleID].JobTitle : ''
        // // console.log('Member my_contact', my_contact)
        let teams = Store.getStoreData('teams')
        let roles = Store.getStoreData('roles')
        let user_role = roles[c.id.toString()]
        // // console.log('user_role', user_role)

        let team_manager_id = teams[this.state.team_id].manager_id
        // // console.log('Member team, team_manager_id', teams[this.state.team_id], team_manager_id)
        let allowed_roles = Store.getStoreData('allowed_roles')
        let border_width = this.props.searchUserId === parseInt(c.id) ? 'thick' : 'thin'

        return (<div onMouseEnter={this.showMemberMenuButton} onMouseLeave={this.hideMemberMenuButton}>
        {
            (() => {
                if(c.level > 0) return <Style.MemberConnector style={{width: 40, top: c.manager_bot - this.props.topOffset + 10, left: c.level * 50 - 20, height: (c.member_top - c.manager_bot)}} />
            })()
        }

        <MemberBox style={{top: c.member_top - this.props.topOffset, left: 30 + c.level * 50, height: c.height, borderTopWidth: border_width, borderRightWidth: border_width, borderBottomWidth: border_width}}>
            <MemberBoxHeader>
                <MemberPhotoBox>
                {
                    (() => {
                       if(this.state.pic === null) return (<img src= "/default_profile.png" style={{width:'55px', height: '55px', borderRadius: '50%'}} />)
                       else return (<img src= {'data:image/png;base64,'+ this.state.pic.replace(/ /g, '+')} style={{width:'55px', height: '55px', borderRadius: '50%'}} />)
                    })()
                }
                </MemberPhotoBox>
                <MemberProfileBox>
                     {contact_name}({c.id})
                    <div style={{color: '#7B7B7B', height: '15px', fontSize: '16px', fontWeight:'700', marginTop: '5px'}}>{job_title}</div>
                </MemberProfileBox>
            </MemberBoxHeader>
            {
                (() => {
                    if(this.state.team_id === 'org_level') return (<div>
                            <MemberBoxHr style={{top: 95, width: '100%'}} />
                            {
                                (() => {
                                   if(!isActive) return <InactiveLabel>Inactive User</InactiveLabel>
                                })()
                            }
                            <MemberTeamsPanel card={c} teams={this.props.teams} team_id={this.props.team_id} />
                            <MemberAltManagersPanel card={c} teams={this.props.teams} team_id={this.props.team_id} />
                        </div>)
                    else return <div />
                })()
            }
        </MemberBox>
        {
            (() => {
               if(this.state.show_menu_button) {
            //   // console.log('MemberMenuButton, c', c)
                   return (<MemberMenuButton style={{top: c.member_top - 16 - this.props.topOffset, left: card_width + c.level * 50}} onMouseEnter={this.showMemberMenu} onMouseLeave={this.hideMemberMenu}>
                        <FaEllipsisV />
                    </MemberMenuButton>)
               }
            })()
        }
        {
            (() => {
               if(this.state.show_menu) {
                return (<MemberMenu style={{top: c.member_top - this.props.topOffset - 4, left: card_width - 100 + c.level * 50}} onMouseEnter={this.showMemberMenu}  onMouseLeave={this.hideMemberMenu}>

                    {
                        (() => {
                            if(this.state.team_id !== 'org_level' && c.id.toString() === team_manager_id.toString()) {
                                return (<div>
                                    <Style.MemberMenuLine title={'Edit team.'} onClick={() => this.props.editTeam(this.state.team_id)}>Edit Team</Style.MemberMenuLine>
                                </div>)
                            }
                        })()
                    }
                    <MemberMenuLine title={'Modify user profile data.'} onClick={() => this.props.editUser(c.id)}>Edit User Details</MemberMenuLine>

                    {
                        (() => {
                           if(my_role === 'admin_manager' && user_role in allowed_roles && this.state.team_id === 'org_level' && isActive) {
                               return (
                                <Style.MemberMenuLine title={'Copy the tree starting with current member and form a new Team'} onClick={() => this.props.copyToNewTeam(c.id)}>Copy to new Team</Style.MemberMenuLine>
                               )
                           }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.team_id !== 'org_level' && c.id.toString() !== team_manager_id.toString()) {
                                return (<div>
                                    <Style.MemberMenuLine title={'Remove user from this team.'} onClick={() => this.props.removeUser(c.id, this.state.team_id)}>Remove User</Style.MemberMenuLine>
                                </div>)
                            }
                        })()
                    }
                </MemberMenu>)
               }
            })()
        }
        </div>)
    }
}
export default OrgMember;
