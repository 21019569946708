import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import EventBus from '../../Common/EventBus.js';
import Store from '../../Common/Store.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import SelectTeam from '../Team/SelectTeam';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {FaTimes} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import OrgUtils from '../../Common/OrgUtils.js';
import AlertBox from '../Common/AlertBox.js'
import CSLCheckBox from '../Common/CSLCheckBox.js'
import CSLMultiSelect from '../Common/CSLMultiSelect.js'
import Draggable from 'react-draggable';
import styled from 'styled-components';
// import AlertBox from '../Common/AlertBox.js'

const ModalContainer = styled.div`
    width : 750px;
    top : 70px;
    left : 25%;
    position : absolute;
    background-color : white;
    box-sizing : border-box;
    border-radius : 5px;
    box-shadow : 0px 0px 5px #aaaaaa;
    border : 1px solid #00CDFF;
`

const Label = styled.div`
    padding : 15px 5px 5px 15px;
    color : #046DE4;
    font-size : 18px;
    
`
const LargeLabel = styled.div`
    padding : 10px;
    padding-left : 15px;
    color : #046DE4;
    font-size : 24px;
    font-weight : 600;
    
`

const GridLabel = styled.div`
    padding : 10px 10px 10px 0px;
    color : #3c3c3c;
    font-size : 18px;
    font-weight : 600;
`

const ModalHeader=styled.div`
    border-left : 7px solid #00CDFF;
    background : white;
    box-sizing : border-box;
`

const ModalBody=styled.div`
    background-color : #F3F7FB;
    width : 100%;
    padding : 15px;
    
    box-sizing : border-box;
`

const ModalFooter=styled.div`
    height : 70px;
    background-color: #fff;
    box-sizing : border-box;
    display : flex;
    flex-direction : row;
    justify-content : flex-end;
    align-items : center;
    padding : 20px;
    border-radius : 5px;
`
const ModalCloseIcon=styled.div`
    position : absolute;
    top : 25px;
    right : 25px;
    color : #046DE4;
    font-size : 36px; 
`
const ModalCloseBtn=styled.div`
    width : 120px;
    height : 35px;
    border : 1px solid blue;
    border-radius : 7px;
    line-height : 35px;
    text-align : center;
    color : #555;
    cursor : pointer;

`

const ModalBodyFlex=styled.div`
    box-sizing : border-box;
    display : flex;
    justify-content : space-between;
    padding : 5px;
`

const ModalBodyFlexLeft=styled.div`
    width : 25%;
`
const ModalBodyFlexRight=styled.div`
    width : 65%;
`

const TriangleRight=styled.div`
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-left: 17px solid #555;
    border-bottom: 22px solid transparent;
`
const RoleLabel=styled.div`
    height : 44px;
    width : 200px;
    background-color : #555;
    color : white;
    font-size : 20px;
    font-weight : 600px;
    line-height : 44px;
    padding-left : 20px;
`

const RoleContainer=styled.div`
    display : flex;
    flex-direction : row;
`
const role_colors = {
    "SMF": '#1B3451',
    "SMPR": '#1B3451',
    "SMOR": '#1B3451',
    "OTH": '#1B3451',
    "COCO": '#24B3AD',
    "CRR": '#F0B72E',
    "CNF": '#11BBF2',
    "ANC": '#CE6666',
    "PAST": '#8A97A6'
}


class AssignCertificationMgr extends React.Component
{
    state = {
        contacts_in_list : {},
        selected_cert_mgrs : {},
        current_user : {},
        //current_role : ""
    } 
    componentDidMount = () => {
		this.setComponentState()
	}
    setComponentState = () =>{
        let contacts = Store.getStoreData('members');
        //console.log("CON : ",contacts);
        let contacts_in_list = {};
        //console.log("PROPS USER ID",this.props.user_id);
        for(let con in contacts){
            if(contacts[con].ID !== this.props.user_id) contacts_in_list[contacts[con].ID.toString()] = {id : contacts[con].ID, name : contacts[con].ContactName};
        }
        let selected_cert_mgrs = {};
        if(Object.keys(this.props.cert_mgrs_rolewise).length===0)
            for(let arole of this.props.assigned_roles) selected_cert_mgrs[arole.role_id] = {};
        else
            selected_cert_mgrs = JSON.parse(JSON.stringify(this.props.cert_mgrs_rolewise))
        console.log("SELECTED CERT MGRS : ", selected_cert_mgrs);
        this.setState({contacts_in_list, current_user : contacts[this.props.user_id], selected_cert_mgrs});
    }
    assignCertMgr = (id, role_id = null) => {
        console.log("ID : ",id, "Role ID", role_id);
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs));
        //console.log("selected_cert_mgrs[this.current_role]",selected_cert_mgrs[this.current_role])
        selected_cert_mgrs[role_id] = {};
        selected_cert_mgrs[role_id][id.toString()] = this.state.contacts_in_list[id] ;
        console.log("selected_cert_mgrs", selected_cert_mgrs);
        this.setState({selected_cert_mgrs});
    }

    removeCertMgr = (id, role_id = null) => {
        console.log("ID : ",id, "Role ID", role_id);
        let selected_cert_mgrs = JSON.parse(JSON.stringify(this.state.selected_cert_mgrs));
        //console.log("selected_cert_mgrs[this.current_role]",selected_cert_mgrs[this.current_role])
        delete selected_cert_mgrs[role_id][id.toString()];
        console.log("selected_cert_mgrs", selected_cert_mgrs);
        this.setState({selected_cert_mgrs});
    }

    saveAssignment = (e) =>{
        e.preventDefault();
        this.props.assignCertificationMgr(this.state.selected_cert_mgrs);
    }

    closeDialog = (e) =>{
        e.preventDefault();
        this.props.closeDialog();    
    }
	render() {
        return (<Draggable>
            <ModalContainer>
                <ModalHeader>
                    <Label>Assign Certification Manager to </Label>
                    <LargeLabel>{this.state.current_user.ContactName}</LargeLabel>
                    <ModalCloseIcon onClick={this.closeDialog} style={{cursor : "pointer"}}><MdClose /></ModalCloseIcon>
                </ModalHeader>
                <ModalBody>
                    <ModalBodyFlex>
                        <ModalBodyFlexLeft><GridLabel>Role</GridLabel></ModalBodyFlexLeft>
                        <ModalBodyFlexRight><GridLabel>Assign to</GridLabel></ModalBodyFlexRight>
                    </ModalBodyFlex>
                    {
                        this.props.assigned_roles.map(item =>{
                                let bgc = role_colors[item.base];
                                console.log("Seelcted : ",item.role_id);
                                return(
                                    <ModalBodyFlex>
                                    <ModalBodyFlexLeft>
                                            <RoleContainer>
                                                <RoleLabel style={{backgroundColor : bgc}}>{item.role_code}</RoleLabel>
                                                <TriangleRight style={{borderLeftColor : bgc}}/>
                                            </RoleContainer>
                                        </ModalBodyFlexLeft>
                                        <ModalBodyFlexRight>
                                            <CSLMultiSelect 
                                                //selectedItems={{}}
                                                selectedItems={this.state.selected_cert_mgrs[item.role_id] === undefined ? {} : this.state.selected_cert_mgrs[item.role_id]} 
                                                items={this.state.contacts_in_list}
                                                selectCallback={this.assignCertMgr}
                                                deselectCallback={this.removeCertMgr}
                                                entity={item.role_id}
                                            />
                                        </ModalBodyFlexRight>
                                    </ModalBodyFlex>
                                )
                            }
                        )
                    }             
                </ModalBody>
                <ModalFooter>
                    <ModalCloseBtn onClick={this.saveAssignment}>OK</ModalCloseBtn>
                </ModalFooter>
            </ModalContainer>
        </Draggable>)
    }
}

export default AssignCertificationMgr;


// C:\Python36;C:\poppler\poppler\bin;C:\Users\Indranil\.dotnet\tools;C:\Users\Indranil\AppData\Roaming\npm