import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import Store from '../../Common/Store.js';
import OrgUtils from '../../Common/OrgUtils.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import CSLTableAlt from '../Common/CSLTableAlt.js'
import CSLLoader from '../Common/CSLLoader.js'
import CSLCheckBox from '../Common/CSLCheckBox.js'
import styled from 'styled-components';
import EditUserDialog from './EditUser';
import EditTeamDialog from '../Team/EditTeamDialog.js'
import DeleteUserDialog from './DeleteUserDialog.js'
import {FaTimes, FaPlus, FaMinus, FaLock, FaSitemap, FaPencilAlt, FaEllipsisV, FaUser, FaSearch } from 'react-icons/fa';
import AlertBox from '../Common/AlertBox.js';

const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;
class ManageUsers extends React.Component
{
    state = {
        view_count: 0,
        ready: false,
        contact_list : [],
        contact_name :'',
        jobs :{},
        search_term: '',
        current_team: 'org_level',
        team_members: null,
        show_user_menu: false,
        user_id: 0,
        show_dialog: false,
        show_user_dialog: false,
        show_user_delete_dialog: false,
        show_team_dialog: false,
        edit_team_id: '0'
    }

    constructor(props) {
    	super(props);
    }

    componentDidMount(){
        let postData = { command: "index" };
        let api = new APICall();
        api.command(postData, this.processIndexData);
    }

    componentDidUpdate(prev_props){
        if(this.props === prev_props)return;
        let postData = { command: "index" };
        let api = new APICall();
        api.command(postData, this.processIndexData);
    }
    processIndexData = (result) => {
        //console.log("result===>", result)
        let my_role = result.result.roles[result.result.contactInfo.id];
        let url_start = '/edit/user/';
        if(my_role === 'team') {
            window.location.href = url_start + result.result.contactInfo.id;
            return
        } else {
            // let
            let current_team_url = this.props.match && this.props.match.params.teamId in result.result.configs.org_teams ? this.props.match.params.teamId : '';
            let current_team = 'teamId' in this.props ? this.props.teamId : 'org_level'
            current_team = current_team_url === '' ? current_team : current_team_url
            console.log('manage_users this.props.match.params', this.props.match.params)
            console.log('manage_users result.result.configs.org_teams', result.result.configs.org_teams)
            console.log('manage_users this.props',this.props)
            console.log('manage_users current_team_url',current_team_url)
            console.log('manage_users current_team',current_team)
            Store.updateStore('contactInfo', result.result.contactInfo)
            Store.updateStore('members', result.result.members)
            let job_titles = {}
            for(let j of result.result.job_titles) {
                job_titles[j.JobID.toString()] = j.JobTitle
            }
            Store.updateStore('configs', result.result.configs)
            Store.updateStore('regulatory_status', result.result.regulatory_status)
            Store.updateStore('job_titles_list', result.result.job_titles)
            Store.updateStore('job_titles', job_titles)
            Store.updateStore('my_role', my_role)
            Store.updateStore('roles', result.result.roles)
            Store.updateStore('contacts_byid', result.result.contacts_byid)
            Store.updateStore('current_team', current_team)
            Store.updateStore('active_member_id', result.result.contactInfo.id.toString())
            let team_members = result.result.configs.org_members[current_team]
            let this_org = result.result.configs.org_chart[current_team]
            for(let uid in team_members) {
                console.log('current_team, uid orgmembership this_org', current_team, uid, OrgUtils.orgFindById(uid,this_org), this_org)
                if(OrgUtils.orgFindById(uid,this_org) === null) {
                    delete team_members[uid]
                }
            }
            console.log('team_members', team_members)
            Store.updateStore('list_roles', result.result.list_roles)
            this.setState({ready: true, contact_name:result.result.contactInfo.ContactName, contact_list: result.result.contacts, current_team: current_team,
                            jobs:job_titles, team_members: team_members, show_user_menu: false});
        }
    }

    refreshState = (cur_view) => {
        this.setState({view_count : this.state.view_count + 1});
    }
    editUser = (user_id) => {
        // window.location.href =Store.getStoreData('is_debug') ? "/edit/user/" + id  : "/select/microservice/mstop/teams/edit/user/" + id;
        this.setState({show_dialog: true, show_user_dialog: true, user_id: user_id, show_user_menu: false})

    }
    deleteUser = (user_id) => {
        // window.location.href =Store.getStoreData('is_debug') ? "/edit/user/" + id  : "/select/microservice/mstop/teams/edit/user/" + id;
        this.setState({show_dialog: true, show_user_delete_dialog: true, user_id: user_id, show_user_menu: false})

    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    setMenuShown = (show, user_id, index) => {
        this.setState({show_user_menu: show, user_id: user_id, index:index})
    }

    closeUserDialog = () => {
        this.setState({show_user_dialog: false, show_user_delete_dialog: false, show_dialog: false})
    }

    changeTeam = (team_id) => {
        let current_team = team_id
        Store.updateStore('current_team', team_id)
        let team_members = Store.getStoreData('configs').org_members[current_team]
        let this_org = Store.getStoreData('configs').org_chart[current_team]
        for(let uid in team_members) {
            console.log('current_team, uid orgmembership this_org', current_team, uid, OrgUtils.orgFindById(uid,this_org), this_org)
            if(OrgUtils.orgFindById(uid,this_org) === null) {
                delete team_members[uid]
            }
        }
        this.setState({current_team, team_members})
    }

    setOrgMenuShown = (show) => {
        this.setState({show_org_menu: true})
    }

    closeTeamDialog = () => {
        this.setState({show_team_dialog: false, edit_team_id: '0'})
    }

    editTeam = (team_id) => {
        this.setState({edit_team_id: team_id, show_team_dialog: true})
    }
    processAcceptanceQueueData = () => {
        let contactList = this.state.contact_list;
        console.log("contactList", this.state.team_members)
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Name', accessor: 'contact_name', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Email', accessor: 'email', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Active', Cell: row => (
                                <CSLCheckBox checked={row.original.status} style={{borderColor: ''}} />
                      ), minWidth: 50, headerStyle: {textAlign: 'left', visible:'false'}},
                      {'Header' : '', Cell: row => (
                        <div style={{position: 'relative'}} onMouseEnter={() => this.setMenuShown(true, row.original.id, row.viewIndex)}
                                                     onMouseLeave={() => this.setMenuShown(false, row.original.id, 0)}>
                              <FaEllipsisV style={{cursor: 'pointer'}} />
                        </div>
                      ), width: 40, headerStyle: {textAlign: 'center'}},
                     ];
                     for(let k = 0 ; k< contactList.length ; k++)
                     {
                        if((contactList[k].ID in this.state.team_members) && (contactList[k].ContactName.toLowerCase().indexOf(this.state.search_term.toLowerCase()) >= 0 ||
                                                    contactList[k].EmailAddress.toLowerCase().indexOf(this.state.search_term.toLowerCase()) >= 0 )) {
                            let elem = {'index': k,
                                                'contact_name': contactList[k].ContactName,
                                                'email' : contactList[k].EmailAddress,
                                                'status' : contactList[k].isActive,
                                                'id' : contactList[k].ID
                                               };
                                               // console.log("Testing",elem)
                            ret.data.push(elem);
                        }
                      }
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }
    captureMouseMove = (e) => {
    e.preventDefault()
    console.log('x,y', e.clientX, e.clientY)
    this.setState({ x: e.clientX, y: e.clientY });
    }

    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }


	render()
	{
		if(!this.state.ready) {
            return <CSLLoader />
        }
        if(Store.getStoreData('my_role') === 'no_access') {
            let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
               alertHandler: this.alertHandler, stack: {id: 0}};
            return <AlertBox alertParam = {alert_param}/>
        }
        let teams = Store.getStoreData('configs').org_teams
        let sorted_teams = []
        for(let id in teams) {
            let elem = teams[id]
            elem.id = id
            sorted_teams.push(elem)
        }
        sorted_teams.sort(this.nameCompare)
        let configs = Store.getStoreData('configs')
        let active_member_id = Store.getStoreData('active_member_id')
        let roles = Store.getStoreData('roles')
        let my_role = roles[active_member_id]
        let org = configs.org_chart[this.state.current_team]
        OrgUtils.measureOrg(org, null)
        OrgUtils.setManagers(org, null)
        let member = OrgUtils.orgFindById(active_member_id, org)
        let filtered_teams = my_role === 'admin_manager' ? teams : member.teams
        if(my_role !== 'admin_manager')filtered_teams.org_level = {name: 'ORGANISATION', id: 'org_level'}
        console.log('configs', configs)
        console.log('this.state.show_user_dialog', this.state.show_user_dialog)
		return (

            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                {
                    (() => {
                        if(this.state.show_user_dialog) {
                            return (
                                <div>
                                    <Style.InactiveOverlay  />
                                    <EditUserDialog style={{ position: 'absolute', top: '-60px'}}
                                                        userId={this.state.user_id}
                                                        closeUserDialog={this.closeUserDialog}
                                                        orgMembers={configs.org_members.org_level}
                                                        teamMembers={configs.org_members[this.state.current_team]}
                                                        org={configs.org_chart[this.state.current_team]}
                                                    />
                                </div>
                                )
                        }
                        if(this.state.show_user_delete_dialog) {
                            return (
                                <div>
                                    <DeleteUserDialog style={{ position: 'absolute', top: '-60px'}}
                                                        userId={this.state.user_id}
                                                        closeUserDialog={this.closeUserDialog}
                                                        orgMembers={configs.org_members.org_level}
                                                        teamMembers={configs.org_members[this.state.current_team]}
                                                        org={configs.org_chart[this.state.current_team]}
                                                        currentTeam={this.state.current_team}
                                                    />
                                </div>
                                )
                        }
                        if(this.state.show_team_dialog) {
                            return (
                                <div>
                                    <Style.InactiveOverlay  />
                                    <EditTeamDialog
                                        teamId={this.state.edit_team_id}
                                        closeTeamDialog={this.closeTeamDialog}
                                        source='users'
                                    />
                                </div>
                                )
                        }
                    })()
                }

                <DefaultHeader />
                <WelcomeBanner welcome={this.state.contact_name}/>
                              {
                                (() => {
                                    if(this.state.show_user_menu) {
                                        // console.log('this.state.x, this.state.y', this.state.x, this.state.y)
                                        return (<Style.MemberMenu onMouseEnter={() => this.setMenuShown(true, this.state.user_id, this.state.index)}
                                                     onMouseLeave={() => this.setMenuShown(false, this.state.user_id, 0)} style={{backgroundColor: '#FEEBF4',width:140,
                                                     top: `${320 + (this.state.index*46)}px`, left: 'calc(100vw - 570px)', zIndex:'4000'}}>
                                                    <div>
                                                        <Style.MemberMenuLine style={{fontSize: '16px', fontWeight: '700'}}
                                                            onClick={() => this.editUser(this.state.user_id)}>
                                                            Edit Details
                                                        </Style.MemberMenuLine>
                                                        {
                                                            (() => {
                                                                if(this.state.current_team !== 'org_level') {
                                                                    return <Style.MemberMenuLine
                                                                            style={{fontSize: '16px', fontWeight: '700'}}
                                                                            onClick={() => this.deleteUser(this.state.user_id)}
                                                                            >Delete User</Style.MemberMenuLine>
                                                                }
                                                            })()
                                                        }

                                                    </div>
                                                </Style.MemberMenu>)
                                    }
                                })()
                              }
                    <Style.OrgMenu style={{overFlow: 'none', backgroundColor: 'transparent', padding: '15px', borderBottom: '1px solid #E5E8EC'}}>
                    <Style.SubmitButton style={{fontSize: 14, fontWeight: 700, width: 150}}
                                        onClick={() => this.editTeam('0')}>Add New Team</Style.SubmitButton>
                    {
                        (() => {
                            if(this.state.current_team === 'org_level')return <Style.SubmitButton style={{backgroundColor: 'white', color: 'blue', fontSize: 14, fontWeight: 700, width: 150, marginLeft: 10}}
                                        onClick={() => this.editUser('0')}>Create New User</Style.SubmitButton>
                        })()
                    }

                    <Style.OrgMenuMoreButton><FaEllipsisV /></Style.OrgMenuMoreButton>
                </Style.OrgMenu>
                <div style={{padding:"0px 0px"}}>
                    <div style={{width: '300px', float: 'left', height: '100%', marginTop: 12}}>
                        <div style={{backgroundColor: '#E4F0FF', color: 'blue', marginLeft: '20px',
                                height: '25px', padding: '15px 35px', borderLeft: '5px solid blue', fontSize: '16px', fontWeight: 700,
                                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                title={this.state.current_team === 'org_level' ? 'All Users' : teams[this.state.current_team].name}>
                            {this.state.current_team === 'org_level' ? 'All Users' : teams[this.state.current_team].name}
                        </div>
                        <Style.SpacerXL style={{height: 30}} />
                        <Style.MemberBoxHr style={{marginLeft: '20px', marginRight: '30px', width: 280, borderColor: '#E4D0FF', borderWidth: 2}} />
                        <Style.SpacerXL /><Style.SpacerXL />
                        {
                            (() => {
                                if(this.state.current_team !== 'org_level') {
                                    return (<div style={{width: 'calc(100% - 30px)', height: 50,
                                            cursor: 'pointer', paddingLeft: 60,
                                            fontSize: 16, fontWeight: 600, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                            title='All Users'
                                            onClick={() => this.changeTeam('org_level')}>All Users</div>)
                                }
                            })()
                        }
                        {
                            // Object.keys(filtered_teams).map((id) => {
                            //     if(id !== this.state.current_team) {
                            //         return (<div key={id} style={{width: 'calc(100% - 30px)', height: 50,
                            //                 cursor: 'pointer', paddingLeft: 60,
                            //                 fontSize: 16, fontWeight: 600, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                            //                 title={id === 'org_level' ? 'All Users' : teams[id].name}
                            //                 onClick={() => this.changeTeam(id)}>{id === 'org_level' ? 'All Users' : teams[id].name}</div>)
                            //     }
                            // })
                            sorted_teams.map((team, i) => {
                                if(team.id !== this.state.current_team && team.id !== 'org_level') {
                                    return (<div key={i} style={{width: 'calc(100% - 30px)', height: 50,
                                            cursor: 'pointer', paddingLeft: 60,
                                            fontSize: 16, fontWeight: 600, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                            title={team.id === 'org_level' ? 'All Users' : team.name}
                                            onClick={() => this.changeTeam(team.id)}>{team.id === 'org_level' ? 'All Users' : team.name}</div>)
                                }
                            })
                        }
                        <div style={{width: '100%', height: 50,
                                            cursor: 'pointer', paddingLeft: 60,
                                            fontSize: 16, fontWeight: 600}} title='New Team'
                                            onClick={() => this.editTeam('0')}>Add New Team</div>
                    </div>
                    <div style={{width: 'calc(100% - 400px)', float: 'right', padding: '10px 10px 10px 50px', borderLeft: '2px solid #E4D0FF'}}>
                        <div style={{width: 'calc(100% - 110px)', backgroundColor: 'white', border: '2px solid #F0EFF1', borderRadius: 10, height: 35, padding: '10px 15px'}}>
                            <FaSearch style={{fontSize:32, color: '#F0EFF1', float: 'left'}} />
                            <Style.SearchInput placeholder='Search...' type="text" name="search_term" onChange={this.handleChange} value={this.state.search_term} style={{border: '0px'}} />
                        </div>
                        <CSLTableAlt add={false} processData={this.processAcceptanceQueueData} headerText={null} tableRows={20} tableWidth='calc(100% - 80px)' />
                    </div>

                    {
                        (() =>{
                            if(this.state.add_user_dialog) {
                            return (
                                <div style={{position:"relative",top:"-1000px"}}>
                                    <Style.InactiveOverlay  />
                                    <EditUserDialog userId={this.state.edit_user_id} closeUserDialog={this.closeUserDialog} />
                                </div>
                                )
                            }
                        })()
                    }
                </div>
            </div>

		);
	}
}

export default ManageUsers;
