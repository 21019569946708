import React from 'react';
import * as Style from './Common/StyledComponents';
import APICall from '../Common/APICall.js';
import Store from '../Common/Store.js';

import {IoIosArrowForward, IoIosLock} from 'react-icons/io';

class LeftMenuPanel extends React.Component {

	render() {

		return <div></div>
		let my_role = this.props.myRole;

		console.log('my_role', my_role)
		console.log('this.props.activeSectionId', this.props.activeSectionId)
		return (
			<div>
                        <Style.TeamsTab style={{marginTop: "2px"}}>
                            {
                                (() => {
                                	if(my_role !== 'team') {
	                                    if (this.props.activeSectionId === "org_chart") {
	                                        return (
	                                        	<div>
	                                        		<div style={{float: "left", fontWeight: "600", color: "#39b3ad" }}>Org Chart</div>
						                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
						                            <div style={{clear: "both"}}></div>
	                                        	</div>
	                                        	);
	                                    } else {
	                                        return (
	                                        	<div>
	                                        		<div style={{float: "left"}}>Org Chart</div>
	                                        		<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
						                            <div style={{clear: "both"}}></div>
	                                        	</div>
	                                        	);
	                                    }                                		
                                	}
                                })()
                            }
                        </Style.TeamsTab>
                        <Style.TeamsTab>
                            {
                                (() => {
                                    if (this.props.activeSectionId === "general") {
                                        return (<div style={{float: "left", fontWeight: "600", color: "#39b3ad"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left", color: "#39b3ad" }}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </Style.TeamsTab>

			</div>
			)
	}

}

export default LeftMenuPanel;