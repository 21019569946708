import React from 'react';
import * as Style from '../Common/StyledComponents';
import APICall from '../../Common/APICall.js';
import EventBus from '../../Common/EventBus.js';

import Store from '../../Common/Store.js';
import OrgUtils from '../../Common/OrgUtils.js';
import WelcomeBanner from '../Common/WelcomeBanner';
import DefaultHeader from '../Common/DefaultHeader';
import CSLTableAlt from '../Common/CSLTableAlt.js'
import CSLLoader from '../Common/CSLLoader.js'
import CSLCheckBox from '../Common/CSLCheckBox.js'
import styled from 'styled-components';
import EditUserDialog from './EditUser';
import EditTeamDialog from '../Team/EditTeamDialog.js'
import DeleteUserDialog from './DeleteUserDialog.js'
import {FaTimes, FaPlus, FaMinus, FaLock, FaSitemap, FaPencilAlt, FaEllipsisV, FaUser, FaSearch } from 'react-icons/fa';
import AlertBox from '../Common/AlertBox.js';
import ComDecom from '../../Common/ComDecom.js';

const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const Fade = styled.div`
    position: fixed;
    top: 18px;
    left: calc(50vw - 250px);
    width: 500px;
    font-size: 17px;
    padding-top: 7px;
    font-weight: 600;
    background-color: cyan;
    color: blue;
    text-align: center;
    height: 28px;
    border-radius: 10px;
`


class UserMenu extends React.Component {
    render() {
        let my_teams = Store.getStoreData('my_teams')
        console.log('UserMenu my_teams', my_teams)
        let manager_id = my_teams[this.props.current_team].manager_id.toString()
        let user_id = this.props.user_id.toString()
        return (<div>
            <Style.MemberMenu onMouseEnter={() => this.props.setMenuShown(true, this.props.user_id, this.props.index)}
                 onMouseLeave={() => this.props.setMenuShown(false, this.props.user_id, 0)} style={{backgroundColor: '#FEEBF4',width:140,
                 top: `${220 + (this.props.index*46)}px`, left: 'calc(100vw - 290px)', zIndex:'4000'}}>
                <div>
                    <Style.MemberMenuLine style={{fontSize: '16px', fontWeight: '700'}}
                        onClick={() => this.props.editUser(this.props.user_id)}>
                        Edit Details
                    </Style.MemberMenuLine>
                    {
                        (() => {
                            if(this.props.current_team !== 'org_level' && manager_id !== user_id) {
                                return <Style.MemberMenuLine
                                        style={{fontSize: '16px', fontWeight: '700'}}
                                        onClick={() => this.props.removeUser(this.props.user_id, this.props.current_team)}
                                        >Delete User</Style.MemberMenuLine>
                            }
                        })()
                    }

                </div>
            </Style.MemberMenu>
        </div>)
    }
}

class ManageUsers extends React.Component
{
    state = {
        ready: false,
        contacts : null,
        teams: null,
        org_members: null,
        org_members_assoc: null,
        jobs :{},
        role: 'no_access',
        search_term: '',
        current_team: 'org_level',
        team_members: null,
        show_user_menu: false,
        user_id: 0,
        view_index: 0,
        edit_user_id: 0,
        edit_team_id: '0',
        show_dialog: null,
        show_user_dialog: false,
        show_user_delete_dialog: false,
        show_team_dialog: false,
        alert_param: null,
        fade_scale: 0,
        fade_message: 'User activated/deactivated'
    }

    setComponentState = () => {
        this.setState({current_team: this.props.teamId})
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }
    componentDidMount = () => {
        this.setComponentState()
    }

    captureMouseMove = (e) => {
        e.preventDefault()
        console.log('x,y', e.clientX, e.clientY)
        this.setState({ x: e.clientX, y: e.clientY });
    }

    componentDidMount = () => {
        EventBus.registerEvent('user_deleted', 'ManageUsers', this.userDeleted)
        this.setState({ready: true})
    }

    userDeleted = (team_id) => {
        this.setState({current_tem: team_id})
    }

    reduceFade = () => {
        let fade_scale = this.state.fade_scale - 0.05
        console.log('Status clicked', fade_scale)
        this.setState({fade_scale})
        if(fade_scale > 0) setTimeout(this.reduceFade, 10)
    }

    processStatusChange = (result) => {
        let fade_message = 'ERROR: Operation failed. Please try after some time.'
        let fade_scale = 0.8
        let members = Store.getStoreData('members')
        console.log('Status clicked members, result', members, result)
        if(result.error_code === 0) {
            let user_id = result.result.user_id.toString()
            let name = members[user_id].ContactName
            let active = result.result.is_active === 1 ? 'activated' : 'deactivated'
            fade_message = `User ${name} has been successfully ${active}`
            members[user_id].isActive = result.result.is_active === 1 ? true : false
            Store.updateStore('members', members)
            this.setState({fade_message, fade_scale})
            console.log('Status clicked fade_message, fade_scale', fade_message, fade_scale)
            setTimeout(this.reduceFade, 1000)
        } else {
            this.setState({fade_message, fade_scale})
            setTimeout(this.reduceFade, 1000)
        }

    }

    closeAlert = () => {
        this.setState({alert_param: null})
    }

    changeStatus = (checked, user_id) => {
        let team_managers = Store.getStoreData('team_managers')
        let roles = Store.getStoreData('roles')
        let user_role = roles[user_id.toString()]
        if(!checked && user_id in team_managers) {
            let alert_param = {title: 'ERROR', message: 'A current team manager cannot be deactivated.', ok_text: 'OK',
                           alertHandler: this.closeAlert}
            this.setState({alert_param})
            return
        }
        if(!checked && user_role === 'admin_manager') {
            let alert_param = {title: 'ERROR', message: 'Teams admin cannot be deactivated.', ok_text: 'OK',
                           alertHandler: this.closeAlert}
            this.setState({alert_param})
            return
        }
        let postData = {command: 'set_active', user_id: user_id, is_active: checked ? 1 : 0}
        let api = new APICall()
        api.command(postData, this.processStatusChange)
    }

    nameCompare = (a,b) => {
        return a.name.localeCompare(b.name)
    }

    changeTeam = (current_team) => {
        this.setState({current_team})
    }

    setMenuShown = (show_user_menu, user_id, view_index) => {
        this.setState({show_user_menu, user_id, view_index})
    }

    closeDialog = (refresh = false) => {
        console.log('EditTeamDialog refresh, this.state',  refresh, this.state)
        if(!refresh)this.setState({edit_user_id: 0, edit_team_id: '0', show_dialog: null, new_members: []})
        else this.refreshTeam(true, this.state.team_id)
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    getTableData = () => {
        let ret = {data: [], columns: []};
        let members = Store.getStoreData('org_members')[this.state.current_team]
        let my_teams = Store.getStoreData('my_teams')
        console.log('my_teams', my_teams)
        let manager_id = this.state.current_team === 'org_level' ? '' : Store.getStoreData('my_teams')[this.state.current_team].manager_id.toString()
        let contacts = Store.getStoreData('members')
        console.log('getTableData contacts, members', contacts, members)
        ret.columns =[{'Header' : 'Name', Cell: row => (
                        <div>

                              {
                                //   (() => {
                                //      if(this.state.show_fade) return <Fade>User activated.</Fade>
                                //   })()
                              }
                              {
                                  (() => {
                                    console.log('row', row)
                                     if(row.original.id.toString() === manager_id) return <span style={{fontWeight: 700, fontSize: 20}}>{row.original.contact_name}</span>
                                     else return <>{row.original.contact_name}</>
                                  })()
                              }
                        </div>
                      ), width: 220, headerStyle: {textAlign: 'left'}},
                      {'Header' : 'Email', Cell: row => (
                        <div>
                              {
                                  (() => {
                                    console.log('row', row)
                                     if(row.original.id.toString() === manager_id) return <span style={{fontWeight: 700, fontSize: 20}}>{row.original.email}</span>
                                     else return <>{row.original.email}</>
                                  })()
                              }
                        </div>
                      ), minWidth: 120, headerStyle: {textAlign: 'left'}},
                      {Header: 'Active', Cell: row => (
                                <CSLCheckBox checkCallback={this.changeStatus} stack={row.original.id} checked={row.original.status} style={{borderColor: ''}} />
                      ), minWidth: 50, headerStyle: {textAlign: 'left', visible:'false'}},
                      {'Header' : '', Cell: row => (
                        <div style={{position: 'relative'}} onMouseEnter={() => this.setMenuShown(true, row.original.id, row.viewIndex)}
                                                     onMouseLeave={() => this.setMenuShown(false, row.original.id, 0)}>
                              <FaEllipsisV style={{cursor: 'pointer'}} />
                        </div>
                      ), width: 40, headerStyle: {textAlign: 'center'}},
                     ];
        if(this.state.current_team !== 'org_level') ret.columns.splice(2,1)
        // let members = this.state.org_members_assoc[this.state.current_team]
        let index = 0
        for(let mm of members) {
            let m = contacts[mm.id]
            if(m === undefined) {
                continue
            }
            if((m.ContactName.toLowerCase().indexOf(this.state.search_term.toLowerCase()) >= 0 || m.EmailAddress.toLowerCase().indexOf(this.state.search_term.toLowerCase()) >= 0 )) {
                ret.data.push({'index': index,
                                'contact_name': m.isActive === false ? m.ContactName+' (Inactive)': m.ContactName,
                                'email' : m.EmailAddress,
                                'status' : m.isActive,
                                'id' : m.ID})
                index++
            }
        }

        return ret
    }

	render()
	{
		if(!this.state.ready) {
            return <CSLLoader />
        }

        let teams = Store.getStoreData('my_teams')
        let sorted_teams = []
        for(let id in teams) {
            let elem = teams[id]
            elem.id = id
            sorted_teams.push(elem)
        }
        sorted_teams.sort(this.nameCompare)
        console.log('ManageUsers DEL this.state, this.props', this.state, this.props)
		return (

            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <Fade style={{opacity: this.state.fade_scale}}>{this.state.fade_message}</Fade>
                    <Style.OrgMenu style={{overFlow: 'none', backgroundColor: 'transparent', padding: '15px', borderBottom: '1px solid #E5E8EC'}}>
                    <Style.SubmitButton style={{fontSize: 14, fontWeight: 700, width: 150}}
                                        onClick={() => this.props.editTeam('0')}>Add New Team</Style.SubmitButton>
                    <Style.OrgMenuMoreButton><FaEllipsisV /></Style.OrgMenuMoreButton>
                </Style.OrgMenu>
                <div style={{padding:"0px 0px"}}>
                    <div style={{width: '300px', float: 'left', height: '100%', marginTop: 12}}>
                        <div style={{backgroundColor: '#E4F0FF', color: 'blue', marginLeft: '20px',
                                height: '25px', padding: '15px 35px', borderLeft: '5px solid blue', fontSize: '16px', fontWeight: 700,
                                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                title={this.state.current_team === 'org_level' ? 'All Users' : teams[this.state.current_team].name}>
                            {this.state.current_team === 'org_level' ? 'All Users' : teams[this.state.current_team].name}
                        </div>
                        <Style.SpacerXL style={{height: 30}} />
                        <Style.MemberBoxHr style={{marginLeft: '20px', marginRight: '30px', width: 280, borderColor: '#E4D0FF', borderWidth: 2}} />
                        <Style.SpacerXL /><Style.SpacerXL />
                        {
                            (() => {
                                if(this.state.current_team !== 'org_level') {
                                    return (<div style={{width: 'calc(100% - 30px)', height: 50,
                                            cursor: 'pointer', paddingLeft: 60,
                                            fontSize: 16, fontWeight: 600, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                            title='All Users'
                                            onClick={() => this.changeTeam('org_level')}>All Users</div>)
                                }
                            })()
                        }
                        {
                            sorted_teams.map((team, i) => {
                                if(team.id !== this.state.current_team && team.id !== 'org_level') {
                                    return (<div key={i} style={{width: 'calc(100% - 30px)', height: 50,
                                            cursor: 'pointer', paddingLeft: 60,
                                            fontSize: 16, fontWeight: 600, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                            title={team.id === 'org_level' ? 'All Users' : team.name}
                                            onClick={() => this.changeTeam(team.id)}>{team.id === 'org_level' ? 'All Users' : team.name}</div>)
                                }
                            })
                        }
                        <div style={{width: '100%', height: 50,
                                            cursor: 'pointer', paddingLeft: 60, marginTop: 30,
                                            fontSize: 20, fontWeight: 700}} title='New Team'
                                            onClick={() => this.props.editTeam('0')}>Add New Team</div>
                    </div>
                    <div style={{width: 'calc(100% - 400px)', float: 'right', padding: '10px 10px 30px 50px', borderLeft: '2px solid #E4D0FF'}}>
                        <div style={{width: 'calc(100% - 110px)', backgroundColor: 'white', border: '2px solid #F0EFF1', borderRadius: 10, height: 35, padding: '10px 15px'}}>
                            <FaSearch style={{fontSize:32, color: '#F0EFF1', float: 'left'}} />
                            <Style.SearchInput placeholder='Search...' type="text" name="search_term" onChange={this.handleChange} value={this.state.search_term} style={{border: '0px'}} />
                        </div>
                        <CSLTableAlt add={false} processData={this.getTableData} headerText={null} tableRows={20} tableWidth='calc(100% - 80px)' />
                    </div>
                </div>
                {
                    (() => {
                       if(this.state.show_user_menu) return <UserMenu
                                                                user_id={this.state.user_id}
                                                                current_team={this.state.current_team}
                                                                editUser={this.props.editUser}
                                                                setMenuShown={this.setMenuShown}
                                                                removeUser={this.props.removeUser}
                                                                index={this.state.view_index}
                                                            />
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
            </div>

		);
	}
}

export default ManageUsers;
