import React from 'react';

class DataLoader extends React.Component
{
    state = {view_count: 0 };

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.props.dataLoader()
    }

    render()
    {
        return <div>Loading...</div>
    }
}
export default DataLoader;