import React from 'react';
import moment from 'moment';
import Store from '../Common/Store.js';
import EventBus from '../Common/EventBus.js';
import ComDecom from '../Common/ComDecom.js';
import Utils from '../Common/Utils.js';
import OrgUtils from '../Common/OrgUtils.js';
import OrgMember from './OrgMember.js'
import APICall from '../Common/APICall.js';
import {FaTimes, FaPlus, FaSearch, FaMinus, FaLock, FaSitemap, FaPencilAlt, FaEllipsisV, FaUser} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import * as Style from './Common/StyledComponents';
import LeftMenuPanel from './LeftMenuPanel.js'
import EditTeamDialog from './Team/EditTeamDialog.js'
import EditUserDialog from './User/EditUser'
import AlertBox from './Common/AlertBox.js'
import Fade from './Common/Fade.js'
import CSLLoader from './Common/CSLLoader.js'
import ManageUsers from './User/ManageUsers'
import ManageTeams from './Team/ManageTeams';
import cookie from 'react-cookies'
import styled from 'styled-components'
import PdfGenerator from './Reporting/PdfGenerator.js';



const x_offset = 120;
const y_offset = 10;
const box_height = 100;
const line_height = 18;
const vert_gap = 50;
const line_len = 50;
const editor_top = 20;


const OrgContainer = styled.div`
    width: calc(100% + 5px);
    height: calc(100vh - 215px);
    overflow-x: hidden;
    overflow-y: auto;
    background: #F9FAFB;
    margin-top: 2px;
    position: relative;
    padding-bottom: 10px;
`
const MembersContainer = styled.div`
    position: relative;
    width: calc(100% - 1px);
    background: #F9FAFB;
    margin-top: 2px;
    // margin-bottom: 20px;
    
`
const MenuContainer = styled.div`
    margin-top: 2px;
    padding-top: 10px;
    margin-left: 10px;
    width: calc(100% - 5px);
    white-space: nowrap;
    overflow: hidden;
    background: #F9FAFB;
    
`;

const MemberMenuLineSpacer = styled.div`
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F5DADA;
    margin-bottom: 5px;
    margin-top: 3px;
`;

const UserSelect = styled.select`
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    width: calc(94% - 30px);
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
    font-family:montserrat ;
    font-size:14px;
`;

const MenuInstructionContainer = styled.div`
    width: 431px;
    font-size: 11px;
    color: rgb(160, 161, 162);
    float: right;
    text-align: center;
    padding-top: 5px;
    margin-right: 3px;
    font-weight: 700;
`

const MenuItemsContainer = styled.div`
    padding-left: 20px;
    width: calc(100% - 270px);
    white-space: nowrap;
    overflow-x: hidden;
    float: left;
    display: flex;
`;

const MenuSearchboxContainer = styled.div`
    padding-left: 20px;
    width: 200px;
    float: left;
    display: flex;
    padding: 2px;
    border: 1px solid;
    position: relative;
    border: 1px solid;
    border-radius: 10px;
`;

const MenuSearchbox = styled.input`
    border: none;
    width: 150px;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
`

const MenuSearchButton = styled.div`
    position: absolute;
    top: 5px;
    right: 0px;
    width: 30px;
    font-size: 22px;
    border: 1px solid transparent;
    border-radius: inherit;
    cursor: pointer;
    opacity: 0.7;
`

const MenuEllipsisContainer = styled.div`
    width: 30px;
    height: 30px;
    float: right;
    font-size: 18px;
    cursor: pointer;
`;

const ClearFloat = styled.div`
    clear: both;
`

const ActiveMenuItem = styled.div`
    color: rgb(4, 109, 228);
    text-transform: uppercase;
    max-width: 250px;
    min-width: 100px;
    font-weight: 700;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 18px;
    display: inline-block;
    border-left: 10px solid rgb(245, 0, 137);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const MenuItem = styled.div`
    color: rgb(55, 56, 56);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 18px;
    display: inline-block;
    cursor: pointer;
    max-width: 250px;
    min-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
        color: rgb(55, 56, 186);
    }
`
const OrgMenu = styled.div`
    position: absolute;
    background-color: #FEEBF4;
    font-size: 16px;
    top: 10px;
    left: 415px;
    color: #F5239A;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 4px;
    width: 200px;
    z-index: 4000;
`;

const OrgMenuLine = styled.div`
    width: calc(100% - 20px);
    padding-left: 15px;
    padding-top: 1px;
    padding-bottom: 6px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;

`;

class ResetPassDialog extends React.Component {
    render() {
        return (<div></div>)
    }
}

class TeamsMenu extends React.Component {
    state = {show_menu: false, user_id: '0', teams: null}

    getTeams = () => {
        let all_teams = Store.getStoreData('teams')
        let contact_info = Store.getStoreData('contactInfo')
        let my_role = Store.getStoreData('my_role')
        let teams = {}
        if(my_role === 'admin_manager')teams = all_teams
        else {
            for(let id in all_teams) {
                if(contact_info.id.toString() === all_teams[id].manager_id.toString() || id === 'org_level') teams[id] = all_teams[id]
            }
        }
        return teams
    }

    componentDidMount = () => {
        let teams = this.getTeams()
        EventBus.registerEvent('teams_changed', 'TeamsMenu', this.refreshMenu)
        this.setState({teams})
    }

    refreshMenu = () => {
        let teams = this.getTeams()
        this.setState({teams})
    }

    showMenu = () => {
        EventBus.raiseEvent('show_fade', null)
        this.setState({show_menu: true})
    }
    hideMenu = () => {
        this.setState({show_menu: false, page_size: 50})
    }

    searchUser = (e) => {
        e.preventDefault()
        let user_id = e.target.value
        // console.log('user', user_id)
        this.props.searchUser(user_id)
        this.setState({user_id})
    }

    searchUserDirect = (e) => {
        e.preventDefault()
        if(this.state.user_id !== '0')this.props.searchUser(this.state.user_id)
    }
    render() {
        if(this.state.teams === null) return <div />
        let my_role = Store.getStoreData('my_role')
        let contact_info = Store.getStoreData('contactInfo')
        let teams = this.state.teams
        // teams = Store.getStoreData('teams')

        let contacts = Store.getStoreData('members')
        // console.log('teamsMenu contact_info, my_role, teams', contacts)

        let start_at = this.props.startAt
        let page_size = this.props.pageSize
        let sheet_size = this.props.cards.length
        let show_next = start_at < sheet_size - page_size + 10 ? true : false
        let show_prev = start_at > 0 ? true : false
        if(sheet_size < 100) {
            show_next = false
            show_prev = false
        }

        return (<div>
        {
            (() => {
               if(this.state.show_menu) {
                   return (
                    <OrgMenu style={{left: 'calc(100vw - 225px)', top: 110}} onMouseEnter={this.showMenu} onMouseLeave={this.hideMenu}>

                        {
                            (() => {
                                if(sheet_size > 100) {
                                    if(page_size < 1000) return <OrgMenuLine onClick={() => this.props.move('all')} style={{fontSize: '16px', fontWeight: '700'}}>Show all users </OrgMenuLine>
                                    else return <OrgMenuLine onClick={() => this.props.move('some')} style={{fontSize: '16px', fontWeight: '700'}}>Show {this.state.page_size} users </OrgMenuLine>
                                } else if(start_at > 0) return <OrgMenuLine onClick={() => this.props.move('all')} style={{fontSize: '16px', fontWeight: '700'}}>Show all users </OrgMenuLine>
                                else return <div />
                            })()
                        }
                        {
                            (() => {
                                if(show_next) return (<OrgMenuLine onClick={() => this.props.move('down')} style={{fontSize: '16px', fontWeight: '700'}}>Show next {page_size} users </OrgMenuLine>)
                            })()
                        }
                        {
                            (() => {
                                if(show_prev) return (<OrgMenuLine onClick={() => this.props.move('up')} style={{fontSize: '16px', fontWeight: '700'}}>Show previous {page_size} users </OrgMenuLine>)
                            })()
                        }
                        <MemberMenuLineSpacer />
                        {
                            (() => {
                                        if(my_role === 'admin_manager') {
                                            return (
                                                <div>
                                                    <OrgMenuLine
                                                        style={{fontSize: '16px', fontWeight: '700'}} onClick={() => this.props.editTeam('0')}>Add Team</OrgMenuLine>
                                                    <MemberMenuLineSpacer />
                                                </div>
                                                )
                                        }
                                    })()
                        }
                        {
                            Object.keys(teams).map((id, i) => {
                                if(true)return (<OrgMenuLine key={i} onClick={() => this.props.changeTeam(id)}>{teams[id].name}</OrgMenuLine>)
                                else return (<div key={i} />)
                            })
                        }
                        <Style.MemberMenuLine />
                        <Style.MemberMenuLineSpacer />
                        {/*<Style.MemberMenuLine onClick={() => this.props.togglePdf()} style={{fontSize: '16px', fontWeight: '700'}}>Print</Style.MemberMenuLine>*/}
                    </OrgMenu>
                   )
               }
            })()
        }
        <MenuContainer>
            <MenuItemsContainer>
            <ActiveMenuItem title={teams[this.props.activeTeam].name}>{teams[this.props.activeTeam].name}</ActiveMenuItem>
            {
                Object.keys(teams).map((id, i) => {
                    if(id !== this.props.activeTeam) return (<MenuItem key={i} title={teams[id].name} onClick={() => this.props.changeTeam(id)}>{teams[id].name}</MenuItem>)
                    else return (<div key={i} />)
                })
            }
            </MenuItemsContainer>
            <MenuSearchboxContainer>
                <UserSelect onChange={this.searchUser} value={this.state.user_id}>
                {/*<option value='0'>Select member...</option>
                {
                    this.props.cards.map((c, i) => {
                        // // console.log('c', contacts[c.id.toString()])
                        return <option key={i} value={c.id.toString()}>{contacts[c.id.toString()].ContactName}</option>
                    })
                }*/}
                {
                    (() => {
                        let x = [];
                        x.push(<option key='unique' value='0'>Select member...</option>)
                        let cts = [];
                        for(let k in this.props.cards){
                            cts.push({id: this.props.cards[k].id, name: contacts[this.props.cards[k].id.toString()].ContactName})
                        }
                        cts.sort(function(a,b) {return (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0);} );
                        for(let c in cts){
                            x.push(<option key={c} value={cts[c].id.toString()}>{cts[c].name}</option>)
                        }
                        return x

                    })()
                }
                </UserSelect>
                <MenuSearchButton onClick={this.searchUserDirect}><FaSearch /></MenuSearchButton>
            </MenuSearchboxContainer>
            <MenuEllipsisContainer onMouseEnter={this.showMenu} onMouseLeave={this.hideMenu}><FaEllipsisV /></MenuEllipsisContainer>

            <ClearFloat />
        </MenuContainer>
        <MenuContainer>
            <MenuEllipsisContainer onClick={this.props.zoomIn} style={{fontSize: 14, marginTop: 5, color: 'blue'}}><FaPlus /></MenuEllipsisContainer>
            <MenuEllipsisContainer onClick={this.props.zoomOut} style={{fontSize: 14, marginRight: 10, marginTop: 5, color: 'blue'}}><FaMinus /></MenuEllipsisContainer>
            <MenuInstructionContainer style={{marginRight: 14}}>Use Control-F to search for specific employees and the +/- to zoom in and out.</MenuInstructionContainer>
        </MenuContainer>
        </div>)
    }
}

class OrgEditor extends React.Component
{
    state = {org: null,
         team_id: 'org_level',
         role: 'team',
         alt_managers: null,
         alt_reporters: null,
         member_cards: null,
         member_teams: null,
         edit_user_id: 0,
         show_dialog: null,
         edit_team_id: '0',
         new_members: [],
         start_at: 0,
         page_size: 50,
         search_user_id: 0,
         alert_param: null,
         zoom:1,
         cur_view: 'org_editor',
         reset_user_id: 0,
         fade_params: null,
         generate_report: false,
         isLoadedIcon: false,
         empty_members: {}
    }

    member_top = 15
    editorDiv = React.createRef();



    setComponentState = () => {
        let org_state = Store.getStoreData('org_state')
        // // console.log('org_state', org_state)
        if(org_state !== null) {
            this.setupEditorObjects(this.state.team_id)
            return
        }
        EventBus.registerEvent('teams_changed_editor', 'OrgEditor', this.teamsChanged)
        let post_data = {command: 'get_team', team_id: this.state.team_id}
        let api = new APICall();
        api.command(post_data, this.getTeam);
    }

    teamsChanged = (team_id) => {
        Store.updateStore('close_dialogs', 0)
        this.refreshTeam(true, team_id)
    }

    refreshTeam = (res, team_id) => {
        // console.log(team_id)
        console.log('ManageUsers DEL refreshTeam team_id', team_id)
        let post_data = {command: 'get_team', team_id: team_id}
        let api = new APICall();
        this.setState({alert_param: null})
        api.command(post_data, this.getTeam);
    }

    setupEditorObjects = (team_id) => {
        let org_state = Store.getStoreData('org_state')
        let contacts = Store.getStoreData('members')
        let member_cards = []

        let org = org_state.org
        let role = org_state.role
        let alt_managers = org_state.alt_managers
        let alt_reporters = org_state.alt_reporters
        let member_teams = org_state.member_teams
        // // console.log('setupEditorObjects alt_managers', alt_managers)
        let start = moment().unix()
        this.generateMemberCards(org_state.org, team_id, contacts, member_teams, alt_managers, member_cards)
        // let end = moment().unix()
        console.log('member_cards', member_cards)
        this.setState({org, team_id, role, member_cards, start_at: 0})
    }

	generateMemberCards = (org, team_id, contacts, member_teams, alt_managers, cards) => {
	   // console.log('generateMemberCards org', org)
	    this.member_top = editor_top

        console.log(org, team_id, contacts, member_teams)

	    for(let m of org[team_id].members) {
	        this.processMember(team_id, m, cards, 0, 0, contacts, member_teams, alt_managers)
	    }
	    console.log('generateMemberCards org', org)
	}

	sortMembers = (member) => {
	    member.members.sort(function(a,b) {
	        return b.score - a.score
	    })
	    for(let m of member.members) {
	        this.sortMembers(m)
	    }
	}

	sortMembersAlpha = (member, contacts) => {
	    member.members.sort(function(a,b) {
	        return contacts[a.id].ContactName.localeCompare(contacts[b.id].ContactName)
	    })
	    for(let m of member.members) {
	        this.sortMembersAlpha(m, contacts)
	    }
	}

	scoreMembers = (member, contacts) => {
	    console.log('scoreMembers m, contacts', member, contacts)
        if(member.id !== '0' && !contacts[member.id].isActive) {
           member.score = -1
           return 0
        }
	    member.score = 0
	    for(let m of member.members) {
	        member.score += this.scoreMembers(m, contacts)
	    }
	    return member.score + 1
	}

    convertListToTree = (list, contacts) => {
        let members = this.processConvertListToTree(list, contacts)

        this.scoreMembers(members, contacts)
        this.sortMembersAlpha(members, contacts)
        this.sortMembers(members)
        // console.log('scoreMembers members', members)

        return members
    }
    processConvertListToTree = (list, contacts) => {
		var companyIDs = {};
		list.forEach(function(x) {
			companyIDs[x['user_id'].toString()] = 1;
		});
	    var treeList = [];
	    var lookup = {};
	    list.forEach(function(obj) {
	        if(!(obj['manager_id'].toString() in companyIDs) || !(obj['manager_id'].toString() in contacts)) {
	        	obj['manager_id'] = '0';
	        }
	        lookup[obj['user_id'].toString()] = obj;
	        obj['members'] = [];
	    });
	    list.forEach(function(obj) {
	        if (obj['manager_id'].toString() !== '0') {
	        	lookup[obj['manager_id'].toString()]['members'].push(obj);
	        } else {
	            treeList.push(obj);
	        }
	    });

		var ret_tree = {"members" : treeList, "id" : '0' };
	    return ret_tree;
	}

	processMember = (team_id, m, cards, manager_bot, level, contacts, member_teams, alt_managers) => {
        if (m == null) {
            return
        }
	    let card = {
            id: m.user_id,
            manager_id: m.manager_id,
            level: level,
            manager_bot: manager_bot,
            member_top: this.member_top === editor_top ? editor_top : this.member_top + vert_gap
        }
	    if(this.member_top > editor_top)this.member_top += vert_gap

	    // // console.log('processMember member_teams', member_teams)
	    let teams = m.user_id.toString() in member_teams ? member_teams[m.user_id.toString()] : {}
	    card.teams = teams
	    m.teams = teams
	    card.mgr_lines = []
	    m.mgr_lines = []
	    if(team_id === 'org_level') {
    	    let my_managers = (m.user_id.toString() in alt_managers) ? alt_managers[m.user_id.toString()] : {}
    	   // // console.log('m.user_id, my_managers', m.user_id, my_managers)
    	    if(Object.keys(my_managers).length > 0 || m.manager_id.toString() !== '0') {
    	        card.mgr_lines.push([])
    	        let cur_line = 0
    	        let char_count = 0
    	        if(m.manager_id !== '0') {
    	           // // console.log('m', m)
    	           // // console.log('contacts', contacts)
    	            card.mgr_lines[cur_line].push({name: contacts[m.manager_id.toString()].ContactName, is_primary: true})
    	            char_count += contacts[m.manager_id.toString()].ContactName.length
    	        }

    	        for(let id in my_managers) {
    	            if(char_count + contacts[id].ContactName.length > line_len - 2) {
    	                card.mgr_lines.push([])
    	                char_count = 0
    	                cur_line++
    	            }
    	            card.mgr_lines[cur_line].push({name: contacts[id].ContactName, is_primary: false})
    	            char_count += contacts[id].ContactName.length + (char_count === 0 ? 0 : 2)
    	        }
    	        m.mgr_lines = card.mgr_lines
    	    }
	    }

	   // // console.log('card, teams', card, teams)

	    let num_team_lines = Object.keys(teams).length > 0 ? Object.keys(teams).length + 1 : 0
	    let num_mgr_lines = card.mgr_lines.length > 0 ? card.mgr_lines.length + 1 : 0
	    let ol_boxheight = box_height + (num_mgr_lines + num_team_lines) * line_height
	   // // console.log('4995 ol_boxheight', ol_boxheight)

	    card.height = team_id === 'org_level' ? ol_boxheight : box_height
	    this.member_top += card.height

	    cards.push(card)
	    let mn_bot = this.member_top
	    if(m.members.length > 0) {
	        for(let mm of m.members) {
	           // // console.log('alt_managers', alt_managers)
	            this.processMember(team_id, mm, cards, mn_bot, level+1, contacts, member_teams, alt_managers)
	        }
	    }
	}

    getTeam = (result) => {
        console.log('getTeam result', result)

        let start = moment().unix()
        let role = result.result.role
        // // console.log('Got team result', result)
        let cd = new ComDecom();
        let contact_info = Store.getStoreData('contactInfo')
        let contacts = Store.getStoreData('members')
        let teams_all = Store.getStoreData('teams')
        let team_managers = {}
        for(let t_id in teams_all) {
            if(t_id !== 'org_level') {
                let m_id = teams_all[t_id].manager_id.toString()
                team_managers[m_id] = 1
            }
        }
        Store.updateStore('team_managers', team_managers)
        let teams = {}
        for(let id in teams_all) {
            if(role === 'admin_manager')teams[id] = teams_all[id]
            else {
                if(contact_info.id.toString() === teams_all[id].manager_id.toString() || id === 'org_level')teams[id] = teams_all[id]
            }
        }
        Store.updateStore('my_teams', teams)
        let org_members_raw = cd.deCompress(result.result.members)
        let new_teammembers = {};
        for(let i in org_members_raw){
            new_teammembers[org_members_raw[i].user_id.toString()] = 1;
        }

        let empty_members = {}
        for(let key in contacts){
            if(!(key in new_teammembers)){
                empty_members[key] = 1;
            }
        }

        let org_members = {}
        let member_teams = {}
        for(let m of org_members_raw) {
            if(m.team_id in teams || m.team_id === 'org_level') {
                m.id = m.user_id.toString()
                if(!(m.team_id in org_members))org_members[m.team_id] = []
                if(!(m.user_id.toString() in member_teams))member_teams[m.user_id.toString()] = {}
                org_members[m.team_id].push(m)
                member_teams[m.user_id.toString()][m.team_id] = {name: teams[m.team_id].name, is_manager: teams[m.team_id].manager_id.toString() === m.user_id.toString() ? true : false}
            }
        }

        console.log(' StatusChange team_managers', team_managers)
        // // console.log('teams', teams)


        let alt_managers_list = cd.deCompress(result.result.alt_managers)
        let alt_managers = {}
        let alt_reporters = {}
        // // console.log('ReportingForm alt_managers_list', alt_managers_list)
        for(let r of alt_managers_list) {
            // // console.log('r', r)
            if(!(r.alt_manager_id.toString() in alt_reporters))alt_reporters[r.alt_manager_id.toString()] = {}
            if(!(r.user_id.toString() in alt_managers))alt_managers[r.user_id.toString()] = {}
            alt_reporters[r.alt_manager_id.toString()][r.user_id.toString()] = 1
            alt_managers[r.user_id.toString()][r.alt_manager_id.toString()] = 1
        }
        let org = {}

        for(let t_id in org_members) {
            console.log('OrgEditor t_id, org_members', t_id, org_members)
            let team_org_members = org_members[t_id].filter(function(value, index) {
                 return (value.user_id.toString() in contacts)
            })
            // team_members[t_id] = team_org_members
            console.log('OrgEditor t_id, team_org_members', t_id, team_org_members)
            org[t_id] = this.convertListToTree(team_org_members, contacts)

            if(role !== 'admin_manager') {
                let m = OrgUtils.orgFindById(contact_info.id, org[t_id].members)

                org[t_id] = {id: 0, members: [m]}
            }
        }



        console.log('OrgEditor org_members', org_members)
        Store.updateStore('org_members', org_members)
        Store.updateStore('alt_managers', alt_managers)



        let member_cards = []
        let team_id = 'team_id' in result.result ? result.result.team_id : 'org_level'

        this.generateMemberCards(org, team_id, contacts, member_teams, alt_managers, member_cards)
        // let end = moment().unix()
        console.log('member_cards', member_cards)
        // // console.log('team generate time', end - start)



        let org_state = {org: org, role: role, alt_managers: alt_managers, alt_reporters: alt_reporters, member_teams: member_teams}

        Store.updateStore('org_state', org_state)
        // // console.log('org_state', org_state)

        // org_state.team_id = team_id
        // return org_state
        let close_dialogs = Store.getStoreData('close_dialogs')
        close_dialogs = close_dialogs === null ? 1 : close_dialogs
        let cur_view = Store.getStoreData('landing_page')

        let this_url = window.location.href
        if(this_url.endsWith('/manage/users'))cur_view = 'team_management'
        if(this_url.endsWith('/manage/teams'))cur_view = 'team_table'
        if(close_dialogs === 1) {
            this.setState({org, role, team_id, cur_view, alt_managers, alt_reporters, member_cards, member_teams, edit_user_id: 0, edit_team_id: '0', show_dialog: null, new_members: [], start_at: 0, empty_members: empty_members})
        } else {
            Store.updateStore('close_dialogs', 0)
            this.setState({org, role, team_id, alt_managers, alt_reporters, member_cards, member_teams, start_at: 0, cur_view, empty_members: empty_members})
        }
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return

        this.setComponentState()
    }

    changeTeam = (team_id) => {
        // // console.log('team_id', team_id)
        let org_state = Store.getStoreData('org_state')
        // // console.log('org_state', org_state)
        this.setupEditorObjects(team_id)
        // this.setState({team_id})
    }

    editUserDelayed = () => {
        let edit_user_id = Store.getStoreData('current_edit_user_id')
        this.setState({edit_user_id, show_dialog: 'user_edit'})
    }

    editUser = (edit_user_id) => {
        let fade_params = null
        EventBus.raiseEvent('show_fade', null)
        if(this.state.page_size > 200 && this.state.member_cards.length > 200) {
            fade_params = {message: 'You have too many users showing. Dialog may take long to load/close.', duration: 1000}
            EventBus.raiseEvent('show_fade', fade_params)
            Store.updateStore('current_edit_user_id', edit_user_id)
            setTimeout(this.editUserDelayed, 100)
            return
        }
        this.setState({edit_user_id, show_dialog: 'user_edit',isLoadedIcon: true})
    }

    moveDelayed = () => {
        let dir = Store.getStoreData('current_move_dir')
        let start_at = this.state.start_at
        let page_size = this.state.page_size

        switch(dir) {
            case 'up': start_at = start_at < page_size ? 0 : start_at - page_size; break;
            case 'all': start_at = 0; page_size = 100000;break;
            case 'some': start_at = 0; page_size = 100;break;
            case 'down' : start_at = start_at > this.state.member_cards.length - page_size ? this.state.member_cards.length - page_size : start_at + page_size; break;
        }
        this.setState({start_at, page_size, search_user_id: 0})

    }

    move = (dir) => {
        console.log('Fade dir', dir)
        let start_at = this.state.start_at
        let page_size = this.state.page_size
        if(this.state.member_cards.length > 200 && dir === 'all') {
            let fade_params = {message: 'You are trying to load too many users. May take long to load.', duration: 1000}
            EventBus.raiseEvent('show_fade', fade_params)
            Store.updateStore('current_move_dir', dir)
            setTimeout(this.moveDelayed, 100)
            return
        }
        switch(dir) {
            case 'up': start_at = start_at < page_size ? 0 : start_at - page_size; break;
            case 'all': start_at = 0; page_size = 100000;break;
            case 'some': start_at = 0; page_size = 100;break;
            case 'down' : start_at = start_at > this.state.member_cards.length - page_size ? this.state.member_cards.length - page_size : start_at + page_size; break;
        }
        this.setState({start_at, page_size, search_user_id: 0})
    }

    closeDialog = (refresh = false) => {
        EventBus.raiseEvent('show_fade', null)
        console.log('EditTeamDialog refresh, this.state',  refresh, this.state)
        if(!refresh)this.setState({edit_user_id: 0, edit_team_id: '0', reset_user_id: 0, show_dialog: null, new_members: [], isLoadedIcon: false})
        else this.refreshTeam(true, this.state.team_id)
    }

    editTeamDelayed = () => {
        let edit_team_id = Store.getStoreData('current_edit_team_id')
        this.setState({edit_team_id, show_dialog: 'team_edit'})
    }


    editTeam = (edit_team_id) => {
        let fade_params = null
        if(this.state.page_size > 200 && this.state.member_cards.length > 200) {
            fade_params = {message: 'You have too many users showing. Dialog may take long to load/close.', duration: 1000}
            EventBus.raiseEvent('show_fade', fade_params)
            Store.updateStore('current_edit_team_id', edit_team_id)
            setTimeout(this.editTeamDelayed, 100)
            return
        }
        this.setState({edit_team_id, show_dialog: 'team_edit', fade_params})
    }

    collectMembers = (m, list) => {
        list.push({id: m.id, manager_id: m.manager_id})
        for(let mm of m.members) {
            this.collectMembers(mm, list)
        }
    }

    listMembers = (m) => {
        let list = []
        list.push({id: m.id, manager_id: '0'})
        for(let mm of m.members) {
            this.collectMembers(mm, list)
        }
        return list
    }

    copyToNewTeam = (id) => {
        let org_members = Store.getStoreData('org_members').org_level
        let manager = org_members.find(e => e.id == id)

        let new_members = this.listMembers(manager)
        // console.log('EditTeamDialog id, members', id, new_members)
        this.setState({edit_team_id: '0', show_dialog: 'team_edit', new_members })
    }

    searchUser = (id) => {
        let cards = this.state.member_cards
        let search_user_id = parseInt(id)

        let c_index = cards.findIndex(e => e.id === search_user_id)
        let start_at = c_index < 1 ? 0 : c_index - 1
        let page_size = 100
        this.scrollToDiv(this.editorDiv)
        this.setState({start_at, page_size, search_user_id})
    }

    zoomIn = () => {
        // console.log('userform zoomIn', this.state.zoom)
        let zoom = this.state.zoom < 1.5 ? this.state.zoom + 0.1 : this.state.zoom
        this.setState({zoom})
    }
    zoomOut = () => {
        // console.log('userform zoomOut', this.state.zoom)
        let zoom = this.state.zoom > 0.5 ? this.state.zoom - 0.1 : this.state.zoom
        this.setState({zoom})
    }

    removeUser = (user_id, team_id) => {
        let alert_param = {title: 'Warning', message: 'Are you sure you want to remove this user from the Org Chart?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.removeUserProcessor, stack: {user_id: user_id, team_id: team_id}}

        console.log('manage user delete', alert_param)

        this.setState({alert_param})
    }

    closeAlert = () => {
        this.setState({alert_param: null})
    }

    refreshTeamAfterRemove = (res, team_id) => {
        EventBus.raiseEvent('user_deleted', team_id)
        this.refreshTeam(true, team_id)
    }

    scrollToDiv = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    removeUserProcessorFinal = (result) => {
        let alert_param = {title: 'ERROR', message: 'Operation failed. Please try after some time.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.closeAlert}
        if(result.error_code === 0) {
            alert_param = {title: 'Success', message: 'User successfully removed from the team.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.refreshTeamAfterRemove, stack: result.result.team_id}
        }
        this.setState({alert_param})
    }

    removeUserProcessor = (response, stack) => {
        if(!response) {
            this.setState({alert_param: null})
            return
        }
        let api = new APICall()
        let postData = {command: 'remove_user', team_id: stack.team_id, user_id: stack.user_id}
        console.log('userform postData', postData)
        api.command(postData, this.removeUserProcessorFinal)

    }

    togglePdf = () =>  {
        let status = this.state.generate_report ? false : true;
        this.setState({generate_report: status});
    }

    updateEmptyTeammembers = () => {
        let postData = {command: "add_empty_members", members: this.state.empty_members};
        const api = new APICall();
        if(Object.keys(this.state.empty_members).length > 0){
            api.command(postData, this.processEmptyMembers)
        }
    }

    processEmptyMembers = (result) => {
        console.log("updateEmptyTeammembers", result)
        this.setState({empty_members: {}});
        // this.setComponentState()
        this.refreshTeam(true, this.state.team_id)
    }

    render() {
        // console.log('ManageUsers DEL OrgEditor this.state', this.state)
        if(this.state.org === null) return (<div>
            <CSLLoader />
        </div>)

        let empty_members = this.state.empty_members
        // console.log("empty_members", empty_members)
        if(Object.keys(empty_members).length > 0){
            setTimeout(() => {
                this.updateEmptyTeammembers()
            }, 3000);
        }

        if(this.state.cur_view === 'team_management') return (<div>
                <ManageUsers editUser={this.editUser} editTeam={this.editTeam} removeUser={this.removeUser} teamId={this.state.team_id} />
                {
                    (() => {
                       if(this.state.show_dialog !== null) {
                           switch(this.state.show_dialog) {
                               case 'user_edit': return <EditUserDialog
                                                            userId={this.state.edit_user_id}
                                                            closeUserDialog={this.closeDialog}
                                                            teamId={this.state.team_id}
                                                         />
                               case 'team_edit': return <EditTeamDialog
                                                            teamId={this.state.edit_team_id}
                                                            closeTeamDialog={this.closeDialog}
                                                            newMembers={this.state.new_members}
                                                        />
                           }
                       }
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
            </div>)
        if(this.state.cur_view === 'team_table') return (<div>
                <ManageTeams editUser={this.editUser} editTeam={this.editTeam} />
                {
                    (() => {
                       if(this.state.show_dialog !== null) {
                           switch(this.state.show_dialog) {
                               case 'user_edit': return <EditUserDialog
                                                            userId={this.state.edit_user_id}
                                                            closeUserDialog={this.closeDialog}
                                                            teamId={this.state.team_id}
                                                         />
                               case 'team_edit': return <EditTeamDialog
                                                            teamId={this.state.edit_team_id}
                                                            closeTeamDialog={this.closeDialog}
                                                            newMembers={this.state.new_members}
                                                        />
                           }
                       }
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
            </div>)

        console.log('Fade this.state ', this.state)
        let contacts = Store.getStoreData('members')
        console.log('Fade this.state ', contacts)
        let job_titles = Store.getStoreData('job_titles')
        let teams = Store.getStoreData('teams')
        let cards = this.state.member_cards
        let top_offset = cards[this.state.start_at].member_top - 20
        let zoom = this.state.zoom;

        return (
            <div style={{zoom: zoom}}>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <TeamsMenu activeTeam={this.state.team_id}
                           cards={cards}
                           pageSize={this.state.page_size}
                           startAt={this.state.start_at}
                           changeTeam={this.changeTeam}
                           editUser={this.editUser}
                           editTeam={this.editTeam}
                           move={this.move}
                           searchUser={this.searchUser}
                           zoomIn={this.zoomIn}
                           zoomOut={this.zoomOut}
                           togglePdf={this.togglePdf}
                />
                <OrgContainer style={{paddingBottom: 15}}>
                    <Style.OrgLegends style={{position: 'absolute',color: 'black', fontSize: 15, width: 160}}>
                                            <FaUser style={{color: '#F50089'}} /> &nbsp;&nbsp;Team Manager<br />
                                            <FaUser style={{color: '#A5A5A5'}} /> &nbsp;&nbsp;Team Member
                    </Style.OrgLegends>
                    <MembersContainer ref={this.editorDiv}>
                    {
                        this.state.member_cards.map((c, i) => {
                            // // console.log('rendering ', c, moment().unix())
                            if(i < this.state.start_at)return <div key={i} />
                            if(i > this.state.start_at + this.state.page_size - 1)return <div key={i} />
                            return (
                                <OrgMember key={i}
                                    card={c}
                                    contacts={contacts}
                                    job_titles={job_titles}
                                    team_id={this.state.team_id}
                                    teams={teams}
                                    editUser={this.editUser}
                                    removeUser={this.removeUser}
                                    editTeam={this.editTeam}
                                    copyToNewTeam={this.copyToNewTeam}
                                    searchUserId={this.state.search_user_id}
                                    topOffset={top_offset}
                                />
                            )
                        })
                    }
                    </MembersContainer>
                </OrgContainer>
                {
                    (() => {
                       if(this.state.show_dialog !== null) {
                           switch(this.state.show_dialog) {
                                case 'user_edit': return <EditUserDialog
                                                            userId={this.state.edit_user_id}
                                                            closeUserDialog={this.closeDialog}
                                                            teamId={this.state.team_id}
                                                         />
                                case 'team_edit': return <EditTeamDialog
                                                            teamId={this.state.edit_team_id}
                                                            closeTeamDialog={this.closeDialog}
                                                            newMembers={this.state.new_members}
                                                        />

                           }
                       }
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
                {
                    (() => {
                        if (this.state.generate_report) {
                            return (
                                <div style={{position:'fixed', top:'140px', width:'calc(100%-50px)', height: 'calc(100vh-180px)'}}>
                                    <Style.InactiveOverlay style={{zIndex: "1010"}} />
                                    <PdfGenerator style={{zIndex:"8000"}} togglePdf={this.togglePdf} includes={this.state} reportData={this.state.org[this.state.team_id].members}
                                    currentTeams={this.state.team_id}/>
                                </div>
                            );
                        }
                    })()
                }
            </div>
            )
    }
}

export default OrgEditor;

// marginTop: `${zoom < 1 ? '-' : ''}calc(${Math.abs(zoom-1)*100}vh - ${Math.abs(zoom-1)*260}px)`,
