import React from 'react';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaPlus, FaTimes} from 'react-icons/fa';


class ManagerSelector extends React.Component
{
    state = {view_count: 0 };

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // console.log('member', this.props.member)
    }

    closeSelector = () => {
        Store.updateStore('modal_view_addmanager', false)
        Store.updateStore('active_member_id', '0')
        Store.updateStore('elligible_members', [])
        this.props.refreshOrgEditor()
    }

    selectMember = (selected_id) => {
        console.log('this.props.altManager', this.props.altManager)
        if(this.props.managerInterface){
            if(this.props.altManager) {
                this.props.addAltManager(selected_id,this.props.member.id)
            } else {
                this.props.addManager(selected_id,this.props.member.id)
            }
            
        } else {
            this.props.addReporter(selected_id,this.props.member.id)
        }
        
    }

    render()
    {
        console.log('values', this.props.elligibleMembers);
        return (
            <div>
                <Style.MemberSelectionBox>
                    <Style.MemberSelectionBoxHeader>
                        {this.props.titleText}
                    </Style.MemberSelectionBoxHeader>
                    <FaTimes onClick={() => this.closeSelector()}
                        style={{position: 'absolute', top: '5px', right: '10px', fontSize: '18px', cursor: 'pointer', color: 'white'}} />
                    <div style={{overflowX: 'hidden', overflowY: 'auto', height: 'calc(100% - 40px)'}}>
                    {
                        this.props.elligibleMembers.map((member, i) => {
                            return (<Style.MemberSelectionBoxRow key={i} onClick={() => this.selectMember(member.id)}>{member.ContactName}</Style.MemberSelectionBoxRow>);
                        })
                    } 
                    </div>
                </Style.MemberSelectionBox>
            </div>
            )
    }
}
export default ManagerSelector;