import React from 'react';
import * as Style from './Common/StyledComponents';
import APICall from '../Common/APICall.js';
import Store from '../Common/Store.js';
import Utils from '../Common/Utils.js';
import ComDecom from '../Common/ComDecom.js';
import WelcomeBanner from './Common/WelcomeBanner';
import DataLoader from './Common/DataLoader';
import DefaultHeader from './Common/DefaultHeader';
import TEAMsIndex from './TEAMsIndex';
import CSLLoader from './Common/CSLLoader.js'
import AlertBox from './Common/AlertBox.js';
import EditUserDialog from './User/EditUser'
import Reload from '../Common/Reload.js';

let loadCount = 0


class Index extends React.Component
{
	state = {
        view_count: 0,
        cur_view: 'teams_index',
        contact_name: '',
        ready: false,
        access: true,
        alert_param: null
	}

    callApi(){
        let contactlist = JSON.parse(localStorage.getItem("contactlist"))
        console.log('contactlist', contactlist)
        let postData = { command: "get_contact" };
        let api = new APICall();
        Utils.log('sending first call')
        api.command(postData, this.processIndexData)
    }

    setComponentState(){
        this.callApi()
    }

	constructor(props) {
		super(props);
        this.callApi = this.callApi.bind(this);
	}
	componentDidMount(){
        this.callApi()
    }
    processIndexData = (result) => {
        if(result.error_code > 0){
            let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                       alertHandler: this.alertHandler, stack: {id: 0}}
            alert_param["message"] = result.error_msg;
            this.setState({ ready: true, alert_param: alert_param});
            return;
        }
        let cd = new ComDecom()
        loadCount++
        console.log("userform result===>", result)

        let my_role = result.result.roles[result.result.contactInfo.id];
        // console.log('result from first/second call my_role', result, my_role)
        let url_start = '/edit/user/';
        Store.updateStore('contactInfo', result.result.contactInfo)
        Store.updateStore('roles',  result.result.roles)
        Store.updateStore('list_roles',  result.result.list_roles)
        Store.updateStore('my_role', my_role)
        Store.updateStore('teams', result.result.teams)
        Store.updateStore('landing_page', result.result.module_first_page)
        Store.updateStore('allowed_roles', {admin_manager: 1, team_manager: 1})

        let langs = result.result.languages['result'];
        Store.updateStore('languages', langs)
        Store.updateStore('user_langs', result.result.user_langs);
        Store.updateStore('is_lan', result.result.is_lan)

        Store.updateStore('enabled_emails', result.result.enabled_emails)
        let members_raw = cd.deCompress(result.result.contacts)
        let members = {}
        for(let r of members_raw) {
            members[r.ID.toString()] = r
        }
        let job_titles_raw = cd.deCompress(result.result.job_titles, false, 'JobID')
        let job_titles = {}

        for(let j of job_titles_raw) {
            // console.log('j', j)
            job_titles[j.JobID.toString()] = j
        }
        // job_titles['0'] = 'N/A'
        Store.updateStore('job_titles', job_titles)

        let smcr_job_titles_raw = cd.deCompress(result.result.smcr_job_titles, false, 'JobID')
        let smcr_job_titles = {};
        for(let k of smcr_job_titles_raw){
            smcr_job_titles[k.JobID.toString()] = k
        }
        Store.updateStore('smcr_job_titles', smcr_job_titles)




        let regulatory_status = result.result.regulatory_statuses
        for(let r of regulatory_status) {
            r.ID = r.ID.toString()
        }
        Store.updateStore('regulatory_status', regulatory_status)
        console.log('members', members)
        Store.updateStore('members', members)
        let cur_view = my_role === 'team' ? 'user_index' : this.state.cur_view
        Store.updateStore('active_member_id', result.result.contactInfo.id.toString())

        this.setState({ready: true, contact_name:result.result.contactInfo.ContactName, contact_info: result.result.contactInfo, cur_view});

    }

	refreshState = (cur_view) => {
        this.setState({cur_view : cur_view});
    }

    alertHandler = (result, stack) => {
        window.location = '/'
    }

    alertHandler = (result, stack) => {
      // this.setState({ alert_param: null });
      // window.location = "/select/ServiceBasedLanding";
      Reload.ReloadPage();
    };

    closeUserDialog = () => {
        // alert("hello")
    }

	render()
	{
	    console.log('this.state', this.state)
		if(!this.state.ready) {
            return (
                <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                    <DefaultHeader />
                    <CSLLoader />
                </div>
            )
        }

        if (this.state.alert_param !== null) {
          return <AlertBox alertParam={this.state.alert_param} />;
        }
		return (

            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
                <DefaultHeader />
                <WelcomeBanner welcome={this.state.contact_name}/>
                    {
                        (() => {
                            // console.log(' Three this.state', this.state)
                            switch(this.state.cur_view) {
                                case 'teams_index' : return <TEAMsIndex refreshCallback={this.refreshState} />; break;
                                // case 'users_index' : return <ManageUsers />; break;
                                case 'user_index' : return (<EditUserDialog
                                                            userId={this.state.contact_info.id.toString()}
                                                            closeUserDialog={this.closeUserDialog}
                                                            teamId={'org_level'}
                                                         />); break;
                                default: return <Style.NoPermission>Sorry, you do not have permission to view this page. </Style.NoPermission>;
                            }
                        })()
                    }
            </div>

		);
	}
}

export default Index;
