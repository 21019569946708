import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import Store from '../../Common/Store';
import Permissions from './Permissions';
import APICall from '../../Common/APICall.js';
import { Link } from "react-router-dom";
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import General from './General';
import Email from './Email';
import Reload from '../../Common/Reload.js';


const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

class ModuleConfigHOC extends React.Component
{
    state = {
		active_section_id: "general",
        dirty: false,
        permissions: {},
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        used_tags: {},
        alert_param: null,
        role: '',
        ready: false,
        module_config:{}
    };

    constructor(props) {
        super(props);
        this.callApi = this.callApi.bind(this);
    }

    componentDidMount() {
      this.callApi()
    }

    callApi = () => {
        //console.log("result ===>", "module_config_index")
        const postData = { command: "module_config_index" };
        const api = new APICall();
        api.command(postData, this.getInitialPermission);
    }

    getInitialPermission = (result) => {
        //console.log("result ===>", result)
        let requester = this.props.match ? this.props.match.params.requester : "";
        const permissions = {
            user_roles: result.user_roles,
            roles: result.roles,
            company_users: result.company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        this.setState({permissions:permissions, module_config: result.moduleconfig, requester, all_company_users: all_company_users, role: result.role});
    }

	returnSection = (section) => (event) => {
		event.preventDefault();
        this.setState({active_section_id: section});
	}

	updateGeneral = (obj) => {
		let {module_config} = this.state;
        module_config.general.module_start_page = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    afterUpdate = (result) => {
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {}}
        this.setState({alert_param: alert_param})
        // console.log("Swoham==>",result);
        // alert(result.error_msg);

        //this.setState({module_config: result.result});
    }

   updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    includeExcludeEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterincludeExclude);
    }

    afterincludeExclude = (result) => {
        // console.log("module_config", result)
         let {module_config} = this.state;
         module_config.email = result.result['email']
        this.setState({module_config: module_config});
    }

    updateEmailRemainder = (obj) =>{
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    topicIncludedExclude = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.UpdatetopicIncludedExclude);
    }

    UpdatetopicIncludedExclude = (result) => {
        this.setState({module_config: result.result});
    }

    updatePermissions = (obj, single_obj) => {
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        this.setState({permissions: obj, changed_user_roles});
    }

    searchUsers = (search_string) => {
        //console.log("search_string",search_string)
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            //console.log("all_company_users",all_company_users)
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        this.setState({permissions, changed_user_roles});
    }

    updateModuleconfig = () => {
        let {module_config, changed_user_roles} = this.state;
        const api = new APICall();
        let postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
        Reload.RestoreStorage(changed_user_roles);
        api.command(postData, this.afterRoleUpdate);
    }
    afterRoleUpdate = (result) => {
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {}}
        this.setState({alert_param: alert_param})
    }
    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        window.location.replace(url);
    }
    alertHandler = (result, stack) => {
        this.setState({alert_param: null});
        window.close();
    }

    sameAlertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

	render()
	{

        if (Object.keys(this.state.module_config).length === 0) {
            return <div><CSLLoader /></div>
        }
        console.log("this.state.permissions",this.state.permissions)
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
          let site_layout = JSON.parse(localStorage.getItem('site_layout'));
          use_site_layout = site_layout.result.use_site_layout;
        }
        if (this.state.role !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    {use_site_layout ? "" : <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>}
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }

		return (
			<div style={{padding: "10px 10px 20px 10px"}}>
                {use_site_layout ? "" : <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>}
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                	<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                        <ReviewTab onClick={this.returnSection("general")}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                	</ReviewLeftContainer>
                        <ReviewRightContainer>
                            {
                                (() => {
                                    if (this.state.active_section_id === "permissions") {
                                        // return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
                                    }
                                    if (this.state.active_section_id === "general") {
                                        return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />);
                                    }

                                })()
                            }
                            {
                                (() => {
                                    if (this.state.active_section_id !== "faqrst" && this.state.active_section_id !== "general") {
                                        return (
                                            <FooterButtonsContainer>
                                                <CancelBtn onClick={this.closePage}>Cancel</CancelBtn><SaveBtn onClick={() => this.updateModuleconfig()}>Save</SaveBtn>
                                            </FooterButtonsContainer>
                                        );
                                    }
                                })()
                            }
                        </ReviewRightContainer>

                	<div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
                <CSLLoader />
                <AlertBox alertParam = {this.state.alert_param} />
			</div>
		);
	}
}

export default ModuleConfigHOC;
