import React from 'react';
import Store from '../../Common/Store';
import EventBus from '../../Common/EventBus';
import {FaCog, FaHome, FaThList} from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaFlag } from "react-icons/fa";
import * as Style from './StyledComponents';
import { FaWrench } from "react-icons/fa";
import Fade from './Fade.js'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHouseBlank, faSquareList, faGear, faFlag, faUsers } from '@fortawesome/pro-thin-svg-icons'

class DefaultHeader extends React.Component
{
	state = {fade_params: null}

	componentDidMount = () => {
		EventBus.registerEvent('show_fade', 'DefaultHeader', this.showFade)
	}

	showFade = (fade_params) => {
		console.log('Fade called')
		this.setState({fade_params})
	}
	redirectTo = (location) => (event) => {
		event.preventDefault();
		let url = "";
		switch(location) {
			case "home":
				url = "/";
				break;
			default:
				url = "/";

		}
		window.location.assign(url);
	}
	redirectToManageUsers = () => (event) => {
		event.preventDefault();
		window.location.href = "/manage/users";
	}
	redirectToManageTeams = () => (event) => {
		event.preventDefault();
		window.location.href = "/manage/teams";
	}
	redirectToManageConfigs = () => (event) => {
		event.preventDefault();
		window.location.href ="/manage/moduleconfig";
	}
	render()
	{
		console.log("Current_Role", Store.getStoreData('my_role'))

		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		  let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		  use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		const role = Store.getStoreData('my_role')
		return (
			<Style.HeadContainer style={{marginTop: 10}}>
				{
					(() => {
						if(this.state.fade_params !== null)	return <Fade fadeParams={this.state.fade_params} />
					})()
				}


			    <Style.HomeText onClick={this.redirectTo("home")} style={{cursor: "pointer"}}>
					{/*<FaHome style={{float: 'left', marginRight: '10px', fontSize: '16px', color: '#929292'}} />*/}
					<FontAwesomeIcon style={{float: 'left', marginRight: '10px', fontSize: '16px', color: '#929292'}} icon={faHouseBlank} />
			        <span style={{fontWeight: "600"}} >Teams</span>
			    </Style.HomeText>
			    <Style.HomeLogo title="Team View" onClick={this.redirectToManageTeams()} style={{paddingRight:"20px", float:"right", cursor: "pointer"}}><FontAwesomeIcon icon={faFlag} /></Style.HomeLogo>
			    <Style.HomeLogo title="Personnel View" onClick={this.redirectToManageUsers()} style={{paddingRight:"10px", float:"right", cursor: "pointer"}}><FontAwesomeIcon icon={faUsers} /></Style.HomeLogo>
				{ role === 'admin_manager' &&
			    	<Style.HomeLogo title="Audit Logs" style={{paddingRight:"10px", float:"right", cursor: "pointer"}}><a href={process.env.REACT_APP_AUDIT_URL} style={{color: '#929292'}}><FontAwesomeIcon icon={faSquareList} /></a></Style.HomeLogo>
				}
				{ role === 'admin_manager' &&
					<Style.HomeLogo title="Module Config" style={{paddingRight:"10px", float:"right", cursor: "pointer"}}><Link to={'/manage/moduleconfig'} style={{color: '#929292'}}><FontAwesomeIcon icon={faGear} /></Link></Style.HomeLogo>
				}
				<div style={{clear: "both"}}></div>
			</Style.HeadContainer>
		);
	}
}

export default DefaultHeader;
